
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import ProductsPatentsNotice from '@/components/ProductsPatentsNotice'
import ProductsGatewayNotice from '@/components/ProductsGatewayNotice'
import ProductsSpecialPriceNotice from '@/components/ProductsSpecialPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'


export default {
  name: 'TagBFP',
  components: {
    BuyProductArea,
    ProductsPriceNotice,
    ProductsPatentsNotice,
    ProductsGatewayNotice,
    ProductsSpecialPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'TagBFP',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Синий флуоресцентный белок TagBFP'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
