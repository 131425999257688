
import ProductToBasket from '../ProductToBasket'
import BtnWithLoading from '../BtnWithLoading'
import VueScrollbar from 'vue2-scrollbar'
import 'vue2-scrollbar/dist/style/vue2-scrollbar.css'
import _ from 'lodash'
import ProductsPricelist from '../../pages/products/Pricelist'
import ProductsPriceNotice from '../ProductsPriceNotice'
import ProductsPatentsNotice from '../ProductsPatentsNotice'
import ProductsSpecialPriceNotice from '../ProductsSpecialPriceNotice'

export default {
  components: {
    ProductsSpecialPriceNotice,
    ProductsPatentsNotice,
    ProductsPriceNotice,
    ProductsPricelist,
    ProductToBasket,
    BtnWithLoading,
    VueScrollbar
  },
  name: 'ProductListModal',
  props: {
    title: null
  },
  computed: {
    products () {
      return this.$store.state.dictionaries.productPrice
    }
  },
  mounted(){
    this.filterProducts()
    this.searchProduct = _.debounce(this.filterProducts, 500)
  },
  data() {
    return {
      findProductText: '',
      filteredProducts: []
    }
  },
  methods: {
    filterProducts(){
      if (!this.findProductText.trim()){
        this.filteredProducts = this.products
      }

      this.filteredProducts = this.products.filter(item =>
        (item.cat.toLowerCase().indexOf(this.findProductText.toLowerCase().trim()) >= 0)
        || (item.name.toLowerCase().indexOf(this.findProductText.toLowerCase().trim()) >= 0)
      ).sort((a, b) => {
        return (a.cat > b.cat) - (a.cat < b.cat)
      })
    },
    closeDialog () {
      this.$emit('close')
    },
    buildWarning(product) {
      let result = ''

      if (product.name_warning) {
        result = "<b class='red'>" + product.name_warning + '</b>'
      }

      if (product.name_warning_url) {
        result = `<a href='${product.name_warning_url}' target='_blank' >${result}</a>`
      }

      return result ? ('<br/>' + result) : ''
    },
  }

}
