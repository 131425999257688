
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'EducationTopics',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Практикум по генной инженерии, описание задач',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Описание задач практикума по генной инженерии'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
