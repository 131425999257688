
import BuyProductArea from '@/components/BuyProductArea'
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  name: 'CYP2C19',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ГенТест CYP2C19',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для выявления аллельных вариантов в гене человека CYP2C19 методом ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ГенТест CYP2C19, R2001, тест система CYP2C19, kit CYP2C19, набор CYP2C19, мутация CYP2C19, горячая точка CYP2C19, аллельный вариант CYP2C19, скрининг CYP2C19, CYP2C19*2, NG_008384.2:g.24154G> A, rs4244285, NM_000769.2:c.681G> A, NP_000760.1:p.Pro227'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/R2001_CYP2C19.jpg'}
      ],
      imageIndex: null
    }
  }
}
