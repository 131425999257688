import { render, staticRenderFns } from "./Pricelist.vue?vue&type=template&id=ea874d4c&scoped=true"
import script from "./Pricelist.vue?vue&type=script&lang=js"
export * from "./Pricelist.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea874d4c",
  null
  
)

export default component.exports