
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'HsTaqBuffers',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Буферы для Taq и HS Taq ДНК полимераз',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Taq ДНК полимераза, смесь полимераз, буфер, Taq Turbo, Taq Red, taq gc'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
