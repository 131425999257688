
import AddressBlock from '@/components/page_blocks/AddressBlock'
import ContactsBlock from '@/components/page_blocks/ContactsBlock'
export default {
  name: 'Contacts',
  scrollToTop: true,
  components: {
    ContactsBlock,
    AddressBlock
  },
  layout: 'main/Index',
  head() {
    return {
      title: 'Контактная информация',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Евроген - контакты для связи'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'евроген адрес, синтез праймеров адрес, сиквенс адрес, синтез олигов адрес, синтез олигонуклеотидов адрес, синтез генов адрес, евроген контакты, синтез праймеров контакты, сиквенс контакты, синтез олигов контакты, синтез олигонуклеотидов контакты, синтез генов контакты, евроген почта, синтез праймеров почта, сиквенс почта, синтез олигов почта, синтез олигонуклеотидов почта, синтез генов почта, евроген телефон, синтез праймеров телефон, сиквенс телефон, синтез олигов телефон, синтез олигонуклеотидов телефон, синтез генов телефон, евроген техническая, евроген тех, евроген консультация, евроген помощь, евроген подбор'
        }
      ]
    }
  }
}
