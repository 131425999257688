
import ConfidentialityV1 from '@/components/page_blocks/ConfidentialityV1'
export default {
  name: 'IndustrialTechnologies',
  components: {
    ConfidentialityV1
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Разработка полупромышленных технологий синтеза',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Разработка полупромышленных технологий синтеза на основе существующих или новых методик.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Взлом патентов, разработка технологии синтеза, заказ разработки синтеза, Разработка химии, трансфер технологии, промышленная технология синтеза заказ, полупромышленная технология синтеза заказ'
        }
      ]
    }
  }
}
