import { render, staticRenderFns } from "./PlasmidPurification.vue?vue&type=template&id=9ae8b47e&scoped=true"
import script from "./PlasmidPurification.vue?vue&type=script&lang=js"
export * from "./PlasmidPurification.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ae8b47e",
  null
  
)

export default component.exports