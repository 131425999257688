
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'ScreenMixHs',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '5X ScreenMix-HS',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Окрашенная ПЦР-смесь с "горячим стартом" для нанесения на гель.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: '5X ScreenMix hs, смесь нанесение на гель горячий старт, микс нанесение на гель горячий старт, mix нанесение на гель горячий старт, смесь электрофорез горячий старт, микс электрофорез горячий старт, смесь нанесение на гель hot start, микс нанесение на гель'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
