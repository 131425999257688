
import BtnWithLoading from '../BtnWithLoading'
export default {
  name: 'DeleteContactModal',
  components: {
    BtnWithLoading
  },
  props: {
    title: '',
    contact: null
  },
  methods: {
    confirmDeletion () {
      this.$nuxt.$emit('delete-contact', this.contact)
      this.$emit('close')
    }
  }
}
