
import _ from 'lodash'

export default {
  name: 'PagerSearchCmp',
  props: {
    pageCount: {default: 0},
    currentPage: {default: 0},
    showOnePage: {default: true},
    prevText: {default: '&laquo;'},
    nextText: {default: '&raquo;'}
  },
  computed: {
    pagesInFrame () {
      if (this.pageCount <= 10) {
        return _.range(1, this.pageCount + 1)
      }

      let curPage = this.currentPage + 1

      if (curPage <= 5) {
        return _.range(1, 11)
      }

      if (curPage > this.pageCount - 6) {
        return _.range(this.pageCount - 9, this.pageCount + 1)
      }

      return _.range(curPage - 4, curPage + 6)
    }
  },
  watch: {
    currentPage () {
      this.$forceUpdate()
    }
  }
}
