
import ContactsBlock from '@/components/page_blocks/ContactsBlock'
export default {
  name: '',
  scrollToTop: true,
  components: {
    ContactsBlock
  },
  layout: 'main/Index',
  head() {
    return {
      title: 'Как заказать',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Как заказать наборы и сервисы Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
