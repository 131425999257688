
export default {
  name: 'ModifiersPrice',
  computed: {
    modifiers () {
      return this.$store.state.dictionaries.modifiers
    },
    modifiersForPrice () {
      const preparePosition = function (modifier, posName, acc) {
        if (modifier.acceptedPositionArray.indexOf(posName) < 0) {
          return
        }

        const result = {}
        result.cat = modifier.catNumbers && modifier.catNumbers[posName] ? modifier.catNumbers[posName] : ''
        result.name = (posName === 'left' ? '5` - ' : '3` - ') + (modifier.fullName ? modifier.fullName : modifier.name)
        result.scale_004 = modifier.priceForPositionArray[posName]
        result.scale_02 = result.scale_004 * 2
        result.scale_1 = result.scale_004 * 4
        acc.push(result)
      }

      return this.modifiers
      .filter(m =>!m.isDeleted)
      .reduce(function (sum, modifier) {
        preparePosition(modifier, 'left', sum)
        preparePosition(modifier, 'right', sum)
        return sum
      }, []).sort(function (a, b) {
        if (a.name[0] === b.name[0]) {
          return a.name > b.name ? 1 : -1
        }
        return a.name[0].toString() === '5' ? -1 : 1
      })
    }
  },
  methods: {
    getPriceForScale(scale, type) {
      if (this.$store.state.dictionaries.primerPrices && this.$store.state.dictionaries.primerPrices[scale]) {
        return this.$store.state.dictionaries.primerPrices[scale][type]
      }

      return ''
    }
  },
  data () {
    return {
      details1Expanded: false,
      images: [
        {url: '/img/image_serv.jpg'}
      ],
      swipeItems:             [
        {src: '/img/image_serv.jpg', thumbnail:'/icons/BC025S.jpg' , w: 900,h: 785, title: 'BC025S.jpg'}
      ],
      imageIndex: null
    }
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new this.$PhotoSwipeLightbox({
        gallery: '#myGallery',
        children: 'a.grl',
        pswpModule: this.$PhotoSwipe,
      });
      this.lightbox.init();
    }
  },
}
