
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'PcrPolymerases',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Полимеразы',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Термостабильные ДНК-полимеразы, наборы реактивов и буферы для ПЦР'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Термостабильные полимеразы, днк полимеразы, полимераза горячий старт, hot start, полимераза купить ферменты пцр, полимераза, taq'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
