
export default {
  name: 'RnaIsolation',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Total RNA isolation protocol',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Протокол выделения тотальной РНК (англ.)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Total RNA isolation, выделения тотальной РНК'
        }
      ]
    }
  }
}
