
import ProductToBasket from './ProductToBasket'

export default {
  name: 'BuyProductArea4',
  components: {
    ProductToBasket
  },
  props: {
    cat: null
  }
}
