
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from "../../../components/ProductsPriceNotice";

export default {
  name: 'BisQuick',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'BisQuick',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор BisQuick для бисульфитной модификации ДНК, выделенной из различных образцов (включая ДНК из FFPE-блоков, тканей, культур клеток)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'бисульфитная модификация, бисульфитная конверсия, Bisulfite Conversion, BisQuick'
        }
      ]
    }
  },
  data () {
    return {
      details1Expanded: false,
      images: [
        {url: '/img/EG002_BQ.jpg'}
      ],
      imageIndex: null
    }
  }
}
