
import BtnWithLoading from '../BtnWithLoading'
import VueScrollbar from 'vue2-scrollbar'
import 'vue2-scrollbar/dist/style/vue2-scrollbar.css'

export default {
  name: 'DeletePrimersModal',
  components: {
    BtnWithLoading,
    VueScrollbar
  },
  props: {
    title: '',
    primers: null
  },
  methods: {
    confirmDeletion () {
      this.$nuxt.$emit('delete-primers', this.primers)
      this.$emit('close')
    },
    formatPrice(src) {
      return this.$myHelper.priceFormat(src)
    }
  }
}
