
import ProductsPriceNotice from '../../../../ProductsPriceNotice'
import BuyProductArea from '../../../../BuyProductArea'
export default {
  name: 'TaqAdditionals',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
