
import BtnWithLoading from './BtnWithLoading'
import _ from 'lodash'
import {required} from 'vuelidate/lib/validators'

// TODO: Сейчас только добавление клиента - нуно будет переделыывать с редактированием
export default {
  name: 'AddClient',
  props: {
    client: {
      affiliate: {id: '', name: ''},
      id: 0,
      name: '',
      is_person: 0,
      pdDiscount: 0,
      simplePrimerDiscount: 0,
      source: 'site'
    }
  },
  components: {
    BtnWithLoading
  },
  validations() {
    return {
      client: {
        name: {
          required
        },
        affiliate: {
          name: {
            required
          }
        }
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  data () {
    return {

    }
  },
  methods: {
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    async onBtnSave() {
      this.client.users = [this.user.id]

      try {
        this.client.id = await this.$store.dispatch('user/saveClient', this.client)
      } catch (ex) {
        this.showDialog('Ошибка сохранения клиента', ex)
        return
      }

      const fnd = _.find(this.user.clients, cln => cln.id * 1 === this.client.id * 1)

      if (!fnd) {
        this.user.clients.push(this.client)
      } else {
        fnd.name = this.client.name
        fnd.affiliate.name = this.client.affiliate.name
      }

      this.$emit('add-client', this.client)
    },

    onBtnCancel() {
      this.$emit('client-add-cancel')
    },

    checkClient(value, returnDoubleError) {
      if (this.client.id !== 0) {
        return ''
      }

      if (!value) {
        return 'Поле должно быть заполнено.'
      }
      if (!returnDoubleError) {
        return ''
      }
      const checkName = (this.client.name + this.client.affiliate.name).toLowerCase().replace(/ /g, '')
      return this.user.clients.some(item => (item.name + item.affiliate.name).toLowerCase().replace(/ /g, '') === checkName)
        ? 'В списке уже есть эта организация и подразделение.'
        : ''
    }
  }
}
