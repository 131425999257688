
import BuyProductArea from '@/components/BuyProductArea'
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  name: 'F2-F5',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ГенТест F2, F5',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для выявления полиморфизмов в генах человека F2 и F5 методом ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ГенТест F2 F5, R2005, тест система F2, kit F2, набор F2, мутация F2, горячая точка F2, аллельный вариант F2, скрининг F2, полиморфизм F2, тест система F5, kit F5, набор F5, мутация F5, горячая точка F5, аллельный вариант F5, скрининг F5, полиморфизм F5'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/R2005_F2.jpg'}
      ],
      imageIndex: null
    }
  }
}
