
export default {
  name: 'SberbankPayCompleted',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Оплата заказа',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  async mounted() {
    this.sberId = this.$route.query.orderId

    if (!this.sberId) {
      this.message = 'Ошибочный номер платежа'
      return
    }

    this.loading = true
    this.message = 'Ожидание подтверждения оплаты'
    await this.checkPayState()
  },
  data () {
    return {
      orderNumber: '',
      sberId: '',
      loading: false,
      message: ''
    }
  },
  methods: {
    checkPayState: async function() {
      let response = null

      try {
        response = await this.$myHelper.axiosWithMyEx('/basket/getOrderNumberBySberId', JSON.stringify(this.sberId))
      } catch (ex) {
        this.message = 'Во время проверки оплаты произошла ошибка.'
        this.loading = false
        return
      }

      if (!response.number) {
        this.message = 'Платеж не найден.'
        this.loading = false
        return
      }

      switch (response.code) {
        case 0: {
          this.message = `Заказ&nbsp;#<b>${response.number}</b>&nbsp;оплачен.`
          this.loading = false
          return
        }

        case -100: {
          setTimeout(this.checkPayState, 5000)
          return
        }

        default: {
          this.message = `Ошибка оплаты заказа&nbsp;#<b>${response.number}</b>: ${response.message}`
          this.loading = false
          return
        }
      }
    }

  }
}
