
export default {
  name: 'Ssh',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Suppression Subtractive Hybridization (SSH)',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Описание метода "Супрессионная вычитающая гибридизация" (англ.)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Suppression Subtractive Hybridization, SSH, Супрессионная вычитающая гибридизация'
        }
      ]
    }
  }
}
