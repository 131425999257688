
import BufferSelectorCmp from '../BufferSelectorCmp'
import BtnWithLoading from '../BtnWithLoading'

export default {
  components: {
    BufferSelectorCmp,
    BtnWithLoading,
  },
  name: 'SelectBufferModal',
  props: {
    product: null,
    buffers: null
  },
  computed: {
    selectedBuffer () {
      if (this.product) {
        return this.product.buffer_fk
      }
    },
  },
  methods: {
    setBuffer (value) {
      if (this.product) {
        this.product.buffer_fk = value
      }
    },

    async saveProductInBasket() {
      if (this.product.buffer_fk < 1) {
        this.product.buffer_fk = ''
      }

      this.$store.state.basket.basket.recalcProductsDiscount()

      try {
        await this.$store.dispatch('basket/saveProducts')
        this.$emit('close')

      } catch (ex) {

      }
    },

  }

}
