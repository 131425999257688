import { render, staticRenderFns } from "./NewsAll.vue?vue&type=template&id=321e59cb&scoped=true"
import script from "./NewsAll.vue?vue&type=script&lang=js"
export * from "./NewsAll.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321e59cb",
  null
  
)

export default component.exports