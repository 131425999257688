
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'Antibodies',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Антитела против флуоресцентных белков',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Коллекция очищенных поликлональных антител (IgG) для иммунологической детекции флуоресцентных белков и фотосенсибилизаторов'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Антитела против флуоресцентных белков, антитела флуоресцентный белок биотехнология, синтез олигонуклеотидов, генная инженерия, наука, евроген, олигонуклеотиды, праймеры, синтез, секвенирование, ферменты, taq полимераза, пцр-рв, клонирование фрагментов Д'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
