
import Primer from '@/assets/primer.js'
import PrimerEditor from './PrimerEditor'
import ModifiersButtons from './ModifiersButtons'
import BtnWithLoading from './BtnWithLoading'
import $ from 'jquery'

export default {
  name: 'PrimerEdit',
  props: ['primer', 'index', 'editOnePrimerMode'],
  components: {
    ModifiersButtons,
    BtnWithLoading,
    PrimerEditor
  },

  watch: {
    'primer' : {
      deep: true,
      handler: (value) => {
        if (!Primer.canKeen(value.scale, value) && value.options.includes('keen')) {
          value.options = value.options.filter(o => o !== 'keen')
        }
      }
    }




  },

  methods: {
    canClean(scale) {
      return Primer.canClean(scale);
    },

    canKeen(primer) {
      return Primer.canKeen(primer.scale, primer);
    },

    onChangeScale(primer) {
      if (!this.canClean(primer.scale)) {
        primer.options = primer.options.filter(o => o!== 'clean');
      } else if ( !primer.options.includes('clean') && primer.isForceClean() ) {
        primer.options.push('clean');
      }

      if (!this.canKeen(primer)) {
        primer.options = primer.options.filter(o => o!== 'keen');
      }


      this.$emit('change-primer', primer);
    },

    onChangeOptions (event) {
      const el = $(event.srcElement ? event.srcElement : event.target)
      this.primer.setOptions(el.val(), el.prop('checked'))
      this.$emit('change-primer', this.primer)
    },
    formatPrice(src) {
      return this.$myHelper.priceFormat(src)
    }
  },
  computed: {
    canAllScaleClean() {
      return Primer.canClean(1) && Primer.canClean(0.2) && Primer.canClean(0.04) && Primer.canClean(0.02);
    },

    maxPrimerLenght() {
      return Primer.getMaxLength(this.primer.scale);
    },

    minPrimerLength() {
      return Primer.minLength;
    }
  }
}
