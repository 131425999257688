
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'Sybr',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Интеркалирующий краситель SYBR Green I для ПЦР-РВ',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'SYBR Green I, SYBR купить, интеркалирующий краситель купить, краситель пцр-рв'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
