
export default {
  name: 'RnaElectrophoresis',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'RNA analysis on non-denaturing agarose gel electrophoresis',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Протокол анализа РНК на неденатурирующем агарозном геле (англ.)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'RNA gel, RNA electrophoresis, РНК неденатурирующий геле'
        }
      ]
    }
  }
}
