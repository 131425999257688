import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=6a0bb886&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=6a0bb886&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0bb886",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductsPriceNotice: require('/var/www/localhost/evrogen.ru/components/ProductsPriceNotice.vue').default,ProductsPatentsNotice: require('/var/www/localhost/evrogen.ru/components/ProductsPatentsNotice.vue').default,ProductsSpecialPriceNotice: require('/var/www/localhost/evrogen.ru/components/ProductsSpecialPriceNotice.vue').default,BuyProductArea: require('/var/www/localhost/evrogen.ru/components/BuyProductArea.vue').default})
