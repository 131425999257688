
export default {
  name: 'BloodPreparation',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Первичная обработка цельной крови',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Протокол первичной обработки цельной крови'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'обработка цельной крови'
        }
      ]
    }
  }
}
