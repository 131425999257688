
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'ScreenMix',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '5X ScreenMix',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Окрашенная ПЦР-смесь без "горячего старта" для нанесения на гель.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'смесь нанесение на гель, микс нанесение на гель, mix нанесение на гель, смесь электрофорез, микс электрофорез, 5X ScreenMix'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
