
export default {
  name: 'EvrogenFpLicense',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Ограниченная Лицензия #001: Флуоресцентные продукты компании Евроген',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Ограниченная Лицензия #001: Флуоресцентные продукты компании Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'лицензия евроген белок, лицензия евроген вектор, патент евроген белок, патент евроген вектор'
        }
      ]
    }
  }
}
