
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPatentsNotice from "@/components/ProductsPatentsNotice";
import ProductsGatewayNotice from "@/components/ProductsGatewayNotice";
import ProductsSpecialPriceNotice from "@/components/ProductsSpecialPriceNotice";

export default {
  name: 'TurboGFP',
  components: {
    ProductsSpecialPriceNotice,
    ProductsGatewayNotice,
    ProductsPatentsNotice,
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'TurboGFP',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Зеленый флуоресцентный белок TurboGFP'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
