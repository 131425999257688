
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea4 from '@/components/BuyProductArea4'

export default {
  name: 'MintRaceCdna',
  components: {
    BuyProductArea4,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Mint RACE cDNA amplification set',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор для быстрой амплификации концевых фрагментов кДНК'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'амплификация race, амплификации концевых фрагментов'
        }
      ]
    }
  }
}
