
import BuyProductArea from '@/components/BuyProductArea'
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  name: 'Insider',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Инсайдер',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы реагентов для скрининга мутаций в горячих точках генов BRAF, NRAS и KRAS методом мутационно-специфической ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Инсайдер FullRAS, R3002, Инсайдер KRAS-2, Инсайдер NRAS-3, Инсайдер BRAF, GK221, GK223, GK241, GK243, GK231, GK233, тест система KRAS, тест система NRAS, тест система BRAF, kit KRAS, kit NRAS, kit BRAF, набор KRAS, набор NRAS, набор BRAF, мутация KRAS, мутация NRAS, мутация BRAF'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/M3000_Insider.jpg'}
      ],
      imageIndex: null
    }
  }
}
