
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
export default {
  name: 'CompetentCells',
  components: {ProductsPriceNotice, BuyProductArea},
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Компетентные клетки',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Компетентные клетки для химической и электрической трансформации'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'CC001, CC002, CC003, CC004, компетентные клетки, XL1-Blue, BL21, JM110, химическая трансформация, электрическая трансформация'
        }
      ]
    }
  },
  data () {
    return {
      details1Expanded: false,
      details2Expanded: false,
      details3Expanded: false,
      details4Expanded: false
    }
  }
}
