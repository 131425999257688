
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'Education',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Практикум по генной инженерии',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Практикум моделирует ключевые этапы научного исследования: от выделения РНК до получения активного рекомбинантного белка.\n' +
            'Набор ориентирован на студентов и школьников, специализирующихся в области молекулярной биологии, генетики и вирусологии.\n'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'практикум биология, практикум генная инженерия, обучение студентов биология, обучение генная инженерия, Экспрессия гена студент, Синтез кДНК студент, выделение РНК студент, выделение ДНК студент, RACE студент биотехнология, синтез олигонуклеотидов, генн'
        }
      ]
    }
  }
}
