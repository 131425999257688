
import BtnWithLoading from './BtnWithLoading'
import ProductCountModal from './modals/ProductCountModal'
import _ from 'lodash'

export default {
  name: 'ProductToBasket',
  props: ['cat', 'preloadedProduct', 'addDialogRef', 'oldPriceText', 'openedInBasket', 'isPricePage'],
  components: {
    BtnWithLoading,
    ProductCountModal
  },
  computed: {
    products() {
      return this.$store.state.dictionaries.productPrice
    },
    productsInBasket () {
      return this.$store.state.basket.basket.products
    },
    bulks () {
      return this.$store.state.dictionaries.bulks
    },
    product () {
      if (this.preloadedProduct) {
        return this.preloadedProduct
      }

      return this.products.find(el => el.cat === this.cat)
    },
    showPrice () {
      if (!this.product) {
        return '-'
      }
      let result = this.$myHelper.priceFormat(this.$myHelper.calcDiscountPrice(this.product.price, this.discountPercent))
      if (this.product.discount_price > 0) {
        result += ' / ' + this.$myHelper.priceFormat(this.$myHelper.calcDiscountPrice(this.product.discount_price, this.discountPercent)) + "<span style='color:red'>*</span>"
      }
      return result
    },
    countInBasket () {
      if (!this.product) {
        return 0
      }
      const productInBasket = _.find(this.productsInBasket, item => (!item.forDelete) && (Number(item.product_fk) === Number(this.product.id)))
      return productInBasket ? productInBasket.count : 0
    },
    managerEmail () {
      return this.$store.state.dictionaries.settings.managerMail
    },

    discountPercent () {
      return this.product.discount_percent * 1
        ? this.product.discount_percent
        : (this.$store.state.user.user.discountInfo
            ? (this.$store.state.user.user.discountInfo.pdPercent || 0) * 1
            : 0
        )
    }
  },
  mounted () {
    if (this.addDialogRef
      && this.$root.$refs[this.addDialogRef]
      && (!this.addDialog)
    ) {
      this.addDialog = this.$root.$refs[this.addDialogRef]
    }
  },
  data () {
    return {
      addDialog: null
    }
  },
  methods: {
    showProductCountModal() {
      let productInBasket = _.find(this.productsInBasket, item => Number(item.product_fk) === Number(this.product.id))

      this.$modal.show(
        ProductCountModal,
        {
          product: this.product,
          buffers: this.bulks.filter(item => item.product_id * 1 === this.product.id * 1),
          productInBasket: productInBasket ? productInBasket : null,
          openedInBasket: this.openedInBasket
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true
        },
          {
            'before-open': this.$myHelper.onModalOpened,
            'before-close': this.$myHelper.onModalClosed
          }
      )
    },

    formatPrice: function (src) {
      return this.$myHelper.priceFormat(src)
    }
  }
}
