
export default {
  name: 'Smart',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'SMART cDNA synthesis',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Описание технологии SMART для синтеза кДНК (англ.)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'SMART'
        }
      ]
    }
  }
}
