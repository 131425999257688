
export default {
  name: 'QuantumConcentration',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Определение пригодности образца для ПЦР',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Определение производится путем оценки эффективной концентрации фрагментов ДНК соответствующей длины.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
