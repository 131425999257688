
import _ from 'lodash'

export default {
  name: 'BufferSelectorCmp',
  props: {
    buffers: null,
    selectedBuffer: null
  },
  mounted() {
    this.bufferId = this.selectedBuffer
  },
  computed: {
    groups() {
      return _.groupBy(this.buffers, 'with_magnesium')
    }
  },
  data() {
    return {
      bufferId: null
    }
  }
}
