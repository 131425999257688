
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'WtbPcr',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    CoolLightBox
  },
  head() {
    return {
      title: 'Технология обнаружения редких аллелей с помощью мутационно-специфической ПЦР',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Описание технологии обнаружения редких аллелей с помощью мутационно-специфической ПЦР (Wild-Type Blocking PCR)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'мутационно-специфическая ПЦР, мсПЦР, Wild Type Blocking PCR, WTB PCR'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img_large/schemaWTB-PCR_large_new.png'},
        {url: '/img_large/insider-fig1_2abv_large.png'}
      ],
      imageIndex: null
    }
  }
}
