
export default {
  name: 'LicenseStatements',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Лицензионные соглашения',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Лицензионные соглашения Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'лицензия евроген, патенты евроген'
        }
      ]
    }
  }
}
