
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import TaqAdditionals from '@/components/page_blocks/products/pcr/taq/TaqAdditionals'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'Taq',
  components: {
    BuyProductArea,
    TaqAdditionals,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Taq ДНК-полимераза',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Taq ДНК-полимераза без «горячего старта» для рутинной ПЦР'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Taq полимераза, PK113S, PK113L, PK114, Taq полимераза купить биотехнология, синтез олигонуклеотидов, генная инженерия, наука, евроген, олигонуклеотиды, праймеры, синтез, секвенирование, ферменты, taq полимераза, пцр-рв, клонирование фрагментов ДНК, норм'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
