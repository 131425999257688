
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'Tersus',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Tersus и Tersus Plus PCR kit',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Полимераза для высокоточной амплификации фрагментов ДНК длиной до 15 т.п.о.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Tersus Plus PCR kit, PK221, PK123, PK123S, PK123L, PK123B, tersus'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
