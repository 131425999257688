
export default {
  name: 'Case12Citing',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Case12, цитирование',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
