
import Degracalq from '@/components/Degracalq'

export default {
  name: 'QuantumFragmentation',
  components: {
    Degracalq
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Оценка степени фрагментации образцов ДНК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Прогнозирование концентрации ДНК с помощью набора QuantumDNA и онлайн калькулятора "КАЛЬQЛЯТОР"'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  mounted (){
    this.$nextTick().then(() => {
      if (this.$route.hash){
        let el = document.getElementById("DNAcalc")
        el && el.scrollIntoView()
      }
    })
  }
}
