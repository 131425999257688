
import _ from 'lodash'
import BufferSelectorCmp from '../BufferSelectorCmp'
import BtnWithLoading from '../BtnWithLoading'
import ToBasketButtons from '../ToBasketButtons'

export default {
  components: {
    ToBasketButtons,
    BufferSelectorCmp,
    BtnWithLoading,
  },
  name: 'ProductCountModal',
  props: {
    product: null,
    buffers: null,
    productInBasket: null,
    openedInBasket: null
  },
  computed: {
    countInBasket () {
      return this.productInBasket ? this.productInBasket.count : 0
    },
    selectedBuffer () {
      if (this.productInBasket) {
        return this.productInBasket.buffer_fk
      }

      return this.emittedBuffer
    },
    productsInBasket () {
      return this.$store.state.basket.basket.products
    },
    isCountError () {
      return (!this.$myHelper.isPositiveInt(this.count)) ||
        this.count * 1 + this.countInBasket * 1 < this.product.minimum_order_count * 1;
    }
  },
  mounted () {
    if (this.productInBasket) {
      this.count = this.productInBasket.count
    } else {
      this.count = this.product.minimum_order_count > 1 ? this.product.minimum_order_count : 1
    }
  },
  data() {
    return {
      basketSaved: false,
      count: 1,
      emittedBuffer: ''
    }
  },
  methods: {
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    closeDialog () {
      this.$emit('close')
    },

    setBuffer (value) {
      if (this.productInBasket && this.productInBasket.buffer_fk) {
        this.productInBasket.buffer_fk = value
      }

      this.emittedBuffer = value
    },

    async saveProductInBasket() {
      let productInBasket = {}

      if (!this.productInBasket) {
        productInBasket = {
          id: -1,
          order_fk: '',
          count: 0,
          price: (this.product.price - this.product.price / 100 * this.product.discount_percent).toFixed(2),
          discount_price: (this.product.discount_price - this.product.discount_price / 100 * this.product.discount_percent).toFixed(2),
          discount_count: 0,
          basket_id: '',
          product_fk: this.product.id,
          groups: this.product.groups,
          selected: false,
          forDelete: false,
          cat: this.product.cat,
          name: this.product.name,
          withBuffer: this.product.withBuffer,
          discount_percent: this.product.discount_percent * 1
        }

        this.productsInBasket.push(productInBasket)
      } else {
        productInBasket = this.productInBasket
      }

      productInBasket.count = Number(this.count)
      productInBasket.buffer_fk = this.emittedBuffer

      this.$store.state.basket.basket.recalcProductsDiscount()

      try {
        await this.$store.dispatch('basket/saveProducts')

        this.basketSaved = true
        if (this.openedInBasket && this.openedInBasket === true) {
          this.closeDialog()
        }

      } catch (ex) {
        if (productInBasket.id > 0) {
          productInBasket.count = Number(productInBasket.count) - Number(this.count)
        } else {
          const idx = this.productsInBasket.indexOf(productInBasket)
          this.productsInBasket.splice(idx, 1)
        }

        this.showDialog('Ошибка сохранения корзины', ex)
        throw ex
      }
    },

    checkNumber (event) {
      let acceptedKeys = _.range(10)
      acceptedKeys.push('Backspace', 'Delete', 'ArrowRight', 'ArrowLeft')

      if (!acceptedKeys.some(function (item) {
        return event.key === item.toString()
      })) {
        event.preventDefault()
      }
    },
    goToBasket (){
      this.$router.push('/basket')
      this.$emit('close')
    }

  }

}
