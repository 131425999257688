
import RCFRPMCalc from '@/components/RCF-RPMCalq'
export default {
  name: 'RCF-RPM',
  components: {
    RCFRPMCalc
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Расчет RCF-RPM on-line',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Калькулятор для расчета RPM (обороты в минуту) при работе по протоколам к нашим наборам или методикам, приведенным в статьях, или для указания универсальной величины центрифугирования RCF (g) в публикациях.\n'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Центрифугирование как пересчитать, расчет g, расчет rcf, расчет pfm, как перевести g в обороты, конвертирование rcf, конвертирование g, конвертирование rpm, калькулятор g, калькулятор rcf, калькулятор pfm, RCF RPM on line, RCF RPM он лайн, g обороты on'
        }
      ]
    }
  }
}
