
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'Mycoplasma',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'MycoReport и Myco Real-Time',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы для выявления контаминации микоплазмой культур клеток'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'MycoReport, Myco Real-Time, выявление микоплазменной контаминации, контаминация микоплазмой, MR004, MR001'
        }
      ]
    }
  }
}
