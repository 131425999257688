
import BuyProductArea from '@/components/BuyProductArea'
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  name: 'InsiderEGFR',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Инсайдер EGFR',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для скрининга мутаций в горячих точках экзонов 18, 19, 20, 21 гена человека EGFR методом мутационно-специфической ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Инсайдер EGFR, R3001, тест система EGFR, kit EGFR, набор EGFR, мутация EGFR, горячая точка EGFR, аллельный вариант EGFR, скрининг EGFR, EGFR ex 18, c.2156G> C, chr7:55174010-55174018, c.2155G> A, c.2155G> T, EGFR ex 19, c.2235_2249del15, chr7:55174769-5'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/R3001_EGFR.jpg'}
      ],
      imageIndex: null
    }
  }
}
