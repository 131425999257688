
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ProductsPriceNotice from "@/components/ProductsPriceNotice";
import BuyProductArea from "@/components/BuyProductArea";
export default {
  name: '',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    CoolLightBox
  },
  head() {
    return {
      title: 'Новости',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Новости компании Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'евроген новости, евроген события, евроген новинки, евроген новые'
        }
      ]
    }
  },
  data () {
    return {
      textDetailsState: false,
      textDetailsState2: false,
      textDetailsState3: false,
      images: [
        {url: '/img/zakaz1.jpg'},
        {url: '/img/zakaz2.jpg'},
        {url: '/img/zakaz3.jpg'},
      ],
      swipeItems:             [
        {src: '/img/zakaz1.jpg', thumbnail:'/icons/zakaz1.jpg' , w: 1200,h: 552, title: 'zakaz1.jpg'},
        {src: '/img/zakaz2.jpg', thumbnail:'/icons/zakaz2.jpg' ,w: 900,h: 824, title: 'zakaz2.jpg'},
        {src: '/img/zakaz3.jpg', thumbnail:'/icons/zakaz3.jpg' ,w: 741,h: 421, title: 'zakaz3.jpg'}
      ],
      imageIndex: null
    }
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new this.$PhotoSwipeLightbox({
        gallery: '#myGallery',
        children: 'a.grl',
        pswpModule: this.$PhotoSwipe,
      });
      this.lightbox.init();
    }
  },
}
