
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'CDNAAmplification',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'RACE - быстрая амплификация концевых фрагментов кДНК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы для быстрой амплификации концевых фрагментов кДНК (RACE)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
