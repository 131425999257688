
import BuyProductArea from '@/components/BuyProductArea'
export default {
  name: 'DnaLadders',
  components: {
    BuyProductArea
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Евроген: Маркеры длин ДНК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Маркеры длин ДНК - стандарты для определения длины двухцепочечных молекул ДНК при анализе с помощью электрофореза в агарозном геле.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'маркер ДНК, маркер электрофорез, Ladder, NL001, NL002, NL003, маркер молекулярного веса'
        }
      ]
    }
  }
}
