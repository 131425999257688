
import ToBasketButtons from '../ToBasketButtons'

export default {
  components: {
    ToBasketButtons,
  },
  name: 'PrimersToBasketModal',
  props: ['message'],
  methods: {
    closeDialog () {
      this.$emit('close')
    },
    goToBasket (){
      this.$router.push('/basket')
      this.$emit('close')
    }

  }

}
