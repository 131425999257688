
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ProductsPriceNotice from "@/components/ProductsPriceNotice";
import BuyProductArea from "@/components/BuyProductArea";

export default {
  name: 'QuickTa',
  components: {BuyProductArea,
    ProductsPriceNotice,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Quick-TA kit',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор для быстрого клонирования продуктов ПЦР'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'набор клонирование, Quick-TA, лигаза quick'
        }
      ]
    }
  },
  data () {
    return {
      details1Expanded: false,
      images: [
        {url: '/img/TAK02kit.jpg'}
      ],
      imageIndex: null
    }
  }
}
