import { render, staticRenderFns } from "./AddClient.vue?vue&type=template&id=466de666&scoped=true"
import script from "./AddClient.vue?vue&type=script&lang=js"
export * from "./AddClient.vue?vue&type=script&lang=js"
import style0 from "./AddClient.vue?vue&type=style&index=0&id=466de666&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466de666",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnWithLoading: require('/var/www/localhost/evrogen.ru/components/BtnWithLoading.vue').default})
