
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'DSN',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Дуплекс-специфическая нуклеаза',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ДСН – термостабильная нуклеаза, обладающая специфичностью к двухцепочечной ДНК'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ДСН, DSN, нуклеаза, дуплекс ДНК'
        }
      ]
    }
  }
}
