
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'qPcrMixHs',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '5X qPCRmix-HS',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ПЦР-смесь для ПЦР и ПЦР-РВ с интеркалирующими красителями или флуоресцентными зондами'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: '5X qpcrmix, pcr mix hs, pcr mix hot start'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
