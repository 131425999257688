
export default {
  name: 'Citing',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Цитирование',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Публикации, в которых авторы использовали наборы и услуги Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'евроген цитирование, евроген статьи, евроген работы, евроген наука'
        }
      ]
    }
  }
}
