
import AddressForm from './AddressForm'
import AddClient from './AddClient'
import BtnWithLoading from './BtnWithLoading'
// import addressForm from './account-form'
// Vue.component('account-form', addressForm)

export default {
  name: 'UserClient',
  components: {
    BtnWithLoading,
    AddressForm,
    AddClient
  },
  props: {
    id: {type: String},
    showEditButton: {type: Boolean, default: false},
    showDeleteButton: {type: Boolean, default: false},
    showAddresses: {type: Boolean, default: false},
    showAccounts: {type: Boolean, default: false}
  },
  data() {
    return {
      currentEditClient: {}
    }
  },

  beforeRouteUpdate(to, from, next) {
    next()
  },

  created() {
    this.setClientId(this.$route.params.id)
  },

  computed: {
    clients() {
      return this.user.clients.filter(item => item.is_person * 1 === 0);
    },

    user() {
      return this.$store.state.user.user;
    },

    client() {
      return _.find(this.user.clients, client => client.id.toString() === this.id.toString());
    }
  },
  methods: {
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    onBtnEditClient() {
      if (this.$route.params['id']) {
        this.currentEditClient = _.cloneDeep(this.client)
      } else {
        this.$router.push('/clients/' + this.id)
      }
    },

    async onBtnSaveClient(client) {
      this.currentEditClient = {}
      try {
        this.$router.replace('/clients/' + client.id)
      } catch (ex) {
        this.showDialog('Ошибка сохранения организации', ex)
      }
    },

    onBtnCancelEditClient() {
      if (this.id < 0) {
        this.$router.push('/clients/')
      } else {
        this.currentEditClient = {};
      }
    },

    setClientId(id) {
      if (id * 1 === -1) {
        this.currentEditClient = _.cloneDeep(this.addEmptyClient());
      }
    },

    addEmptyClient () {
      const client = {
        affiliate: {id: '', name: ''},
        id: -1,
        name: '',
        shortName: '',
        source: 'site',
        users: [this.user.id],
        is_person: false,
        forDelete: false,
        isEditNow: true
      };
      return client;
    },

    checkClient(client, value, returnDoubleError) {
      if (!value) {
        return 'Поле должно быть заполнено.';
      }
      if (!returnDoubleError) {
        return '';
      }
      const checkName = (client.affiliate.name + client.name).toLowerCase().replace(/ /g, '');
      return this.user.clients.some(function (item) {
        return item.id !== client.id &&
          (item.affiliate.name + item.name).toLowerCase().replace(/ /g, '') === checkName;
      }) ? 'Вы уже указали эту организацию и подразделение.' : '';
    }
  }
}
