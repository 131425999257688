
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'

export default {
  name: 'PcrBulk',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Полимеразы и готовые смеси в больших объемах (балком)',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Компания Евроген - российская инновационная биотехнологическая компания, основными направлениями деятельности которой являются: работа с клеточными культурами, флуоресцентной микроскопией, синтез олигонуклеотидов, секвенирование ДНК, научные исследовани'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'полимераза большой объем, полимераза на заказ, полимераза балк, полимераза балком, полимераза bulk, cмесь пцр большой объем, смесь пцр на заказ, смесь пцр балк, смесь пцр балком, смесь пцр bulk, pcr mix большой объем, pcr mix на заказ, pcr mix балк'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
