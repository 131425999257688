
export default {
  name: 'Catalog',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Наборы и реактивы для молекулярной биологии',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы для выделения и очистки НК, полимеразы и готовые смеси для ПЦР, ревертазы и наборы для синтеза кДНК, реактивы для клонирования.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'наборы клеточная биология, наборы генная инженерия, реактивы клеточная биология, реактивы генная инженерия, каталог евроген, наборы евроген, продукты евроген, реактивы евроген, реагенты евроген'
        }
      ]
    }
  }
}
