
export default {
  name: 'EncycloCiting',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Антитела, цитирование',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
