
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'

export default {
  name: 'IntactRna',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'IntactRNA',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Фиксатор для стабилизации РНК в биологических образцах'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'стабилизация рнк, фиксатор рнк, stab, IntactRNA'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/BC031_IntactRNA.jpg'}
      ],
      imageIndex: null
    }
  }
}
