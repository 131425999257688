
import BtnWithLoading from '@/components/BtnWithLoading'
import DeleteContactModal from '@/components/modals/DeleteContactModal'
import {required} from 'vuelidate/lib/validators'

export default {
  name: 'UserContacts',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    DeleteContactModal,
    BtnWithLoading
  },
  head() {
    return {
      title: 'Контактные данные',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    phones() {
      if (!this.$store.state.user.user || !this.$store.state.user.user.contacts) {
        return []
      }

      return this.$store.state.user.user.contacts.filter(item => item.type === 'phone' && (!item.forDelete))
    },
    mails() {
      if (!this.$store.state.user.user || !this.$store.state.user.user.contacts) {
        return []
      }

      return this.$store.state.user.user.contacts.filter(item => item.type === 'mail' && (!item.forDelete))
    },
  },
  validations() {
    return {
      currentEditContact: {
        contact: {
          required,
          check(val) {
            if (this.currentContactType === '') {
              return true
            }

            let re = this.currentContactType === 'mail'
              ? /^[^@,;"]+@[^@,;'"]+(\.[^@,;'"]+)+$/gi
              : /^(\d|[+)( -])+$/gi

            return re.test(val)
          }
        }
      }
    }
  },
  created () {
    this.$nuxt.$on('delete-contact', (c) => {
      this.doDeleteContact(c)
    })
  },
  async mounted () {
    await this.$store.dispatch('user/loadUser')

    if (!this.user || !this.user.id) {
      this.$router.push('/auth/login?ret=/profile/contacts')
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('delete-contact')
  },
  data() {
    return {
      currentEditContact: {},
      currentContactType: ''
    }
  },
  methods: {
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    startEditContact(contact, type) {
      this.currentContactType = type
      this.currentEditContact = Object.assign({}, contact)
    },

    async saveContact(contact) {
      contact.contact = this.currentEditContact.contact

      try {
        await this.$store.dispatch('user/saveUserFields', this.user)
      } catch (ex) {
        this.showDialog('Ошибка сохранения контакта', ex)
        return
      }

      this.currentContactType = ''
      this.currentEditContact = {}
    },

    addContact(type) {
      const newId = this.user.contacts.reduce((min, item) => item.id <= min ? item.id - 1 : min, -1)
      const newContact = { id: newId, contact: '', type: type, forDelete: false }
      this.user.contacts.push(newContact)

      this.currentContactType = type
      this.currentEditContact = Object.assign({}, newContact)
    },

    cancelEditContact(contact) {
      if (!contact.contact.trim()) {
        this.deleteContact(contact)
      }

      this.currentContactType = ''
      this.currentEditContact = {}
    },

    showDeleteContactModal(contact) {
      this.$modal.show(
        DeleteContactModal,
        {
          title: 'Подтвердите удаление',
          contact: contact
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    async doDeleteContact(contact) {
      this.deleteContact(contact)

      try {
        await this.$store.dispatch('user/saveUserFields', this.user)
      } catch (ex) {
        this.showDialog('Ошибка удаления контакта', ex)
      }
    },

    deleteContact(contact) {
      if (contact.id > 0) {
        contact.forDelete = true
      } else {
        this.user.contacts.splice(this.user.contacts.indexOf(contact), 1)
      }
    },

  }
}
