

import PolicyTextNew from '../../components/page_blocks/PolicyTextNew'
export default {
  name: 'Policy',
  components: {
    PolicyTextNew

  },
  scrollToTop: true,
  layout: 'main_page/Index',
  head() {
    return {
      title: '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  },
  methods: {

  }
}
