
import RegistrationForm from '@/components/RegistrationForm'
import LoggedInNotice from '@/components/LoggedInNotice'

export default {
  name: 'Registration',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    RegistrationForm,
    LoggedInNotice
  },
  head() {
    return {
      title: 'Регистрация',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  }
}
