
export default {
  name: '',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Вакансии',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Открытые вакансии в биотехнологической компании Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'биотехнология вакансии, евроген вакансии, биотехнологическая компания вакансии, биотех вакансии, молекулярная биология вакансии, клеточная биология вакансии, наука россия вакансии, биотехнология работа, евроген работа, биотехнологическая компания  работа, биотех  работа, молекулярная биология работа, клеточная биология работа, наука россия работа'
        }
      ]
    }
  }
}
