
export default {
  name: 'PlasmidPurification',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Очистка плазмид для секвенирования',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Протокол очистки плазмид для секвенирования'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Очистка плазмид, секвенирование'
        }
      ]
    }
  }
}
