
import BuyProductArea from '@/components/BuyProductArea'
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  name: 'ExtractDNA2',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ЭкстрактДНК-2',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для выделения ДНК из периферической крови, буккального эпителия, слюны и культуры клеток животных'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ЭкстрактДНК-2, R1003'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/R1003_DNA2.jpg'}
      ],
      imageIndex: null
    }
  }
}
