
import ServicesNote from '@/components/page_blocks/ServicesNote'
import ConfidentialityV1 from '@/components/page_blocks/ConfidentialityV1'
export default {
  name: 'GeneSynthesis',
  components: {
    ConfidentialityV1,
    ServicesNote
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Синтез генов',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Синтетические фрагменты ДНК клонируются в стандартные вектора или вектора по выбору заказчика. Правильность полученной синтетической последовательности подтверждается секвенированием.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'синтез генов'
        }
      ]
    }
  }
}
