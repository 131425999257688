
export default {
  name: 'Login',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    },
  },
  mounted() {
    this.logout()
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$store.commit('user/setUser', {})
        this.$router.push('/')
      })
    }
  }
}
