
import NewYearAlert from '@/components/NewYearAlert'
import ProductToBasket from '@/components/ProductToBasket'
import ProductsPatentsNotice from '@/components/ProductsPatentsNotice'
import ProductsSpecialPriceNotice from '@/components/ProductsSpecialPriceNotice'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'

export default {
  name: 'ProductsPricelist',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    ProductsPriceNotice,
    ProductsSpecialPriceNotice,
    ProductsPatentsNotice,
    NewYearAlert,
    ProductToBasket
  },
  head() {
    return {
      title: 'Прайс-лист',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Прайс-лист на продукты компании Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    products() {
      return this.$store.state.dictionaries.productPrice
    },
    isIe () {
      return this.$ua.browser() === 'Internet Explorer'
    },

    filteredGroups() {
      return this.findProductText.trim()
        ? this.groups.filter(g => this.filteredProducts.some(p => p.siteGroup.id === g.id ))
        : this.groups;
    },

    filteredProducts() {
      const fnd = this.findProductText.toLowerCase().trim();
      return this.findProductText.trim()
         ? this.products.filter(p =>
                         p.cat.toLowerCase().includes(fnd)
                         || p.name_to_display.toLowerCase().includes(fnd)
        )
         : this.products;
    }
  },
  async mounted(){
    this.groups = await this.$myHelper.axiosWithMyEx('/product/GetSiteGroups')
  },
  data () {
    return {
      groups: [],
      findProductText: '',
      groupAnchors: {
        94: 'polymerases',
        92: 'extraction',
        95: 'pcr',
        96: 'cdna',
        93: 'evaluation',
        119: 'cloning',
        97: 'contamination',
        118: 'lenti',
        120: 'practicum',
        98: 'components',
        101: 'nomotech',
        99: 'ab',
        100: 'fp'
      }
    }
  },
  methods: {
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    scrollToTop () {
      window.scrollTo(0,0)
    }
  }
}
