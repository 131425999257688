
import BtnWithLoading from '../BtnWithLoading'
import VueScrollbar from 'vue2-scrollbar'
import 'vue2-scrollbar/dist/style/vue2-scrollbar.css'

export default {
  name: 'DeleteProductFromBasketModal',
  components: {
    BtnWithLoading,
    VueScrollbar
  },
  props: {
    title: '',
    products: null
  },
  methods: {
    confirmDeletion () {
      this.$nuxt.$emit('delete-product-from-basket', this.products)
      this.$emit('close')
    },
    formatPrice(src) {
      return this.$myHelper.priceFormat(src)
    }
  }
}
