
import ServicesNote from '@/components/page_blocks/ServicesNote'
import ConfidentialityV1 from '@/components/page_blocks/ConfidentialityV1'
export default {
  name: 'PcrCloning',
  components: {
    ConfidentialityV1,
    ServicesNote
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Амплификация и клонирование фрагментов ДНК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Евроген осуществляет все виды работ по клонированию и амплификации ДНК.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Амплификация ДНК, амплификация, клонирование ДНК, клонирование'
        }
      ]
    }
  }
}
