
import BtnWithLoading from '../BtnWithLoading'
export default {
  name: 'DeleteAddressModal',
  components: {
    BtnWithLoading
  },
  props: {
    title: '',
    address: null
  },
  methods: {
    confirmDeletion () {
      this.$nuxt.$emit('delete-address', this.address)
      this.$emit('close')
    }
  }
}
