
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'Components',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Отдельные компоненты',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'dNTP, красители, вода и буферы для ПЦР'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Вода, буфер для разведения днк, буферы для разведения рнк, dNTP, Красители для ПЦР, SYBR, EVA green, rox, геномная ДНК, Стандартные праймеры, Буферы для ПЦР, Буферы для обратной транскрипции, Буферы для клонирования, Буферы для электрофореза'
        }
      ]
    }
  },
  computed: {
    isIe () {
      return this.$ua.browser() === 'Internet Explorer'
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0,0)
    }
  }

}
