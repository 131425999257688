
import LeftSubMenu from './LeftSubMenu'

export default {
  name: 'LeftMenu',
  components: {
    LeftSubMenu
  },
  computed: {
    user () {
      return this.$store.state.user.user
    },
    clients () {
      if (!this.user || !this.user.clients) {
        return []
      }

      return this.user.clients
    },
    productsDiscount () {
      if (this.user.discountInfo && this.user.discountInfo.pdDiscount) {
        return this.user.discountInfo.pdDiscount
      }

      if (this.clients === []) {
        return 0
      }

      return this.clients.reduce((res, curr) => {
        if (curr.pdDiscount*1 > res) {
          res = curr.pdDiscount*1
        }

        return res
      }, 0)
    },
    sstDiscount () {
      if (this.user.discountInfo && this.user.discountInfo.simplePrimerPercent) {
        return this.user.discountInfo.simplePrimerPercent
      }

      if (this.clients === []) {
        return 0
      }

      return this.clients.reduce((res, curr) => {
        if (curr.simplePrimerDiscount*1 > res) {
          res = curr.simplePrimerDiscount*1
        }

        return res
      }, 0)
    },
    userHasDiscount(){
      return this.productsDiscount > 0 || this.sstDiscount > 0
    },
    sectionName () {
      if (this.$route.path === '/') {
        return '/'
      }

      return this.$route.path.substring(1).split('/').reduce((res, curr) => {
        let mo = this.menuOptionsNormilized

        Object.keys(this.menuOptionsNormilized).forEach(key => {
          if (key === curr && this.$route.path.indexOf(mo[key].href) !== -1){
            res = curr.toLowerCase()
          }
        })

        return res
      }, '')
    },
    currentSection () {
      if (!this.menuOptionsNormilized[this.sectionName]) {
        return null
      }

      return this.menuOptionsNormilized[this.sectionName]
    },
    menuOptionsNormilized () {
      let res = {}

      Object.keys(this.menuOptions).forEach(k => {
        res[k.toLowerCase()] = this.menuOptions[k]
      })

      return res
    },
    menuOptions () {
      return {
        'about': {
          href: '/about',
            caption: 'О компании',
            children: [
            {nn: 1, type: 'link', href: '', text: 'О компании', absolute: false},
            {nn: 2, type: 'link', href: 'contacts', text: 'Контакты', absolute: false},
            {nn: 3, type: 'link', href: 'news', text: 'Новости',
              children: [
                {nn: 1, type: 'link', href: 'all', text: 'Архив', absolute: false}
              ],
              absolute: false
            },
            {nn: 4, type: 'link', href: 'vacancy', text: 'Вакансии', absolute: false}
          ]
        },
        'order': {
          href: '/order',
            caption: 'Заказ и доставка',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Как заказать', absolute: false},
            {nn: 2, type: 'link', href: 'shipping', text: 'Условия доставки', absolute: false},
            {nn: 3, type: 'link', href: 'distributors', text: 'Дистрибьюторы', absolute: false},
            {nn: 4, type: 'link', href: 'pay', text: 'Способы оплаты', absolute: false},
            {nn: 5, type: 'link', href: 'return', text: 'Условия возврата', absolute: false},
            {nn: 6, type: 'link', href: 'sample-requirements', text: 'Требования к биологическим материалам', absolute: false},
            {nn: 7, type: 'link', href: 'special-offers', text: 'Специальные предложения', absolute: false}
          ]
        },
        'products': {
          href: '/products',
          caption: 'Наборы и реактивы',
          children: [
            {nn: 1, type: 'link', href: '', text: 'Каталог', absolute: false},
            {nn: 2, type: 'link', href: 'pricelist', text: 'Прайс-лист',
              children: [
                {nn: 1, type: 'scroll', id: 'polymerases', text: 'ДНК-полимеразы', absolute: false, internal: true},
                {nn: 2, type: 'scroll', id: 'extraction', text: 'Выделение и очистка нуклеиновых кислот', absolute: false, internal: true},
                {nn: 3, type: 'scroll', id: 'pcr', text: 'Готовые смеси для ПЦР', absolute: false, internal: true},
                {nn: 4, type: 'scroll', id: 'cdna', text: 'Работа с кДНК', absolute: false, internal: true},
                {nn: 5, type: 'scroll', id: 'evaluation', text: 'Оценка ДНК', absolute: false, internal: true},
                {nn: 6, type: 'scroll', id: 'cloning', text: 'Клонирование', absolute: false, internal: true},
                {nn: 7, type: 'scroll', id: 'contamination', text: 'Выявление контаминации микоплазмой культур клеток', absolute: false, internal: true},
                {nn: 8, type: 'scroll', id: 'lenti', text: 'Лентивирусные частицы', absolute: false, internal: true},
                {nn: 9, type: 'scroll', id: 'practicum', text: 'Практикум по генной инженерии', absolute: false, internal: true},
                {nn: 10, type: 'scroll', id: 'components', text: 'Отдельные компоненты', absolute: false, internal: true},
                {nn: 11, type: 'scroll', id: 'nomotech', text: 'Наборы для молекулярно-генетических исследований', absolute: false, internal: true},
                {nn: 12, type: 'scroll', id: 'ab', text: 'Антитела против флуоресцентных белков', absolute: false, internal: true},
                {nn: 13, type: 'scroll', id: 'fp', text: 'Флуоресцентные белки и вектора к ним', absolute: false, internal: true},
              ],
              absolute: false},
          ]
        },
        'antibodies': {
          href: '/products/antibodies',
            caption: 'Антитела против флуоресцентных белков',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов',
              children: [
                {nn: 1, type: 'link', href: 'anti-gfp', text: 'Anti-GFP', absolute: false},
                {nn: 2, type: 'link', href: 'anti-tag-cgyfp', text: 'Anti-Tag(CGY)FP', absolute: false},
                {nn: 3, type: 'link', href: 'anti-trfp', text: 'Anti-tRFP', absolute: false},
                {nn: 4, type: 'link', href: 'anti-turbo-gfp', text: 'Anti-TurboGFP(d)', absolute: false},
                {nn: 5, type: 'link', href: 'anti-turbo-yfp', text: 'Anti-TurboYFP', absolute: false},
                {nn: 6, type: 'link', href: 'anti-killer-red', text: 'Anti-KillerRed', absolute: false},
              ],
              absolute: false
            },
            {nn: 2, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
          ]
        },
        'cloning': {
          href: '/products/cloning',
            caption: 'Клонирование&nbsp;ДНК',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов', absolute: false},
            {nn: 2, type: 'link', href: 'vectors', text: 'Векторы для клонирования', absolute: false},
            {nn: 3, type: 'link', href: 'quick-ta', text: 'Набор Quick-TA kit', absolute: false},
            {nn: 4, type: 'link', href: 'competent-cells', text: 'Компетентные&nbsp;клетки',
              children: [
                {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
              ],
              absolute: false
            },
          ]
        },
        'components': {
          href: '/products/components',
            caption: 'Отдельные компоненты',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов',
              children: [
                {nn: 1, type: 'link', href: '/products/components#ferment', text: 'Ферменты', absolute: true, internal: false},
                {nn: 2, type: 'link', href: '/products/components#dNTP', text: 'dNTP', absolute: true, internal: false},
                {nn: 3, type: 'link', href: '/products/components#primer', text: 'Стандартные праймеры', absolute: true, internal: false},
                {nn: 4, type: 'link', href: '/products/components#gDNA', text: 'Геномная ДНК', absolute: true, internal: false},
                {nn: 5, type: 'link', href: '/products/components#krasitel', text: 'Красители для ПЦР', absolute: true, internal: false},
                {nn: 6, type: 'link', href: '/products/components#water', text: 'Вода и буферы для разведения НК', absolute: true, internal: false},
                {nn: 7, type: 'link', href: '/products/components#buferPCR', text: 'Буферы для ПЦР', absolute: true, internal: false},
                {nn: 8, type: 'link', href: '/products/components#buferRT', text: 'Буферы для обратной транскрипции', absolute: true, internal: false},
                {nn: 9, type: 'link', href: '/products/components#bufercloning', text: 'Буферы для клонирования', absolute: true, internal: false},
                {nn: 10, type: 'link', href: '/products/components#buferforez', text: 'Буферы для электрофореза', absolute: true, internal: false},
              ],
              absolute: false
            },
          ]
        },
        'practicum': {
          href: '/products/practicum',
            caption: 'Практикум',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Практикум по генной инженерии', absolute: false},
            {nn: 2, type: 'link', href: 'topics', text: 'Описание задач практикума', absolute: false},
          ]
        },
        'fp': {
          href: '/products/fp',
            caption: 'Флуоресцентные белки',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Описание', absolute: false},
            {nn: 3, type: 'link', href: 'basic', text: 'Базовые флуоресцентные белки',
              children: [
                {nn: 1, type: 'link', href: 'tag-fps', text: 'Группа TagFPs',
                  children: [
                    {nn: 1, type: 'link', href: 'tag-bfp', text: 'TagBFP',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 2, type: 'link', href: 'tag-cfp', text: 'TagCFP',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 3, type: 'link', href: 'tag-gfp2', text: 'TagGFP2',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 4, type: 'link', href: 'tag-yfp', text: 'TagYFP',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 5, type: 'link', href: 'tag-rfp', text: 'TagRFP',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 6, type: 'link', href: 'mkate2', text: 'mKate2',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 7, type: 'link', href: 'fusion-red', text: 'FusionRed',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 8, type: 'link', href: 'properties', text: 'Сводная таблица свойств', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 2, type: 'link', href: 'turbo-colors', text: 'Группа TurboColors',
                  children: [
                    {nn: 1, type: 'link', href: 'turbo-gfp', text: 'TurboGFP',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 1, type: 'link', href: 'turbo-yfp', text: 'TurboYFP',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 1, type: 'link', href: 'turbo-rfp', text: 'TurboRFP',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 1, type: 'link', href: 'turbo-fp602', text: 'TurboFP602',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 1, type: 'link', href: 'turbo-fp635', text: 'TurboFP635',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },

                    {nn: 5, type: 'link', href: 'properties', text: 'Сводная таблица свойств', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 4, type: 'link', href: 'flim', text: 'Группа FLIM',
                  children: [
                    {nn: 1, type: 'link', href: 'bruslee', text: 'BrUSLEE', absolute: false},
                    {nn: 2, type: 'link', href: 'slim', text: 'SLIM', absolute: false},
                    {nn: 3, type: 'link', href: 'properties', text: 'Сводная таблица свойств', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 3, type: 'link', href: 'whole-body-imaging', text: 'Визуализация клеток',
                  children: [
                    {nn: 1, type: 'link', href: 'katushka2s', text: 'Katushka2S',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 2, type: 'link', href: 'turbo-fp650', text: 'TurboFP650',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 3, type: 'link', href: 'nirfp', text: 'NirFP',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 4, type: 'link', href: 'properties', text: 'Сводная таблица свойств', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 4, type: 'link', href: 'photoactivatable', text: 'Фотоактивируемые белки',
                  children: [
                    {nn: 1, type: 'link', href: 'ps-cfp2', text: 'PS-CFP2',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 1, type: 'link', href: 'kfp-red', text: 'KFP-Red',
                      children: [
                        {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                      ],
                      absolute: false
                    },
                    {nn: 1, type: 'link', href: 'pa-tag-rfp', text: 'PA-TagRFP', absolute: false},
                    {nn: 5, type: 'link', href: 'properties', text: 'Сводная таблица свойств', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 4, type: 'link', href: 'previous', text: 'Предыдущие версии', absolute: false},
              ],
              absolute: false
            },
            {nn: 3, type: 'link', href: 'biosensors', text: 'Биосенсоры',
              children: [
                {nn: 1, type: 'link', href: 'hyper', text: 'HyPer',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 2, type: 'link', href: 'case12', text: 'Case12',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 3, type: 'link', href: 'casper3-gr', text: 'Casper3-GR',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 4, type: 'link', href: 'products-list', text: 'Список продуктов', absolute: false},
              ],
              absolute: false
            },
            {nn: 4, type: 'link', href: 'photosensibilizators', text: 'Фотосенсибилизаторы',
              children: [
                {nn: 1, type: 'link', href: 'killer-red', text: 'KillerRed',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 2, type: 'link', href: 'arrest-red', text: 'ArrestRed', absolute: false},
                {nn: 3, type: 'link', href: 'killer-orange', text: 'KillerOrange',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 4, type: 'link', href: 'products-list', text: 'Список продуктов', absolute: false},
              ],
              absolute: false
            },
            {nn: 5, type: 'link', href: 'vectors', text: 'Векторы',
              children: [
                {nn: 1, type: 'link', href: 'organelle-labeling', text: 'Для мечения белков и органелл', absolute: false},
                {nn: 1, type: 'link', href: 'promoter-testing', text: 'Для анализа активности промоторов', absolute: false},
              ],
              absolute: false
            },
            {nn: 6, type: 'link', href: 'recombinants', text: 'Препараты рекомбинантных белков', absolute: false},
          ]
        },
        'lenti': {
          href: '/products/lenti',
            caption: 'Лентивирусные частицы',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Описание', absolute: false},
          ]
        },
        'mycoplasma': {
          href: '/products/mycoplasma',
            caption: 'Выявление контаминации микоплазмой',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов', absolute: false},
            {nn: 2, type: 'link', href: 'myco-report', text: 'MycoReport', absolute: false},
            {nn: 3, type: 'link', href: 'myco-real-time', text: 'Myco Real-Time', absolute: false},
          ]
        },
        'evaluation': {
          href: '/products/evaluation',
            caption: 'Оценка ДНК',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Оценка ДНК', absolute: false},
            {nn: 2, type: 'link', href: 'ladders', text: 'Маркеры длин ДНК', absolute: false},
            {nn: 3, type: 'link', href: 'quantum', text: 'QuantumDNA',
              children: [
                {nn: 1, type: 'link', href: 'concentration', text: 'Определение пригодности образца для ПЦР', absolute: false},
                {nn: 2, type: 'link', href: 'fragmentation', text: 'Оценка степени фрагментации образцов ДНК', absolute: false}
              ],
              absolute: false
            }
          ]
        },
        'isolation': {
          href: '/products/isolation',
          caption: 'Выделение нуклеиновых кислот',
          children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов', absolute: false},
            {
              nn: 2,
              type: 'link',
              href: 'plasmid-kits',
              text: 'Plasmid Miniprep Color, Plasmid Miniprep 2.0 и Plasmid Midiprep 3.0',
              absolute: false
            },
            {nn: 3, type: 'link', href: 'extract-dna-ffpe', text: 'ExtractDNA FFPE', absolute: false},
            {
              nn: 4,
              type: 'link',
              href: 'extract-dna-blood-and-cells',
              text: 'ExtractDNA<br>Blood & Cells',
              absolute: false
            },
            {
              nn: 5,
              type: 'link',
              href: 'cleanup',
              text: 'Cleanup Standard, Cleanup S-Cap и Cleanup Mini',
              absolute: false
            },
            {
              nn: 6, type: 'link', href: 'cleanup-pcr', text: 'Cleanup St PCR<br><span style="color: red; text-decoration:none;">Новинка!</span>', absolute: false},
            {
               nn: 7, type: 'link', href: 'cleanup-gel', text: 'Cleanup St Gel<br><span style="color: red; text-decoration:none;">Новинка!</span>', absolute: false},
            {
              nn: 8, type: 'link', href: 'cleanmag', text: 'CleanMag DNA PCR',
              children: [
                {nn: 1, type: 'link', href: 'magstand', text: 'Магнитные штативы МагСтенд', absolute: false}
              ],
              absolute: false
            },
            {nn: 9, type: 'link', href: 'intact-rna', text: 'IntactRNA', absolute: false},
            {nn: 10, type: 'link', href: 'rna-solo', text: 'RNA Solo', absolute: false},
            {nn: 11, type: 'link', href: 'extract-rna', text: 'ExtractRNA', absolute: false},
            {nn: 12, type: 'link', href: 'clean-rna', text: 'CleanRNA Standard', absolute: false},
            {nn: 13, type: 'link', href: 'ribocare-rnase-inhibitor', text: 'Ингибитор РНКаз RiboCare', absolute: false},
            {nn: 14, type: 'link', href: 'dnase-e', text: 'ДНКаза Е', absolute: false},
            {nn: 15, type: 'link', href: 'bisquick', text: 'BisQuick', absolute: false},
            {nn: 16, type: 'link', href: 'rcf-rpm', text: 'Расчет RCF-RPM<br>on-line', absolute: false},
            {nn: 17, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
          ]
        },
        'nomotech': {
          href: '/products/nomotech',
          caption: 'Генотипирование',
          children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов', absolute: false},
/*            {nn: 2, type: 'link', href: 'insider', text: 'Инсайдер', absolute: false},*/
            {nn: 2, type: 'link', href: 'NAT2', text: 'ГенТест-M NAT2', absolute: false},
            {nn: 3, type: 'link', href: 'F2-F5', text: 'ГенТест F2, F5', absolute: false},
            {nn: 4, type: 'link', href: 'CYP2C9-VKORC1-CYP4F2', text: 'ГенТест CYP2C9, VKORC1, CYP4F2', absolute: false},
            {nn: 5, type: 'link', href: 'CYP2C19', text: 'ГенТест СYP2C19', absolute: false},
            {nn: 6, type: 'link', href: 'SLCO1B1', text: 'ГенТест SLCO1B1', absolute: false},
            {nn: 7, type: 'link', href: 'CES1', text: 'ГенТест CES1', absolute: false},
/*            {nn: 10, type: 'link', href: 'MGMT', text: 'ЭпиГенТест-MGMT', absolute: false},*/
            {nn: 8, type: 'link', href: 'insider-fullras', text: 'Инсайдер FullRAS и Инсайдер KRAS, NRAS, BRAF', absolute: false},
            {nn: 9, type: 'link', href: 'insider-egfr', text: 'Инсайдер EGFR', absolute: false},
            {nn: 10, type: 'link', href: 'BRCA', text: 'ГенТест-М BRCA1, BRCA2', absolute: false},
/*            {nn: 13, type: 'link', href: 'ffpe', text: 'ЭкстрактДНК FFPE', absolute: false},*/
            {nn: 11, type: 'link', href: 'extract-dna2', text: 'ЭкстрактДНК-2', absolute: false},
            {nn: 12, type: 'link', href: '/products/evaluation/quantum', text: 'QuantumDNA',   absolute: true, internal: true}
          ]
        },
        'cdna': {
          href: '/products/cdna',
            caption: 'Работа с кДНК',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов', absolute: false},
            {nn: 2, type: 'link', href: 'synthesis', text: 'Cинтез кДНК<br>и ОТ-ПЦР',
              children: [
                {nn: 1, type: 'link', href: 'mmlv', text: 'MMLV',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 2, type: 'link', href: 'mint', text: 'Mint',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 3, type: 'link', href: 'magnus', text: 'Magnus', absolute: false
                },
                {nn: 4, type: 'link', href: 'onetube', text: 'OneTube',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
              ],
              absolute: false
            },
            {nn: 3, type: 'link', href: 'normalization', text: 'Нормализация кДНК',
              children: [
                {nn: 1, type: 'link', href: 'dsn', text: 'DSN',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
                {nn: 2, type: 'link', href: 'trimmer2', text: 'Trimmer-2',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
                  ],
                  absolute: false
                },
              ],
              absolute: false
            },
            {nn: 4, type: 'link', href: 'race', text: 'Амплификация концевых фрагментов кДНК (RACE)',
              children: [
                {nn: 1, type: 'link', href: 'mint-race-cdna', text: 'Mint RACE cDNA amplification set', absolute: false},
                {nn: 2, type: 'link', href: 'mint-race-primers', text: 'Mint RACE primer set', absolute: false},
              ],
              absolute: false
            },
          ]
        },
        'pcr': {
          href: '/products/pcr',
            caption: 'Полимеразы и готовые смеси',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов', absolute: false},
            {nn: 2, type: 'link', href: 'polymerases', text: 'Полимеразы, наборы реактивов и буферы', absolute: false},
            {nn: 3, type: 'link', href: 'mixes', text: 'Готовые смеси', absolute: false},
            {nn: 3, type: 'link', href: 'bulk', text: 'Большие фасовки (балк)', absolute: false},
          ]
        },
        'mixes': {
          href: '/products/pcr/mixes',
            caption: 'Готовые смеси для ПЦР',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов', absolute: false},
            {nn: 2, type: 'link', href: 'screenmix', text: '5X ScreenMix', absolute: false},
            {nn: 3, type: 'link', href: 'screenmix-hs', text: '5X ScreenMix-HS', absolute: false},
            {nn: 4, type: 'link', href: 'screenmix-hs-udg', text: '<nobr>5X ScreenMix-HS (UDG)</nobr>', absolute: false},
            {nn: 5, type: 'link', href: 'qpcrmix-hs', text: '5X qPCRmix-HS',
              children: [
                {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
              ],
              absolute: false
            },
            {nn: 6, type: 'link', href: 'qpcrmix-hs-udg', text: '<nobr>5X qPCRmix-HS (UDG)</nobr>', absolute: false},
            {nn: 7, type: 'link', href: 'qpcrmix-hs-sybr', text: '<nobr>5X qPCRmix-HS SYBR</nobr>', absolute: false},
            {nn: 8, type: 'link', href: 'qpcrmix-hs-sybr-rox', text: '<nobr>5X qPCRmix-HS</nobr> <nobr>SYBR+HighROX</nobr> <nobr>и 5X qPCRmix-HS</nobr> <nobr>SYBR+LowROX</nobr>', absolute: false},
            {nn: 9, type: 'link', href: 'qpcrmix-hs-rox', text: '<nobr>5X qPCRmix-HS HighROX</nobr> <nobr>и 5X qPCRmix-HS LowROX</nobr>', absolute: false},
            {nn: 10, type: 'link', href: 'ktnmix-hs', text: '5X KTNmix-HS', absolute: false},
            {nn: 11, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
          ]
        },
        'polymerases': {
          href: '/products/pcr/polymerases',
            caption: 'Полимеразы',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Список продуктов', absolute: false},
            {nn: 2, type: 'link', href: 'encyclo', text: 'Encyclo',
              children: [
                {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
              ],
              absolute: false
            },
            {nn: 3, type: 'link', href: 'tersus', text: 'Tersus',
              children: [
                {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
              ],
              absolute: false
            },
            {nn: 4, type: 'link', href: 'snp-detect', text: 'SNPdetect',
              children: [
                {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
              ],
              absolute: false
            },
            {nn: 5, type: 'link', href: 'ktn-hs', text: 'KTN-HS', absolute: false},
            {nn: 6, type: 'link', href: 'taq', text: 'Taq',
              children: [
                {nn: 1, type: 'link', href: 'buffers', text: 'Буферы для Taq полимеразы', absolute: false}
              ],
              absolute: false
            },
            {nn: 7, type: 'link', href: 'hs-taq', text: 'HS Taq',
              children: [
                {nn: 1, type: 'link', href: 'buffers', text: 'Буферы для HS Taq полимеразы', absolute: false},
                {nn: 2, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
              ],
              absolute: false
            },
            {nn: 8, type: 'link', href: 'sybr', text: 'SYBR Green&nbsp;I', absolute: false},
            {nn: 9, type: 'link', href: 'rox', text: 'ROX', absolute: false},
            {nn: 10, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
          ]
        },
        'bulk': {
          href: '/products/pcr',
            caption: 'Полимеразы и готовые смеси',
            children: [
            {nn: 1, type: 'link', href: 'bulk', text: 'Большие фасовки', absolute: false},
          ]
        },
        'services': {
          href: '/services',
            caption: 'Сервисы',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Каталог', absolute: false},
            {nn: 2, type: 'link', href: 'pricelist', text: 'Прайс-лист', absolute: false},
          ]
        },
        'sequences': {
          href: '/sequences',
            caption: 'Секвенирование',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Синтез олигонуклеотидов', absolute: false},
            {nn: 2, type: 'link', href: 'order-form', text: 'Заказ On-line', absolute: false},
            {nn: 3, type: 'link', href: 'contacts', text: 'Контакты', absolute: false},
            {nn: 4, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
            {nn: 5, type: 'link', href: '/order/sample-requirements', text: 'Требования к биологическим материалам', absolute: true, internal: true},
          ]
        },
        'synthesis': {
          href: '/services/synthesis',
          caption: 'Синтез олигонуклеотидов',
          children: [
            {nn: 1, type: 'link', href: '', text: 'Синтез олигонуклеотидов', absolute: false},
            {nn: 2, type: 'link', href: 'order-form', text: 'Заказ On-line', absolute: false},
            {nn: 3, type: 'link', href: 'contacts', text: 'Контакты', absolute: false},
            {nn: 4, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
            {nn: 5, type: 'link', href: '/order/sample-requirements', text: 'Требования к биологическим материалам', absolute: true, internal: true},
          ]
        },

        'sanger': {
          href: '/services/sequencing/sanger',
            caption: 'Секвенирование по Сэнгеру',
            children: [
              {nn: 1, type: 'link', href: '', text: 'Секвенирование по Сэнгеру', absolute: false},
              {nn: 2, type: 'link', href: 'standard-primers', text: 'Стандартные праймеры', absolute: false},
              {nn: 3, type: 'link', href: 'requirements', text: 'Требования к биологическим материалам', absolute: false},
              {nn: 4, type: 'link', href: 'contacts', text: 'Контакты', absolute: false},
              {nn: 5, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
          ]
        },
        'ges': {
          href: '/services/ges',
            caption: 'Генно-инженерные сервисы',
            children: [
            {nn: 1, type: 'link', href: '/services', text: 'Каталог', absolute: true, internal: true},
            {nn: 2, type: 'link', href: 'contacts', text: 'Контакты', absolute: false},
            {nn: 3, type: 'link', href: 'gene-synthesis', text: 'Синтез генов',
              children: [
                {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
              ],
              absolute: false
            },
            {nn: 4, type: 'link', href: 'mutagenesis', text: 'Сайт-направленный мутагенез',
              children: [
                {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
              ],
              absolute: false
            },
            {nn: 5, type: 'link', href: 'pcr-cloning', text: 'Амплификация и клонирование фрагментов ДНК', absolute: false},
            {nn: 6, type: 'link', href: 'amplicon-cloning', text: 'Клонирование коротких <nobr>ПЦР-продуктов</nobr> (ампликонов)', absolute: false},
            {nn: 7, type: 'link', href: '/order/sample-requirements', text: 'Требования к биологическим материалам', absolute: true, internal: true},
          ]
        },
        'chemistry': {
          href: '/services/chemistry',
          caption: 'Химический синтез',
          children: [
            {nn: 1, type: 'link', href: '/services', text: 'Каталог', absolute: true, internal: true},
            {nn: 2, type: 'link', href: 'contacts', text: 'Контакты', absolute: false},
            {nn: 3, type: 'link', href: 'organic-synthesis', text: 'Синтез органических соединений', absolute: false},
            {nn: 4, type: 'link', href: 'development', text: 'Разработка полупромышленных технологий синтеза', absolute: false},
          ]
        },
        'basket': {
          href: '/basket',
          caption: 'Корзина',
          children: [
            {nn: 1, type: 'link', href: '/profile', text: 'Профиль', absolute: true, internal: true},
            {nn: 2, type: 'link', href: '/profile/contacts', text: 'Контактные данные', absolute: true, internal: true},
            {nn: 3, type: 'link', href: '/profile/addresses', text: 'Адреса доставки', absolute: true, internal: true},
            {nn: 4, type: 'link', href: '', text: 'Корзина', absolute: false},
            {nn: 5, type: 'link', href: '/profile/orders', text: 'История заказов', absolute: true, internal: true},
            {nn: 6, type: 'link', href: '/profile/discount', text: 'Персональные скидки', absolute: true, isVisible: this.userHasDiscount, internal: true},
          ]
        },
        'profile': {
          href: '/profile',
            caption: 'Личный кабинет',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Профиль', absolute: false},
            //{nn: 2, type: 'link', href: 'testlk', text: 'Тестовый Профиль', absolute: false},
            {nn: 3, type: 'link', href: 'contacts', text: 'Контактные данные', absolute: false},
            {nn: 4, type: 'link', href: 'addresses', text: 'Адреса доставки', absolute: false},
            {nn: 5, type: 'link', href: '/basket', text: 'Корзина', absolute: true, internal: true},
            {nn: 6, type: 'link', href: 'orders', text: 'История заказов', absolute: false},
            {nn: 7, type: 'link', href: 'discount', text: 'Персональные скидки', absolute: false, isVisible: this.userHasDiscount},
          ]
        },
        'services_bckp': {
          href: '/services',
            caption: 'Сервисы',
            children: [
            {nn: 1, type: 'link', href: 'pricelist', text: 'Прайс-лист', absolute: false},
            {nn: 2, type: 'link', href: 'synthesis', text: 'Синтез праймеров',
              children: [
                {nn: 1, type: 'link', href: 'order-form', text: 'Заказ On-line', absolute: false},
                {nn: 2, type: 'link', href: 'order', text: 'Контакты', absolute: false},
                {nn: 3, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
              ],
              absolute: false
            },
            {nn: 3, type: 'link', href: 'sequencing/sanger', text: 'Секвенирование по Сэнгеру',
              children: [
                {nn: 1, type: 'link', href: 'standard-primers', text: 'Стандартные праймеры', absolute: false},
                {nn: 2, type: 'link', href: 'order', text: 'Контакты', absolute: false},
                {nn: 3, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
              ],
              absolute: false
            },
           /*{nn: 5, type: 'link', href: 'cell-cultures', text: 'Работа с культурами клеток',
              children: [
                {nn: 1, type: 'link', href: 'lentiviruses', text: 'Конструирование лентивирусов',
                  children: [
                    {nn: 1, type: 'link', href: 'order-form', text: 'Заказ On-line', absolute: false}
                  ], absolute: false
                },
                {nn: 2, type: 'link', href: 'rna-i', text: 'Лентивирусные векторы для РНК-интерференции', absolute: false},
                {nn: 3, type: 'link', href: 'transfection', text: 'Временная трансфекция культур клеток',
                  children: [
                    {nn: 1, type: 'link', href: 'order-form', text: 'Заказ On-line', absolute: false},
                    {nn: 2, type: 'link', href: 'cell-lines', text: 'Клеточные линии', absolute: false}
                  ],
                  absolute: false
                },
                {nn: 4, type: 'link', href: 'stable-cell-lines', text: 'Создание клеточных линий', absolute: false}
              ],
              absolute: false
            },*/
            {nn: 6, type: 'link', href: 'ges', text: 'Генно-инженерные сервисы',
              children: [
                {nn: 1, type: 'link', href: 'order', text: 'Контакты', absolute: false},
                {nn: 2, type: 'link', href: 'gene-synthesis', text: 'Синтез генов',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
                  ],
                  absolute: false
                },
                {nn: 3, type: 'link', href: 'mutagenesis', text: 'Сайт-направленный мутагенез',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
                  ],
                  absolute: false
                },
                {nn: 4, type: 'link', href: 'pcr-cloning', text: 'Амплификация и клонирование фрагментов ДНК', absolute: false},
                {nn: 5, type: 'link', href: 'amplicon-cloning', text: 'Клонирование коротких <nobr>ПЦР-продуктов</nobr> (ампликонов)', absolute: false},
                {nn: 6, type: 'link', href: 'normalization', text: 'Нормализация кДНК',
                  children: [
                    {nn: 1, type: 'link', href: 'citing', text: 'Цитирование', absolute: false}
                  ],
                  absolute: false
                },
              ],
              absolute: false
            }
          ]
        },
        'support': {
          href: '/support',
            caption: 'Техническая информация',
            children: [
            {nn: 1, type: 'link', href: '', text: 'Техническая информация', absolute: false},
            {nn: 2, type: 'link', href: 'publications', text: 'Публикации', absolute: false},
            {nn: 3, type: 'link', href: 'citing', text: 'Цитирование', absolute: false},
            {nn: 4, type: 'link', href: 'technologies', text: 'Методы и протоколы',
              children: [
                {nn: 1, type: 'link', href: 'normalization', text: 'Нормализация кДНК', absolute: false},
                {nn: 2, type: 'link', href: 'genome-walking', text: 'Прогулка по хромосоме', absolute: false},
                {nn: 3, type: 'link', href: 'mint-cdna-synthesis', text: 'Mint', absolute: false},
                {nn: 4, type: 'link', href: 'smart', text: 'SMART', absolute: false},
                {nn: 5, type: 'link', href: 'mos', text: 'MOS', absolute: false},
                {nn: 5, type: 'link', href: 'ssh', text: 'SSH', absolute: false},
                {nn: 6, type: 'link', href: 'long-product-amplification', text: 'Амплификация длинной фракции кДНК', absolute: false},
                {nn: 7, type: 'link', href: 'step-out-race', text: 'Step-Out RACE', absolute: false},
                {nn: 8, type: 'link', href: 'wtb-pcr', text: 'WTB-PCR', absolute: false},
                {nn: 9, type: 'link', href: 'rna-electrophoresis', text: 'Электрофорез РНК', absolute: false},
                {nn: 10, type: 'link', href: 'rna-isolation', text: 'Выделение РНК', absolute: false},
                {nn: 11, type: 'link', href: 'plasmid-purification', text: 'Очистка плазмид', absolute: false},
                {nn: 12, type: 'link', href: 'blood-preparation', text: 'Первичная обработка цельной крови', absolute: false}
              ],
              absolute: false
            },
            {nn: 5, type: 'link', href: 'literature', text: 'Презентационная литература', absolute: false},
            {nn: 6, type: 'link', href: 'license-statements', text: 'Лицензионные соглашения',
              children: [
                {nn: 1, type: 'link', href: 'evrogen-fp-license', text: 'Ограниченная лицензия #001', absolute: false},
                {nn: 2, type: 'link', href: 'evrogen-dsn-license', text: 'Ограниченная лицензия #002', absolute: false},
                {nn: 3, type: 'link', href: 'invitrogen-gateway-license', text: 'Лицензия компании Инвитроген No 19', absolute: false}
              ],
              absolute: false
            }
          ]
        }
      }
    }
  },
  data () {
    return {
      path: '/'
    }
  },
  methods: {
    isActive(section, child) {
      let l = this.buildLink(section, child).toLowerCase()
      let r = this.$route.path.toLowerCase()

      // временный хак для одной отдельно взятой страницы, пока меню остается в таком виде
      if ((l === '/products/antibodies' || l === '/products/antibodies/') && (r === '/products/antibodies/citing' || r === '/products/antibodies/citing/')) {
        return false
      }

      return r === l || r === l  + '/' || (child.children && r.includes(l))
    },
    buildLink(section, child) {
      let res = section.href

      if (child.href !== '') {
        res += '/' + child.href
      }

      return res
    }
  }
}
