
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'

export default {
  name: 'ExtractRna',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ExtractRNA',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Реагент для выделения суммарной РНК (Аналог TRIzol)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'тризол, tri reagent, ExtractRNA, trizol, РНК по Хомчинскому, выделение суммарной рнк, получение суммарной рнк'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/BC032_ExtractRNA.jpg'}
      ],
      imageIndex: null
    }
  }
}
