
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'DnaIsolation',
  components: {
    ProductsPriceNotice,
    BuyProductArea
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Наборы и реактивы для выделения и очистки нуклеиновых кислот',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы для выделения и очистки НК из биологического материала, реакционных смесей и агарозного геля.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'выделение днк набор, выделение днк колонки, выделение днк евроген, очистка днк набор, очистка днк колонки, очистка днк евроген, выделение рнк набор, днк колонки, рнк колонки, днк магнитные частицы, рнк магнитные частицы'
        }
      ]
    }
  }
}
