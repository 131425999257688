

import LanguageSelector from '@/components/menu/LanguageSelector'
import TopSearch from '@/components/search/TopSearch'
import TopMenu from '@/components/menu/TopMenu'
import Profile from '@/components/menu/Profile'

export default {
  components: {
    Profile,
    TopMenu,
    TopSearch,
    LanguageSelector
  },
  computed: {
  },
  mounted () {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
