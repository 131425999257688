
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'BasicFP',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Базовые флуоресцентные белки',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'флуоресцентные белки, Taggfp, turbogfp биотехнология, синтез олигонуклеотидов, генная инженерия, наука, евроген, олигонуклеотиды, праймеры, синтез, секвенирование, ферменты, taq полимераза, пцр-рв, клонирование фрагментов ДНК, нормализация кДНК, синтез'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
