
import BuyProductArea from '@/components/BuyProductArea';
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  name: 'CYP2C9-VKORC1-CYP4F2',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ГенТест CYP2C9, VKORC1, CYP4F2',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для выявления аллельных вариантов в генах человека CYP2C9, VKORC1, CYP4F2 методом ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ГенТест CYP2C9 VKORC1 CYP4F2, R2002,тест система CYP2C9, kit CYP2C9, набор CYP2C9, мутация CYP2C9, горячая точка CYP2C9, аллельный вариант CYP2C9, скрининг CYP2C9, тест система VKORC1, kit VKORC1, набор VKORC1, мутация VKORC1, горячая точка VKORC1, аллельный вариант VKORC1, набор CYP4F2, мутация CYP4F2, горячая точка CYP4F2, аллельный вариант CYP4F2'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/R2002_CYP2C9.jpg'}
      ],
      imageIndex: null
    }
  }
}
