
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from "../../../components/ProductsPriceNotice";

export default {
  name: 'RNASolo',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'RNA Solo',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор RNA Solo для выделения суммарной РНК на колонках из клеток и мягких тканей человека и животных, бактерий, дрожжей'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'РНК на колонках, выделение рнк из клеток, выделение рнк из тканей, выделение рнк из бактерий, выделение рнк из дрожжей, RNA Solo, RNASolo, Total RNA Purification, Rneasy'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/BC034_RNA_Solo.jpg'}
      ],
      imageIndex: null
    }
  }
}
