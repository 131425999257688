
import BtnWithLoading from '../BtnWithLoading'

export default {
  name: 'DeleteSequenceModal',
  components: {
    BtnWithLoading
  },
  props: {
    title: '',
    primers: null
  },
  methods: {
    confirmDeletion () {
      this.$nuxt.$emit('delete-sequences')
      this.$emit('close')
    }
  }
}
