import { render, staticRenderFns } from "./BuyProductArea4.vue?vue&type=template&id=e6b059ce&scoped=true"
import script from "./BuyProductArea4.vue?vue&type=script&lang=js"
export * from "./BuyProductArea4.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6b059ce",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductToBasket: require('/var/www/localhost/evrogen.ru/components/ProductToBasket.vue').default})
