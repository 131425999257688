import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=b7ef4f8e&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=b7ef4f8e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7ef4f8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnWithLoading: require('/var/www/localhost/evrogen.ru/components/BtnWithLoading.vue').default,AddClient: require('/var/www/localhost/evrogen.ru/components/AddClient.vue').default,AddressForm: require('/var/www/localhost/evrogen.ru/components/AddressForm.vue').default})
