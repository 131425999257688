
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'qPcrMixHsSybr',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '5X qPCRmix-HS SYBR',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ПЦР-смесь с красителем SYBR Green I'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ПЦР смесь с сайбром, пцр смесь sybr, ПЦР микс с сайбром, пцр микс sybr, pcr mix sybr, qpcrmix sybr, 5X, ПЦР смесь с евой, пцр смесь eva, ПЦР микс с евой, пцр микс eva, pcr mix eva, qpcrmix eva'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
