
export default {
  name: 'About',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Компания Евроген',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Евроген – российская биотехнологическая компания. \n' +
            'Основные направления деятельности: производство наборов для молекулярной и клеточной биологии, а также выполнение сервисных работ на заказ.\n' +
            'Наборы предназначены для выделения ДНК, постановки ПЦР, работы с кДНК и клонирования.\n' +
            'Сервисные работы выполняются по направлениям: генная инженерия, клеточные технологии, секвенирование и синтез олигонуклеотидов.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'биотехнология, евроген, биотехнологическая компания, биотех, молекулярная биология, клеточная биология, наука россия, разработка тест-систем, евроген что, евроген кто, evrogen'
        }
      ]
    }
  }
}
