
import ModifiersPrice from '@/components/page_blocks/ModifiersPrice'
import ModifiersList from '@/components/page_blocks/ModifiersList'
import PrimersTable from '@/components/page_blocks/PrimersTable'
import BtnWithLoading from '@/components/BtnWithLoading'
import DeletePrimersModal from '@/components/modals/DeletePrimersModal'
import ClearSynthesisFormModal from '@/components/modals/ClearSynthesisFormModal'
import PrimersToBasketModal from '@/components/modals/PrimersToBasketModal'
import LinedTextarea from '@/components/LinedTextarea'
import Primer from '@/assets/primer.js'
import _ from 'lodash'
import $ from 'jquery'

export default {
  name: 'OrderForm',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Онлайн заказ на синтез олигонуклеотидов (праймеров)',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  components: {
    BtnWithLoading,
    ModifiersList,
    ModifiersPrice,
    PrimersTable,
    DeletePrimersModal,
    ClearSynthesisFormModal,
    PrimersToBasketModal,
    LinedTextarea
  },
  computed: {
    basket() {
      return this.$store.state.basket.basket
    },
    primers() {
      return this.basket.primers
    },
    modifiers () {
      return this.$store.state.dictionaries.modifiers
    },
    showPrimers() {
      return this.primers
        ? this.primers.showPrimers('draft')
        : []
    },
    simple_state() {
      return this.primers ? this.primers.getState(false) : ''
    },
    mod_state() {
      return this.primers ? this.primers.getState(true) : ''
    },
    all_state() {
      return this.primers ? this.primers.getState() : ''
    },
    simple_count() {
      return this.showPrimers.filter(item => item.mods.length === 0).length
    },
    mod_count() {
      return this.showPrimers.filter(item => item.mods.length > 0).length
    },
    all_count() {
      return this.showPrimers.length
    },
    simple_price() {
      return this.showPrimers.reduce((sum, item) => (item.mods.length === 0
        ? sum + item.discount_price * 1
        : sum), 0)
    },
    mod_price() {
      return this.showPrimers.reduce((sum, item) => (item.mods.length > 0
        ? sum + item.discount_price * 1
        : sum), 0)
    },
    all_price() {
      return this.showPrimers.reduce((sum, item) => sum + item.discount_price * 1, 0)
    },
    withDiscount() {
      return this.showPrimers.length > 0 && this.showPrimers.some(item => item.price*1 > 0 && item.price !== item.discount_price)
    },
    primersErrors() {
      let rows = this.showPrimers.map(p => ({name: p.name.toLowerCase(), seq: p.buildHtml('text', false)}));
      let groups = _.groupBy(rows, 'name');
      if ( Object.values(groups).some(arr => (new Set(arr.map(p => p.seq))).size > 1 )) {
        return true;
      }

      return this.showPrimers.some(item => item.isErrors())
    }
  },
  created () {
    this.$nuxt.$on('delete-primers', (primers) => {
      this.doDeletePrimers(primers)
    })

    this.$nuxt.$on('clear-primers-form', () => {
      this.doClearForm()
    })
  },
  mounted() {
    this.basket.onUpdatePrimers = this.updatePrimers
  },
  beforeDestroy() {
    this.$nuxt.$off('delete-primers')
    this.$nuxt.$off('clear-primers-form')
  },
  data () {
    return {
      isImportingNow: false,
      exportErrors: [],
      doneMessageShow: false,
      errorMessage: '',
      showDescription: false,
      showModifiers: false,
      showPrice: false,
      importSrc: ''
    }
  },
  methods: {
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    importPrimers() {
      this.isImportingNow = true;
      let src = this.importSrc;
      this.importSrc = '';

      this.$nextTick(() => {
        try {
          this.doImportPrimers(src)
        } finally {
          this.isImportingNow = false;
        }
      });
    },

    async doImportPrimers(src) {
      const setTestPrimers = type => {
        const primer = {
          _s: 'тестовый праймер для простого заказа;ACGT;0.04\n' +
            'еще один тестовый праймер для простого заказа;UIACGT;0.04\n',
          _m: 'тестовый праймер для модифицированого заказа;ACGT-/Alk/;0.04\n' +
            'тестовый праймер для модифицированого заказа;/6-FAM/-ACGT;0.04\n' +
            'тестовый праймер для модифицированого заказа;AC-/dT-BHQ-2/GT;0.04\n' +
            'тестовый праймер для модифицированого заказа;/6-FAM/-AC-/dT-BHQ-2/GT-/Alk/;0.04\n',
          _mx: 'тестовый праймер для mix заказа;ACGT;0.04\n' +
            'еще один тестовый праймер для mix заказа;UIACGT;0.04\n' +
            'тестовый праймер для mix заказа;ACGT-/Alk/;0.04\n' +
            'тестовый праймер для mix заказа;/6-FAM/-ACGT;0.04\n' +
            'тестовый праймер для mix заказа;AC-/dT-BHQ-2/GT;0.04\n' +
            'тестовый праймер для mix заказа;/6-FAM/-AC-/dT-BHQ-2/GT-/Alk/;0.04\n'
        }
        let result = false
        if (type[ type.length - 1 ] === 'b') {
          type = type.substr(0, type.length - 1)
          result = true
        }

        if (!primer.hasOwnProperty(type)) {
          return false
        }
        this.importSrc = primer[ type ]
        return result
      }

      function pad(num, size) {
        const s = `0000${num}`
        return s.substr(s.length - size)
      }

      this.exportErrors = []
      let errorCount = 0
      let errorLines = ''
      const context = this

      const writeLogLine = function(type, message, srcErrorLine) {
        context.exportErrors.push({
                                    line: pad(errorCount + 1, lines.length.toString().length),
                                    message
                                  })
        errorLines += `${srcErrorLine}\n`
        errorCount++
      }

      const findColumns = function(srcCols) {
        const result = { length: 0 }
        let position
        let nameFieldIsExist
        const scaleIsFind = srcCols.some((item, index) => {
          const isScale = ['0.02', '0.04', '0.2', '1', '2', '5', '15', '80'].indexOf(item.replace(/,/g, '.')) >= 0

          if (index > 0 && isScale) {
            position = index >= 2 ? (index - 2) : (index - 1)
            nameFieldIsExist = index >= 2
            return true
          }
          return false
        })

        if (!scaleIsFind) {
          if (srcCols.length === 1) {
            result.length = 1
            result.sequence = 0
          } else if (srcCols.length === 2) {
            result.length = 2
            result.name = 0
            result.sequence = 1
          }

          return result
        }
        ['name', 'sequence', 'scale', 'clean', 'keen'].some((item) => {
          if ((item !== 'name') || (nameFieldIsExist)) {
            result[ item ] = position
            result.length++
            position++
          }
          return position === srcCols.length
        })
        return result
      }

      const checkAndReplaceScale = function(cols, scalePosition) {
        [[2, 0.04], [5, 0.04], [15, 0.2], [80, 1]].some((item) => {
          if (item[ 0 ] === parseInt(cols[ scalePosition ])) {
            cols[ scalePosition ] = item[ 1 ].toString()
          }
        })
        return [0.02, 0.04, 0.2, 1].some((item) => {
          const parsedValue = parseFloat(cols[ scalePosition ].replace(/,/g, '.'))
          if (item === parsedValue) {
            cols[ scalePosition ] = parsedValue
            return true
          }
          return false
        })
      }

      const buildOptions = function(cols, colPos) {
        return ['clean', 'op', 'keen']
        .filter(option => option in colPos &&
          ['1', 'true', 'да', 'yes', 'y', '+', 'hplc', 'вэжх'].some(item => item === cols[ colPos[ option ] ].trim().toLowerCase()))
      }

      let goToBasket = false
      if (this.importSrc.length <= 4) {
        goToBasket = setTestPrimers(this.importSrc)
      }

      const lines = src.split('\n')
      const newPrimers = []

      lines.forEach((line) => {
        if (line.trim().length === 0) {
          return
        }
        const cols = line.replace(/\t/g, ';').split(';').filter(item => item.trim().length > 0).map(item => item.trim())
        const colPos = findColumns(cols)
        if ((!colPos.hasOwnProperty('sequence')) || (!colPos.hasOwnProperty('scale') && colPos.length > 2) || (colPos.length > 6)) {
          writeLogLine('error', 'Не удалось импортировать, проверьте содержимое строки и порядок полей', line)
          return
        }
        if (colPos.scale && !checkAndReplaceScale(cols, colPos.scale)) {
          writeLogLine('error', 'Неверно указана шкала синтеза. Допустимые значения - 0.04, 0.2, 1 (nmol)', line)
          return
        }
        let primer
        try {
          primer = Primer.parse(cols[ colPos.sequence ], true)
        } catch (ex) {
          writeLogLine('error', ex.toString(), line)
          return
        }
        let maxLength = Primer.getMaxLength(primer.scale);
        if (primer.lengthWithMods() > maxLength) {
          writeLogLine('error', `Максимальная длина праймера ${maxLength}н`, line)
          return
        }

        primer.name = (!('name' in colPos))
          ? ''
          : (colPos.name > 0 // Если перед названием есть еще калонки -сливаем их в название
            ? cols.slice(0, colPos.name + 1).join(';')
            : cols[ colPos.name ])
        primer.scale = colPos.scale ? cols[ colPos.scale ] : '0.04'
        primer.options = buildOptions(cols, colPos)
        if ((!colPos.clean) && primer.isForceClean()) {
          primer.options.push('clean')
        }
        goToBasket && (primer.editState = 'basket')
        newPrimers.push(primer)
      })

      if (newPrimers.length > 0) {
        newPrimers.forEach(primer => this.setPrimerCleanByIorU(primer))
        this.primers.addPrimers(newPrimers, this.$store)
        try {

          let maxNumber = Math.max(...this.$store.state.basket.basket.primers.items.map(p => p.numberInOrder));
          await this.$store.dispatch('basket/savePrimers', { primers: newPrimers, maxNumber })
        } catch (ex) {
          this.primers.deletePrimers(newPrimers)
          this.showDialog('Ошибка сохранения корзины', ex)
          return
        }
      }

      if (goToBasket) {
        this.$router.push('/basket')
      } else {
        this.importSrc = errorLines
      }
    },

    setPrimerCleanByIorU(primer) {
      if (Primer.canClean(primer.scale) && primer.lastCharIsUorI() && !primer.options.includes('clean')) {
        primer.options.push('clean');
      }
    },

    async savePrimersToBase(primers) {
      if (!Array.isArray(primers)) {
        primers = [primers]
      }
      primers.forEach(primer => this.setPrimerCleanByIorU(primer))
      try {
        let maxNumber = Math.max(...this.$store.state.basket.basket.primers.items.map(p => p.numberInOrder));
        await this.$store.dispatch('basket/savePrimers', {primers, maxNumber})
      } catch (ex) {
        this.showDialog('Ошибка сохранения корзины', ex)
        return false
      }

      return true
    },

    showDeletePrimersModal (primers) {
      this.$modal.show(
        DeletePrimersModal,
        {
          title: 'Подтвердите удаление',
          primers: primers
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true,
          width: '522px',
          minWidth: 522
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    doDeletePrimers (primers) {
      this.primers.deletePrimers(primers)
      this.savePrimersToBase(primers)
    },

    showClearFormModal () {
      this.$modal.show(
        ClearSynthesisFormModal,
        {
          title: 'Подтвердите удаление'
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    doClearForm () {
      this.primers.deletePrimers(this.primers.items.filter(primer => primer.editState === 'draft'))
      this.savePrimersToBase(this.primers.items)
    },

    showToBasketModal () {
      this.$modal.show(
        PrimersToBasketModal,
        {
          message:'Содержимое формы добавлено в корзину',
          title: '',
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    async toBasket() {
      this.doneMessageShow = false
      this.errorMessage = ''
      $('#errMessageRow').hide()
      this.primers.items.forEach(primer => primer.editState = 'basket')

      if (!await this.savePrimersToBase(this.primers.items)) {
        return
      }

      this.showToBasketModal()
    },

    onClickAddPrimer() {
      this.doneMessageShow = false
      this.errorMessage = ''
    },

    async buildModifiers() {
      await modifierButtons.generateButtons(this.modifiers)
    },

    updatePrimers(primers) {
      this.primers = primers
    },

    formatPrice(src) {
      return this.$myHelper.priceFormat(src)
    }
  }
}
