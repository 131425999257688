
import BtnWithLoading from '@/components/BtnWithLoading'
import PagerSearchCmp from '@/components/PagerSearchCmp'
import Primer from '@/assets/primer.js'
import { VueSelect } from "vue-select";
import 'vue-select/dist/vue-select.css';
import Paginate from 'vuejs-paginate/src/components/Paginate.vue';
import copyOrderPromptModal from '@/components/modals/CopyOrderPromptModal';
import PrimersToBasketModal from "../../components/modals/PrimersToBasketModal.vue";
import SelectDatesModal from "../../components/modals/SelectDatesModal.vue";


export default {
  name: 'UserOrdersTest',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    Paginate,
    BtnWithLoading,
    PagerSearchCmp,
    VueSelect
  },
  head() {
    return {
      title: 'История заказов',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    modifiers() {
      return this.$store.state.dictionaries.modifiers
    },
    pagesCount() {
      return Math.ceil(this.allCount / this.pageSize)
    },
    baseUrl() {
      return this.$myHelper.baseUrl
    }
  },
  async mounted() {
    await this.$store.dispatch('user/loadUser')

    if (!this.user || !this.user.id) {
      this.$router.push('/auth/login?ret=/profile/orders')
    }

    await this.$store.dispatch('dictionaries/loadModifiersAndBulks')
    Primer.initStaticFields(this.$store.state.dictionaries)
    await this.doSearch();
  },
  data() {
    return {
      pageSize: 30,
      allCount: 0,
      currentPage: 1,
      metaorders: [],
      openOrders: [],
      openComments: [],
      selectedState: null,
      selectedType: null,
      searchText: '',
      exportSelect: null,

      types: [
        { type: 'pdt', label: 'Продукты (PDT)' },
        { type: 'sst', label: 'Олигонуклеотиды без модификаций (SST)' },
        { type: 'mst', label: 'Олигонуклеотиды c модификациями (MST)' },
        { type: 'seq', label: 'Секвенирование (SEQ)' },
        { type: 'srv', label: 'Генно-инженерные сервисы (SRV)'}
      ],

      states: [
        { state: 'newOrder', name: 'Новый' },
        { state: 'waitDelivery', name: 'Новый'},
        { state: 'awaitApprove', name: 'Согласовывается' },
        { state: 'inWork', name: 'В работе' },
        { state: 'done', name: 'Завершен' },
        { state: 'deleted', name: 'Удален' },
        { state: 'annulled', name: 'Удален' }
      ]
    }
  },
  methods: {

    clearBasket() {
      const delPrimers = this.$store.state.basket.basket.primers.showPrimers()
      this.$store.state.basket.basket.primers.deletePrimers(delPrimers)
      this.$store.state.basket.basket.products.forEach(item => item.forDelete = true)
    },

    copyPdtOrder(order) {
      let oldCat = [];
      let doubleCat = [];
      order.productArray.forEach((historyProduct, idx) => {
        const product = this.$store.state.dictionaries.productPrice.find(pp => pp.id * 1 === historyProduct.product_fk * 1);
        if (product) {
          if (!this.$store.state.basket.basket.products.some(p => p.product_fk * 1 === product.id * 1)) {
            let newProductRow = _.cloneDeep(historyProduct);
            newProductRow.id = -(idx + 1);
            newProductRow.order_fk = '';
            newProductRow.price = (product.price - product.price / 100 * product.discount_percent).toFixed(2);
            newProductRow.discount_price = (product.discount_price - product.discount_price / 100 * product.discount_percent).toFixed(2);
            newProductRow.basket_id = '';
            newProductRow.discount_percent = product.discount_percent * 1;
            this.$store.state.basket.basket.products.push(newProductRow);
          } else {
            doubleCat.push(product.cat);
          }
        } else {
          oldCat.push(historyProduct.cat);
        }
      });
      return { oldCat, doubleCat };
    },

    copyPrmOrder(order) {
      let primers = _.cloneDeep(order.primerArray);
      primers.forEach(p => p.editState = 'basket')
      this.$store.state.basket.basket.primers.addPrimers(primers, this.$store)
    },

    async savePrimersToBase(primers) {
      if (!Array.isArray(primers)) {
        primers = [primers]
      }

      try {
        let maxNumber = Math.max(...this.$store.state.basket.basket.primers.items.map(p => p.numberInOrder));
        await this.$store.dispatch('basket/savePrimers', { primers, maxNumber })
      } catch (ex) {
        this.showDialog('Ошибка сохранения корзины', ex)
        return false
      }
      return true
    },


    async onClickCopyMetaorder(metaorder) {
      const doCopyFn = async(result) => {
        if (!result) {
          return;
        }
        if (result.isReplace) {
          this.clearBasket();
        }

        let oldCatArr = [];
        let doubleCatArr = [];

        result.orders.forEach(order => {
          if (order.type === 'pdt') {
            let { oldCat, doubleCat } = this.copyPdtOrder(order);
            oldCat.length > 0 && oldCatArr.push(...oldCat);
            doubleCat.length > 0 && doubleCatArr.push(...doubleCat);
          } else {
            this.copyPrmOrder(order);
          }
        });
        await this.savePrimersToBase(this.$store.state.basket.basket.primers.items)
        await this.$store.dispatch('basket/saveProducts')
        this.$modal.show(
          PrimersToBasketModal,
          {
            message:'<p style="text-transform: none; font-size: 143%; text-align: center;">Позиции успешно добавлены!</p><p style="margin-top: 5px; font-size: 14px; text-transform: none; letter-spacing: 0.03em; line-height: 1.5em;">В корзине можно скорректировать заказ перед его оформлением.</p>',
            title: '',
          },
          {
            resizable: false,
            draggable: false,
            clickToClose: true
          },
          {
            'before-open': this.$myHelper.onModalOpened,
            'before-close': this.$myHelper.onModalClosed
          }
        )
      }

      const basketIsEmpty = ((this.$store.state.basket.basket.primers?.items?.length || 0)
        + (this.$store.state.basket.basket.products?.length || 0)) === 0

      const filteredOrders = metaorder.orders.filter(o => ['sst', 'mst', 'pdt'].includes(o.type));
      if (basketIsEmpty && filteredOrders.length === 1) {
        await doCopyFn({ isReplace: false, orders: filteredOrders });
        return;
      }
      this.$modal.show(
        copyOrderPromptModal,
        {
          close: doCopyFn,
          orders: filteredOrders,
          basketIsEmpty
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    onClickRow(metaorderId) {
      if (!this.openOrders.includes(metaorderId)) {
        this.openOrders.push(metaorderId);
      } else {
        this.openOrders = this.openOrders.filter(id => id !== metaorderId);
      }
    },

    openCloseComment(metaorderId) {
      if (!this.openComments.includes(metaorderId)) {
        this.openComments.push(metaorderId);
      } else {
        this.openComments = this.openComments.filter(id => id !== metaorderId);
      }
    },

    commentIsOpen(metaorderId) {
      return this.openComments.includes(metaorderId);
    },

    buildOpenCloseStyle(metaorderId) {
      if (!this.commentIsOpen(metaorderId)) {
        return 'height:50px; overflow:hidden';
      }
      return ''
    },


    onPaginateClick(value) {
      this.doSearch();
    },

    getMetaorderStateName(metaorder) {
      let states = ['Новый', 'Согласовывается', 'В работе']
      let distinctStates = metaorder.orders
      .map(o => this.getOrderStateName(o))
      .filter((value, index, array) => array.indexOf(value) === index);

      distinctStates = distinctStates.filter(s => s !== 'Удален');
      switch ( distinctStates.length ) {
        case 0:
          return 'Удален';
        case 1:
          return distinctStates[ 0 ];
      }
      const orderedStates = states.filter(s => distinctStates.includes(s));
      return orderedStates[orderedStates.length - 1];
    },

    getOrderStateName(order) {
      let state = order.state;
      if (order.type === 'srv' && order.state === 'waitDelivery') {
       state = 'awaitApprove'
      }
      const findState = this.states.find(s => s.state === state);
      return findState?.name || 'В работе';
    },

    getStatusClass(statusName) {
      switch ( statusName ) {
        case 'Согласовывается':
        case 'Новый':
          return 'order-status--to-agreed';
        case 'В работе':
          return 'order-status--in-progress';
      }
      return '';
    },

    closeOrder(metaorderId) {
      this.openOrders = this.openOrders.filter(m => m !== metaorderId)
    },

    orderDoneCount(metaorder) {
      const doneCount = metaorder.orders.filter(o => o.state === 'done').length;
      return `${doneCount}/${metaorder.orders.length}`;
    },

    async printSeqOrder(order) {
      let options = {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer'
      };
      let data = await this.$axios.post('/site/v2/sequences/SequenceOrderPdf', JSON.stringify(order.id * 1), options);

      let blobData = new Blob([data.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blobData);
      link.setAttribute('download', `order_${order.number}.pdf`);
      document.body.appendChild(link);
      link.click();
    },

    formatPrice(src) {
      return this.$myHelper.priceFormat(src)
    },

    async doExport() {

      await this.$modal.show(
        SelectDatesModal,
        {
        },
        {
          width: '480px',
          resizable: false,
          draggable: false,
          clickToClose: true
        }
      )
      this.exportSelect = null;
    },

    async doSearch() {
      const data = {
        pageSize: this.pageSize,
        page: this.currentPage - 1,
        search: this.searchText.trim(),
        type: this.selectedType?.type,
        srcState: this.selectedState?.state
      }
      try {
        const response = await this.$myHelper.axiosWithMyEx('/profile/getHistory', data)
        this.allCount = response.count
        this.metaorders = response.metaorders.map(metaorder => {
          metaorder.orders.filter(o => o.type === 'sst' || o.type === 'mst').forEach(order => {
            order.primerArray = order.primerArray.map(srcPrimer => {
              const newPrimer = Primer.parse(srcPrimer.sequenceView, this.modifiers)
              newPrimer.price = srcPrimer.price
              newPrimer.name = srcPrimer.name
              newPrimer.scale = srcPrimer.scale
              newPrimer.options = srcPrimer.options
              return newPrimer
            })
          })
          return metaorder;
        })
      } catch (ex) {
      }
    }
}
}
