
export default {
  name: '',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Условия доставки',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Евроген - доставка по всей России'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'доставка евроген, доставка выделение днк, доставка выделение рнк, доставка полимераза, доставка ПЦР смесь, доставка праймеров, доставка евроген, курьер евроген'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
