
import Primer from '@/assets/primer.js'
import BtnWithLoading from '../BtnWithLoading'
import PrimerView from '../PrimerView'
import PrimerEdit from '../PrimerEdit'
import ModifiersButtons from '../ModifiersButtons'
import $ from 'jquery'
import _ from 'lodash'

export default {
  name: 'PrimersTable',
  props: ['primers', 'editState'],
  components: {
    BtnWithLoading,
    PrimerView,
    PrimerEdit,
    ModifiersButtons
  },
  computed: {
    showPrimers() {
      return this.primers
        ? this.primers.showPrimers(this.editState)
        : []
    },
    filteredPrimers() {
      return this.showPrimers.filter(function (item) {
        return item.selected
      })
    },
    simple_state() {
      return this.primers ? this.primers.getState(false) : ''
    },
    mod_state() {
      return this.primers ? this.primers.getState(true) : ''
    },
    all_state() {
      return this.primers ? this.primers.getState() : ''
    },

    simple_count() {
      return this.showPrimers.filter(function (item) {
        return item.mods.length === 0
      }).length
    },
    mod_count() {
      return this.showPrimers.filter(function (item) {
        return item.mods.length > 0
      }).length
    },
    all_count() {
      return this.showPrimers.length
    },

    simple_price() {
      return this.showPrimers.reduce(function (sum, item) {
        return item.mods.length === 0
          ? sum + item.price
          : sum
      }, 0)
    },
    mod_price() {
      return this.showPrimers.reduce(function (sum, item) {
        return item.mods.length > 0
          ? sum + item.price
          : sum
      }, 0)
    },
    all_price() {
      return this.showPrimers.reduce(function (sum, item) {
        return sum + item.price
      }, 0)
    },

    primersErrors() {
      return this.showPrimers().some(function (item) {
        return item.isErrors()
      })
    }
  },
  data() {
    return {
      editOnePrimerMode: false,
      checkedModeArray: [],
      groupOptionsCheckArray: [],
      groupScaleValue: '0.04',
      activePrimer: null,
      exportErrors: [],
      savedPrimer: null
    }
  },
  async mounted() {
    this.$nuxt.$on('click-modifier', this.onClickModifier)
    this.$nuxt.$on('click-group-modifier', this.onClickGroupModifier)
    this.$nuxt.$on('click-editor-modifier', this.onClickModifierInEditor)

    document.body.addEventListener('mscontrolselect', function (e) {
      e.preventDefault()
    })
    document.execCommand('enableObjectResizing', false, false)

    if (!document.caretRangeFromPoint) {
      document.caretRangeFromPoint = function (x, y) {
        function inRect(x, y, rect) {
          return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom
        }

        function inObject(x, y, object) {
          var rects = object.getClientRects()
          for (var i = rects.length; i--;) {
            if (inRect(x, y, rects[i])) {
              return true
            }
          }
          return false
        }

        function getTextNodes(node, x, y) {
          if (!inObject(x, y, node)) {
            return []
          }

          var result = []
          node = node.firstChild
          while (node) {
            if (node.nodeType === 3) {
              result.push(node)
            }
            if (node.nodeType === 1) {
              result = result.concat(getTextNodes(node, x, y))
            }

            node = node.nextSibling
          }

          return result
        }

        var element = document.elementFromPoint(x, y)
        var nodes = getTextNodes(element, x, y)
        if (!nodes.length) {
          return null
        }
        var node = nodes[0]

        var range = document.createRange()
        range.setStart(node, 0)
        range.setEnd(node, 1)

        for (var i = nodes.length; i--;) {
          node = nodes[i]
          var text = node.nodeValue

          range = document.createRange()
          range.setStart(node, 0)
          range.setEnd(node, text.length)

          if (!inObject(x, y, range)) {
            continue
          }

          for (var j = text.length; j--;) {
            if (text.charCodeAt(j) <= 32) {
              continue
            }

            range = document.createRange()
            range.setStart(node, j)
            range.setEnd(node, j + 1)

            if (inObject(x, y, range)) {
              range.setEnd(node, j)
              return range
            }
          }
        }

        return range
      }
    }
  },
  methods: {
    checkPrimerDoubleName(primer) {
      let seq = primer.buildHtml('text', false)
      let findPrimers = this.showPrimers
              .filter(p => p.name.toLowerCase() === primer.name.toLowerCase() && p.buildHtml('text', false) !== seq)
      return findPrimers.length > 0 && this.showPrimers.indexOf(primer) > this.showPrimers.indexOf(findPrimers[0]);
    },


    onClickModifierInEditor (data) {
      this.activePrimer.cursorPosition = data
    },

    savePrimer (primer) {
      this.activePrimer = null
      this.$emit('save-primer', primer)
    },
    cancelEditPrimer(primer) {
      this.activePrimer = null
      const idx = this.primers.items.indexOf(primer)
      if (primer.id === -1) {
        this.primers.items.splice(idx, 1)
      } else {
        const primerForReplace = _.cloneDeep(this.savedPrimer)
        this.$set(this.primers.items, idx, primerForReplace)
        this.savedPrimer = null
      }
    },

    onClickModifier (modAndPosition) {
      if (this.activePrimer) {
        this.$nuxt.$emit('insert-modifier', {
          target: this.activePrimer.id,
          modifier: modAndPosition.modifier,
          position: modAndPosition.position
        })
      }
    },

    onClickGroupModifier (modAndPosition) {
      if (!this.primers) {
        return
      }

      this.filteredPrimers.forEach(primer => {
        const primerLength = primer.lengthWithMods()
        let positionForMod = modAndPosition.position === 'left' ? 0 : (primerLength - 1)
        const existModIdx = _.findIndex(primer.mods, modifier => modifier.position * 1 === positionForMod)
        if (existModIdx >= 0 && primerLength > 1) {
          primer.mods.splice(existModIdx, 1, {position: positionForMod, mod: modAndPosition.modifier})
        } else {
          positionForMod = modAndPosition.position === 'left' ? 0 : (positionForMod + 1)
          primer.addModifier(modAndPosition.modifier, positionForMod)
        }
      })
      this.onChangePrimer(this.filteredPrimers)
      this.$emit('group-change-primers', this.filteredPrimers)
    },

    onSetActivePrimer (primer) {
      if (this.activePrimer) {
        this.$emit('save-primer', this.activePrimer)
        this.activePrimer.primerIsActive = false
      }
      this.savedPrimer = _.cloneDeep(primer)
      this.activePrimer = primer
      this.activePrimer.primerIsActive = true
      primer.cursorPosition = 0
    },

    onChangePrimer (primer) {
      this.$store.dispatch('basket/loadPrimersPrice', {primers: primer})
    },

    onChangeCheckGrop (event) {
      var context = this
      var eventEl = $(event.srcElement ? event.srcElement : event.target)
      var clickValue = eventEl.val()
      this.checkedModeArray = eventEl.prop('checked') ? [clickValue] : []

      this.primers.items.forEach(function (item) {
        var checkMode = context.checkedModeArray.length > 0 ? clickValue : 'none'
        switch (checkMode) {
          case 'none':
          case 'all' : {
            item.selected = checkMode === 'all'
            return
          }
          case 'simple' :
          case 'modified' : {
            item.selected = checkMode === 'simple'
              ? (item.mods.length === 0)
              : (item.mods.length !== 0)
            return
          }
        }
      })

      var tbCheckBoxes = $('#tbCheckBoxes')
      if ((clickValue !== 'all') || (clickValue === 'all' && tbCheckBoxes.hasClass('cbSelectShow'))) {
        tbCheckBoxes.toggleClass('cbSelectShow')
      }
    },

    moveEditRow (args) {
      var isUp = args.keyCode === 38
      if (!this.showPrimers || this.showPrimers.length === 0) {
        return
      }
      if (!this.activePrimer) {
        this.activePrimer = this.showPrimers[0]
        return
      }
      var currentIndex = this.showPrimers.indexOf(this.activePrimer)
      if (currentIndex === 0 && isUp) {
        this.activePrimer = this.showPrimers[this.showPrimers.length - 1]
        return
      }
      if (currentIndex === this.showPrimers.length - 1 && (!isUp)) {
        this.activePrimer = this.showPrimers[0]
        return
      }
      this.activePrimer = this.showPrimers[currentIndex + (isUp ? -1 : 1)]
    },

    setScaleForSelected (event) {
      this.filteredPrimers.forEach(function (item) {
        item.scale = $(event.srcElement ? event.srcElement : event.target).val()
        if (!Primer.canClean(item.scale)) {
          item.setOptions('clean', false);
        }
        if (!Primer.canKeen(item.scale, item)) {
          item.setOptions('keen', false);
        }
      })
      this.onChangePrimer(this.filteredPrimers)
      this.$emit('group-change-primers', this.filteredPrimers)
    },

    setOptionForSelected (event) {
      const eventEl = event.srcElement ? event.srcElement : event.target

      const value = $(eventEl).val()
      const checked = $(eventEl).prop('checked')

      this.filteredPrimers.forEach(function (item) {
        let canSet =
          item.scale &&
          value === 'clean'
          ? Primer.canClean(item.scale)
          : Primer.canKeen(item.scale, item);
        if (value === 'clean' && item.isForceClean()) {
          item.setOptions(value, true);
        } else {
          item.setOptions(value, checked && canSet);
        }
      })
      this.onChangePrimer(this.filteredPrimers)
      this.$emit('group-change-primers', this.filteredPrimers)
    },

    newPrimer () {
      if (this.activePrimer) {
        this.$emit('save-primer', this.activePrimer)
      }

      this.activePrimer = new Primer()
      this.activePrimer.editState = this.editState ? this.editState : 'draft'
      this.primers.addPrimers(this.activePrimer, this.$store)
      this.$emit('click-add-primer', this.activePrimer)
    },

    deletePrimers (primers) {
      if (primers.indexOf(this.activePrimer) >= 0) {
        this.activePrimer = null
      }
      this.$emit('delete-primers', primers)
    }

  }
}
