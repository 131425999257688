
import ServicesGes from '@/components/page_blocks/ServicesGes';
import ServicesSynthesisAndSanger from '@/components/page_blocks/ServicesSynthesisAndSanger';
import ServicesChemistry from "@/components/page_blocks/ServicesChemistry";
export default {
  name: 'Services',
  components: {
    ServicesSynthesisAndSanger,
    ServicesGes,
    ServicesChemistry
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Сервисы Евроген',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Сервисные работы выполняются по направлениям: синтез олигонуклеотидов, ' +
            'секвенирование по Сэнгеру, генная инженерия (синтез генов, мутагенез, клонирование)' +
            ' и клеточные технологии (конструирование лентивирусов, трансфекция культур клеток).'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'евроген услуги, евроген сервисы, генная инженерия, культуры клеток, клеточные технологии'
        }
      ]
    }
  }
}
