
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPatentsNotice from "@/components/ProductsPatentsNotice";
import ProductsSpecialPriceNotice from "@/components/ProductsSpecialPriceNotice";

export default {
  name: 'Casper3-GR',
  components: {
    ProductsSpecialPriceNotice,
    ProductsPatentsNotice,
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Casper3-GR',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Генетически-кодируемый флуоресцентный биосенсор Casper3-GR'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
