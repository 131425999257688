
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
export default {
  name: 'QuantumDna',
  components: {
    ProductsPriceNotice,
    BuyProductArea
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'QuantumDNA',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы для оценки концентрации и качества геномной ДНК человека.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'QuantumDNA, КвантумДНК, определение концентрации ДНК, ингибиторы ПЦР, пригодность для ПЦР, фрагментация ДНК, качество ДНК, оценка концентрации днк, оценка качества днк, проверка концентрации днк, проверка качества днк, днк из FFPE оценка, днк из FFPE проверка, оценка днк str, проверка днк str'
        }
      ]
    }
  }
}
