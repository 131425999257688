
import BtnWithLoading from '../BtnWithLoading'

export default {
  name: 'DeleteSequenceModal',
  components: {
    BtnWithLoading
  },
  props: {
    onClose: null,
  },
  methods: {
    onClickCancel() {
      this.onClose('cancel')
      this.$emit('close')
    },

    onClickNext() {
      this.onClose('import')
      this.$emit('close')
    },

    onClickPrimers() {
      this.onClose('primers')
      this.$emit('close')
    }
  }
}
