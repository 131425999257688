
import _ from 'lodash'

export default {
  name: 'ModifiersList',
  props: ['variant'],
  computed: {
    modifiers: function () {
      return this.$store.state.dictionaries.modifiers
    },
    modGroups: function () {
      const filterMod = position => this.modifiers.filter(modifier => modifier.acceptedPositionArray.indexOf(position) >= 0)

      const leftArr = filterMod('left')
      const rightArr = filterMod('right')
      const inArr = filterMod('in')
      const maxCount = _.maxBy([leftArr, rightArr, inArr], 'length').length
      const result = []
      for (var i = 0; i < maxCount; i++) {
        result.push([
          i < leftArr.length ? (leftArr[i].name.toLowerCase() === '6-fam' ? '6-FAM*' : leftArr[i].name) : '',
          i < inArr.length ? inArr[i].name : '',
          i < rightArr.length ? (rightArr[i].name.toLowerCase() === '6-fam' ? '6-FAM*' : rightArr[i].name) : ''
        ])
      }

      return result
    },
    preparedModifiers() {
      return _.sortBy(this.modifiers, item => item.name.toLowerCase())
        .map(mod => ({
            name: mod.name,
            isDeleted: mod.isDeleted,
            left: mod.acceptedPositionArray.indexOf('left') >= 0,
            center: mod.acceptedPositionArray.indexOf('in') >= 0,
            right: mod.acceptedPositionArray.indexOf('right') >= 0
          })
        ).filter(mod => !mod.isDeleted && ( mod.left || mod.center || mod.right ))
    }
  }
}
