

import BuyProductArea from '@/components/BuyProductArea';
import ProductsPriceNotice from "@/components/ProductsPriceNotice";

export default {
  name: 'Cleanup',
  components: {
    ProductsPriceNotice,
    BuyProductArea
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Cleanup St Gel',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы для очистки ДНК из любых ферментативных реакционных смесей и всех типов агарозных гелей. Очистка осуществляется на микроцентрифужных колонках.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'днк агарозный гель, днк агароза, выделение днк гель, очистка днк гель, днк реакционные смеси, днк , выделение днк смеси, очистка днк смеси, агароза колонки, смеси колонки, набор Cleanup'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/BC027S.jpg'}
      ],
      swipeItems:             [
        {src: '/img/BC027S.jpg', thumbnail:'/icons/BC027S.jpg' , w: 1200,h: 800, title: 'BC027S.jpg'}
      ],
      imageIndex: null
    }
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new this.$PhotoSwipeLightbox({
        gallery: '#myGallery',
        children: 'a.grl',
        pswpModule: this.$PhotoSwipe,
      });
      this.lightbox.init();
    }
  },
}
