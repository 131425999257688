
import ProductsPriceNotice from "../../../components/ProductsPriceNotice";
import BuyProductArea from "../../../components/BuyProductArea";
export default {
  name: 'CloningVectors',
  components: {BuyProductArea, ProductsPriceNotice},
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'pAL2-T и pKAN-T',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Векторы для быстрого клонирования продуктов ПЦР'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Вектор для клонирование, клонирование ампициллин, клонирование канамицин, плазмида клонирование'
        }
      ]
    }
  }
}
