import { render, staticRenderFns } from "./ProductListModal.vue?vue&type=template&id=040dc4c9&scoped=true"
import script from "./ProductListModal.vue?vue&type=script&lang=js"
export * from "./ProductListModal.vue?vue&type=script&lang=js"
import style0 from "./ProductListModal.vue?vue&type=style&index=0&id=040dc4c9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040dc4c9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductToBasket: require('/var/www/localhost/evrogen.ru/components/ProductToBasket.vue').default,ProductsPriceNotice: require('/var/www/localhost/evrogen.ru/components/ProductsPriceNotice.vue').default,ProductsPatentsNotice: require('/var/www/localhost/evrogen.ru/components/ProductsPatentsNotice.vue').default,ProductsSpecialPriceNotice: require('/var/www/localhost/evrogen.ru/components/ProductsSpecialPriceNotice.vue').default})
