
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'qPcrMixHsRox',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '5X qPCRmix-HS c красителем ROX',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ПЦР-смеси для высокоспецифичной ПЦР в реальном времени в присутствии референсного красителя ROX'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: '5X qPCRmix-HS HighROX, 5X qPCRmix-HS LowROX, PK153S, PK153L, PK154S, PK154L'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
