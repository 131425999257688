
export default {
  name: 'Literature',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Промоматериалы',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Промоматериалы Компании Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'евроген промо, евроген презентация, евроген листовки, евроген каталог'
        }
      ]
    }
  }
}
