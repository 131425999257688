
import ServicesNote from '@/components/page_blocks/ServicesNote'
import ConfidentialityV1 from '@/components/page_blocks/ConfidentialityV1'
export default {
  name: 'Mutagenesis',
  components: {ConfidentialityV1, ServicesNote},
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Сайт-направленный мутагенез',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Сервис включает внесение любых мутаций в последовательность ДНК. Соответствие целевого продукта требованиям заказчика определяется секвенированием.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'мутагенез, сайт-направленный'
        }
      ]
    }
  }
}
