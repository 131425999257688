
import Vuelidate from 'vuelidate'
import {required, maxValue, decimal, numeric} from 'vuelidate/lib/validators'
import {Line, mixins} from 'vue-chartjs'

const {reactiveProp} = mixins

let LineChart = {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartOptions: null
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions)
  },
  watch: {
    chartData: {
      handler: function () {
        this.renderChart(this.chartData, this.chartOptions)
      }
    }
  }
};

export default {
  components: {
    LineChart,
  },
  computed: {
    targetConcentration() {
      return this.calculateConcentration(this.targetLength)
    },
    lineChartData() {
      let data = {
        labels: [...Array(499).keys()].map(i => i),
        datasets: [{
          label: '',
          borderColor: [...Array(499).keys()].map(i => {
            return 'grey'
          }),
          pointBackgroundColor: [...Array(499).keys()].map(i => {
            return 'grey'
          }),
          pointRadius: [...Array(499).keys()].map(i => {
            return 0
          }),
          hoverRadius: [...Array(499).keys()].map(i => {
            return 0
          }),
          pointStyle: [...Array(499).keys()].map(i => {
            return 'line'
          }),
          fill: false,
          data: this.prepareLineChartDataset()
        }]
      }

      data.datasets[0]['borderColor'][this.fragmentShort.flength * 1] = '#ff7f0e'
      data.datasets[0]['pointBackgroundColor'][this.fragmentShort.flength * 1] = '#ff7f0e'
      data.datasets[0]['pointRadius'][this.fragmentShort.flength * 1] = 5
      data.datasets[0]['hoverRadius'][this.fragmentShort.flength * 1] = 7
      data.datasets[0]['pointStyle'][this.fragmentShort.flength * 1] = 'circle'

      data.datasets[0]['borderColor'][this.fragmentLong.flength * 1] = '#ff7f0e'
      data.datasets[0]['pointBackgroundColor'][this.fragmentLong.flength * 1] = '#ff7f0e'
      data.datasets[0]['pointRadius'][this.fragmentLong.flength * 1] = 5
      data.datasets[0]['hoverRadius'][this.fragmentLong.flength * 1] = 7
      data.datasets[0]['pointStyle'][this.fragmentLong.flength * 1] = 'circle'

      data.datasets[0]['borderColor'][this.targetLength * 1] = '#1f77b4'
      data.datasets[0]['pointBackgroundColor'][this.targetLength * 1] = '#1f77b4'
      data.datasets[0]['pointRadius'][this.targetLength * 1] = 5
      data.datasets[0]['hoverRadius'][this.targetLength * 1] = 7
      data.datasets[0]['pointStyle'][this.targetLength * 1] = 'circle'

      return data

    },
    lineChartOptions() {
      return {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: function (item, chart) {
              return chart.datasets[item.datasetIndex].label + ': Концентрация ' + item.yLabel.toString() + ' нг/мкл'
            }
          },
          filter: (item) => {
            if ([this.fragmentShort.flength, this.fragmentLong.flength, this.targetLength].indexOf(item.xLabel) !== -1) {
              return item
            }
          }
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Концентрация, нг/мкл',
                fontSize: '14'
              }
            }
          ],
          xAxes: [{
            ticks: {
              autoSkip: true,
              autoSkipPadding: 21,
              maxRotation: 90,
              minRotation: 90
            },
            scaleLabel: {
              display: true,
              labelString: 'Длина фрагмента, п.о.',
              fontSize: '14',
              padding: 15
            }
          }
          ]
        }
      }
    },
    shortToLongRatio() {
      if (this.$v.fragmentShort.$invalid || this.$v.fragmentShort.$invalid || !this.concentrationsRatioIsValid) {
        return 0
      }

      return (this.fragmentShort.concentration * 1 / this.fragmentLong.concentration * 1).toFixed(2)
    },
    concentrationsRatioIsValid() {
      if (this.$v.fragmentShort.$invalid || this.$v.fragmentShort.$invalid) {
        return false
      }

      return this.fragmentShort.concentration * 1 >= this.fragmentLong.concentration * 1
    },
    warningOneIsVisible() {
      return ((this.fragmentShort.flength * 1 === 91 && this.fragmentLong.flength * 1 === 156)
        || (this.fragmentShort.flength * 1 === 156 && this.fragmentLong.flength * 1 === 211))
        && this.concentrationsRatioIsValid
        && (this.shortToLongRatio < 1.5 || this.shortToLongRatio > 3)
        && !this.$v.fragmentShort.$invalid
        && !this.$v.fragmentLong.$invalid
    },
    warningTwoIsVisible() {
      return this.fragmentShort.flength * 1 === 91
        && this.fragmentLong.flength * 1 === 211
        && this.concentrationsRatioIsValid
        && (this.shortToLongRatio < 1.5 || this.shortToLongRatio > 9)
        && !this.$v.fragmentShort.$invalid
        && !this.$v.fragmentLong.$invalid
    }
  },
  validations: {
    fragmentShort: {
      concentration: {
        decimal,
        minValue: (val) => {
          return val > 0
        }
      },
      flength: {
        required
      }
    },
    fragmentLong: {
      concentration: {
        decimal,
        minValue: (val) => {
          return val > 0
        }
      },
      flength: {
        required
      }
    },
    targetLength: {
      numeric,
      required,
      minValue: (val) => {
        return val >= 50
      },
      maxValue: maxValue(500)
    }
  },
  data() {
    return {
      fragmentShort: {
        flength: 91,
        concentration: 10
      },
      fragmentLong: {
        flength: 211,
        concentration: 5
      },
      targetLength: 300,
      isReady: false
    }
  },
  mounted() {
    this.isReady = true
  },
  methods: {
    calculateConcentration(targetLength) {
      if (this.$v.fragmentShort.$invalid || this.$v.fragmentLong.$invalid || this.$v.targetLength.$invalid || !this.concentrationsRatioIsValid) {
        return 0;
      }

      let power = ((targetLength * 1 - this.fragmentShort.flength) / (this.fragmentShort.flength - this.fragmentLong.flength));
      return (this.fragmentShort.concentration * 1 * (this.fragmentShort.concentration * 1 / this.fragmentLong.concentration * 1) ** power).toFixed(2);

    },
    minLengthChanged() {
      if (this.fragmentShort.flength * 1 === 156) {
        this.fragmentLong.flength = 211
      }
    },
    maxLengthChanged() {
      if (this.fragmentLong.flength * 1 === 156) {
        this.fragmentShort.flength = 91
      }
    },
    prepareLineChartDataset() {
      return [...Array(499).keys()].map(i => {
        return this.calculateConcentration(i)
      })
    }
  }
}
