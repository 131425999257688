
export default {
  name: 'payment-redirect',
  head() {
    return {
      title: 'Подготовка платежа'
    }
  },
  props: {
    uid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      message: null
    }
  },
  async created() {
    try {
      let response = await this.$myHelper.axiosWithMyEx('/basket/createPayLink', JSON.stringify(this.uid))
      if (response.url) {
        window.location = response.url;
      }
      this.message = response.error;

    } catch (ex) {
      this.message = ex?.message || ex;
    }
  },
}
