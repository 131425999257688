
import PagerSearchCmp from '@/components/PagerSearchCmp'
import BtnWithLoading from '@/components/BtnWithLoading'

export default {
  name: 'Search',
  scrollToTop: true,
  layout: 'main_page/Index',
  components: {
    PagerSearchCmp,
    BtnWithLoading
  },
  head() {
    return {
      title: 'Поиск по сайту Евроген',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  watch: {
    async $route() {
      await this.onRoute()
    }
  },
  async mounted() {
    await this.onRoute()
  },
  data () {
    return {
      searchText: '',
      lastSearch: '',
      findType: 'all',
      searchIsEmpty: false,
      pagesCount: 0,
      allRowsCount: 0,
      currentPage: 0,
      pageSize: 10,
      rows: null
    }
  },
  methods: {
    async onRoute() {
      if (this.$route.query && this.$route.query.hasOwnProperty('s')) {
        this.searchText = this.$route.query.s || ''
        if (!this.prepareEmptyRequest()) {
          await this.doFind()
        }
      }
    },

    async doFind() {
      let data = {
        search: this.searchText.trim(),
        type: this.findType === 'all' ? undefined : this.findType,
        pageNumber: this.pageSize ? this.currentPage : null,
        pageSize: this.pageSize ? this.pageSize : null,
        userId: this.$store.state.user.user.id ? this.$store.state.user.user.id * 1 : null
      }
      this.$store.state.isLoadingNow = true

      let response = true

      try {
        response = await this.$myHelper.axiosWithMyEx('/api/search', data)
      } finally {
        this.$store.state.isLoadingNow = false
      }

      this.rows = response.rows
      this.allRowsCount = response.count
      this.pagesCount = Math.ceil(this.allRowsCount / this.pageSize)
      this.$router.push({path: '/search', query: {s: this.searchText.trim()}})
      this.lastSearch = this.searchText.trim()

    },

    async onFindClick() {
      this.lastSearch = ''
      if (this.prepareEmptyRequest()) {
        return
      }
      this.currentPage = 0
      await this.doFind()
    },

    async onSetPage(pageNumber) {
      if (this.prepareEmptyRequest()) {
        return
      }
      this.currentPage = pageNumber
      await this.doFind()
    },

    prepareEmptyRequest() {
      if (!this.searchText.trim() || this.searchText.trim().length < 3) {
        this.rows = []
        this.currentPage = 0
        this.pagesCount = 0
        this.searchIsEmpty = true
        return true
      }
      this.searchIsEmpty = false
      return false
    }
  }
}
