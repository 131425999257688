
import BtnWithLoading from '../BtnWithLoading'
export default {
  name: 'ClearSynthesisFormModal',
  components: {
    BtnWithLoading
  },
  props: {
    title: ''
  },
  methods: {
    confirmDeletion () {
      this.$nuxt.$emit('clear-primers-form')
      this.$emit('close')
    }
  }
}
