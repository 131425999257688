
export default {
  name: 'BtnWithLoading',
  props: {
    color: {
      type: String
    },
    addStyle: {
      type: String
    },
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    watchLoading: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    isDisabled() {
      return this.watchLoading && (this.$store.state.isLoadingNow || this.disabled)
    }
  }
}
