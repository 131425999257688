

import ConfidentialityV1 from '@/components/page_blocks/ConfidentialityV1'
export default {
  name: 'OrganicSynthesis',
  components: {
    ConfidentialityV1
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Синтез органических соединений',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Заказной синтез низкомолекулярных органических соединений различной сложности.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Заказной синтез, Синтез гетероциклические соединения, Синтез гетероциклов, Синтез сахаров, Синтез Стероидо, Синтез терпеноидов, Синтез пептидлв, Синтез Красителей, Синтез Фосфорамидитов, Заказ ЯМР, заказ ВЭЖХ, заказ HRMS'
        }
      ]
    }
  }
}
