
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'

export default {
  name: 'DNAseE',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ДНКаза Е',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ДНКаза Е — термостойкая эндонуклеаза, разрушающая ДНК до одноцепочечных олигонуклеотидов'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ДНКаза, ДНКаза I, ДНКаза 1, ДНКаза Е, ДНКаза E, термостойкая эндонуклеаза, термостойкая ДНКаза, термостабильная эндонуклеаза, термостабильная ДНКаза'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/EK007M_DNAseE.jpg'},
        {url: '/img/EK007S_DNAseE.jpg'},
      ],
      imageIndex: null
    }
  }
}
