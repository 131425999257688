
import ProductsPriceNotice from "../../../components/ProductsPriceNotice";
import BuyProductArea from "../../../components/BuyProductArea";
export default {
  name: 'Cloning',
  components: {BuyProductArea, ProductsPriceNotice},
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Клонирование ДНК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Реактивы и наборы для клонирования ДНК'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'клонирование днк, клонирование набор, вектор клонирование, лигаза'
        }
      ]
    }
  }
}
