
export default {
  name: 'DnaEvaluation',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Оценка ДНК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Ревертазы и наборы для синтеза кДНК, реактивы нормализации кДНК и наборы для амплификации концефых фрагментов (RACE)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'оценка днк, синтез кднк, ревертаза, mmlv, mint, mulv, onestep, onetube'
        }
      ]
    }
  }
}
