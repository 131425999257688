
import BtnWithLoading from '@/components/BtnWithLoading'
import AddClient from '@/components/AddClient'
import PrimersTable from '@/components/page_blocks/PrimersTable'
import AddressForm from '@/components/AddressForm'
import DeleteProductFromBasketModal from '@/components/modals/DeleteProductFromBasketModal'
import ViewPolicyModal from '@/components/modals/ViewPolicyModal'
import ProductListModal from '@/components/modals/ProductListModal'
import DeletePrimersModal from '@/components/modals/DeletePrimersModal'
import DeleteSequenceModal from '@/components/modals/DeleteSequenceModal';
import ClearBasketModal from '@/components/modals/ClearBasketModal'
import SelectBufferModal from '@/components/modals/SelectBufferModal'
import _ from 'lodash'

import Primer from '@/assets/primer.js'
import * as Address from '@/assets/address.js'
import AuthModal from '@/components/modals/AuthModal'
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: 'Basket',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    BtnWithLoading,
    AddClient,
    PrimersTable,
    AddressForm,
    DeleteProductFromBasketModal,
    ProductListModal,
    ViewPolicyModal,
    DeletePrimersModal,
    ClearBasketModal,
    SelectBufferModal,
    AuthModal,
  },
  head() {
    return {
      title: 'Корзина',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters('basket', {
      counters: 'counters',
      calcSeqPrimer: 'calcSeqPrimer',
      sequenceSum: 'sequenceSum',
      sequenceSummary:'sequenceSummary'
    }),
    canUseOrderForSeqPrimers() {
        return !this.basket.products?.length  &&
        !this.basket.sequences?.elements?.length &&
        this.basket.primers?.items?.length &&
        this.basket.primers?.items.every(p => !p.mods || p.mods.length === 0)
    },





    sequencePrimersWithPrices() {
      return this.$store.getters['basket/primersForSequence']
      .map(primer => Object.assign(primer, this.calcSeqPrimer(primer, this.primerDiscount) ));
    },



    sberPayIsOn() {
      return this.$store.state.dictionaries.settings.sberPayIsOn
    },

    isBeta() {
      return false
      // return appSettings.isBeta
    },
    showDeliveryBlock() {
      return !!this.orderFields.clientId
        && (
          this.primers.showPrimers('basket').length > 0 ||
          this.showProducts.length > 0 ||
          this.$store.state.basket?.basket?.sequences?.primers?.some(pr => pr.actionAfterEnd === 'return') ||
          this.$store.state.basket?.basket?.sequences?.elements?.some(pr => pr.actionAfterEnd === 'return')
        );
    },
    showPickupBlock() {
      return this.orderFields.clientId && this.$store.state.basket?.basket?.sequences?.isNeedPickUp;
    },

    clientIdForPerson() {
      const fnd = _.find(this.user.clients, client => client.is_person * 1 === 1)
      return fnd ? fnd.id : -1
    },
    basket() {
      return this.$store.state.basket.basket
    },
    primers() {
      return this.$store.state.basket.basket.primers
    },
    sequences() {
      return this.$store.state.basket.basket.sequences;
    },

    isSomePrimersSimple() {
      return this.primers.items.some(p => p.mods.length === 0)
    },

    bulks() {
      return this.$store.state.dictionaries.bulks
    },

    user() {
      return this.$store.state.user.user
    },

    organizationNotEmpty: function() {
      if (this.orderFields.clientId != null && this.orderFields.clientId !== 0) {
        return true
      }

      return ((this.orderFields.clientId === 0) || (this.notEmptyClients.length === 0))
        && (this.orderFields.affiliateName && this.orderFields.clientName)
    },

    notEmptyClients() {
      return (this.user && this.user.clients)
        ? this.user.clients.filter(item => item.affiliate && item.affiliate.name && item.is_person * 1 !== 1)
        : []
    },

    showPrimers() {
      return this.primers
        ? this.primers.showPrimers('basket')
        : []
    },

    checkedPrimers() {
      return this.primers.items.filter(primer => primer.selected && (!primer.forDelete))
    },

    basketIsEmpty() {
      return !((this.primers && this.primers.showPrimers('basket').length > 0) ||
        (this.showProducts.length > 0) || this.sequenceSum(this.sequenceDiscount).sum  )
    },

    selectedProducts() {
      return this.basket.products.filter(item => item.selected && !item.forDelete)
    },

    showProducts() {
      return this.basket.products
        ? this.basket.products.filter(item => !item.forDelete)
        : []
    },

    primersSum() {
      return this.showPrimers.reduce((acc, item) => acc + item.discount_price * 1, 0)
    },

    productsSum() {
      return _.sumBy(this.basket.products,
                     product => this.checkProduct(product)
                       ? (this.calcDiscount(product.discount_price) * product.discount_count
                         + this.calcDiscount(product.price) * (product.count * 1 - product.discount_count * 1))
                       : 0)
    },

    orderSum() {
      return this.counters.sum;
    },

    onlySequenceWoDelivery() {
      return this.showPrimers.length === 0 &&
        this.showProducts.length === 0 &&
        !this.sequences?.elements.some( el => el.actionAfterEnd === 'return')
    },

    disableSaveOrder() {
      return this.basket.loading
        || !this.orderFields.clientId
        || (this.orderFields.clientId * 1 === this.clientIdForPerson * 1 && !this.confirmWithPolicy)
        || ((!this.onlySequenceWoDelivery) &&
          ((this.orderFields.withDelivery === null) || (this.orderFields.withDelivery && this.orderFields.address.trim().length === 0)))
        || this.basket.products.some(product => this.productCountIsError(product))
        || this.showPrimers.some(item => item.isErrors())
        || (this.showPickupBlock &&  !this.orderFields.pickUpAddressId)
    },

    withDiscount() {
      return this.showPrimers.length > 0 && this.showPrimers.some(item => item.price * 1 > 0 && item.price !== item.discount_price)
    },

    managerEmail() {
      return this.$store.state.dictionaries.settings.managerMail
    },

    pdDiscount() {
      let client = this.getDiscountClient()
      return client ? client.pdDiscount : 0
    },

    primerDiscount() {
      let client = this.getDiscountClient()
      return client ? client.simplePrimerDiscount : 0
    },
    sequenceDiscount() {
      let client = this.getDiscountClient();
      return client?.sequenceDiscount || 0;
    }
  },


  async created() {
    this.$nuxt.$on('delete-product-from-basket', (products) => {
      this.doDeleteProducts(products)
    })

    this.$nuxt.$on('delete-sequences', (products) => {
      this.deleteSeqOrder();
    });

    this.$nuxt.$on('delete-primers', (primers) => {
      this.doDeletePrimers(primers)
    })

    this.$nuxt.$on('clear-basket', () => {
      this.doClearBasket()
    })
  },
  watch: {
    user: {
      handler: function() {
        if (this.user.id && this.pageIsReady) {
          this.initUserDependentData()
        }
      }
    },
    deep: true
  },
  async mounted() {
    try {
      await this.$store.dispatch('user/loadUser')
    } catch (e) {

    }

    await this.$store.dispatch('dictionaries/loadProducts', [])
    await this.$store.dispatch('dictionaries/loadModifiersAndBulks')
    Primer.initStaticFields(this.$store.state.dictionaries)
    await this.$store.dispatch('basket/loadBasket')

    this.orderFields.address = 'oldAddress'
    this.basket.onUpdatePrimers = this.updatePrimers
    this.basket.onUpdateProducts = this.updateProducts
    this.basket.onOrderDone = this.orderEndMessage

    if (this.user && this.user.id) {
      await this.initUserDependentData()

      this.pageIsReady = true

    } else {
      this.pageIsReady = true
    }

  },
  beforeDestroy() {
    this.$nuxt.$off('delete-sequences')
    this.$nuxt.$off('delete-product-from-basket')
    this.$nuxt.$off('delete-primers')
    this.$nuxt.$off('clear-basket')
  },
  data() {
    return {
      pageIsReady: false,
      allProductsSelected: false,
      allPrimersSelected: false,
      confirmWithPolicy: false,
      primersForEdit: null,
      hideEditFormButtons: false,

      orderFields: {
        comment: '',
        withDelivery: null,
        address: '',
        addressId: null,
        pickUpAddressId: null,
        addressSrc: '',
        clientId: null,
        clientName: '',
        affiliateName: '',
        isPaySber: false,
        forSequence: false,
      },

      newOrderNumber: '',
      printOrderId: null,
      fileDescriptions: [],
      editFileValue: '',
      editFileId: '',
      fileIsLoading: false,
      showNewOrganization: false,
      deliveryType: null,
      newClient: null,
      emptyClient: {
        affiliate: { id: '', name: '' },
        id: 0,
        name: '',
        is_person: 0,
        pdDiscount: 0,
        simplePrimerDiscount: 0,
        source: 'site'
      }
    }
  },
  methods: {
    async printPdf() {
      let options = {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer'
      };
      this.$store.state.isLoadingNow = true;
      try {
        let data = await this.$axios.post('/site/v2/sequences/SequenceOrderPdf', JSON.stringify(this.printOrderId * 1), options);

        let blobData = new Blob([data.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blobData);
        link.setAttribute('download', `order_${this.newOrderNumber}-SEQ.pdf`);
        document.body.appendChild(link);
        link.click();
      } finally {
        this.$store.state.isLoadingNow = false;
      }
    },

    async deleteSeqOrder() {
      this.$store.state.basket.basket.sequences = {elements: [], primers: []};
      await this.$myHelper.axiosWithMyEx('/v2/basket/deleteBasket', this.$store.state.basket.basket.id);
    },


    async initUserDependentData() {
      this.fileDescriptions = await this.$myHelper.axiosWithMyEx('/basket/getFiles', JSON.stringify(this.basket.id))
      this.user.addresses.forEach(address => Object.setPrototypeOf(address, Address.Address.prototype))

      if (localStorage[ 'orderFields' ]) {
        let fields = JSON.parse(localStorage[ 'orderFields' ])
        this.orderFields[ 'clientId' ] = fields[ 'clientId' ]
        this.$store.commit('basket/setPdDiscount', this.pdDiscount)
        this.$store.commit('basket/setPrimerDiscount', this.primerDiscount)
        this.$store.commit('basket/setSeqDiscount', this.sequenceDiscount)
        await this.$store.dispatch('basket/loadPrimersPrice', {
          primers: this.showPrimers,
          discountPercent: this.primerDiscount
        })

        await this.$store.dispatch('basket/savePrimers', { primers: this.showPrimers, discountPercent: this.primerDiscount })

        this.$nextTick(() => {
          this.deliveryType = fields[ 'withDelivery' ]

          let field_names = ['clientName', 'affiliateName', 'withDelivery', 'address', 'addressId', 'addressSrc']

          field_names.forEach(fName => {
            this.orderFields[ fName ] = fields[ fName ]
          })
        })
      }
    },
    showAuthModal(action) {
      this.$modal.show(
        AuthModal,
        {
          action: action,
          mode: 'modal'
        },
        {
          adaptive: true,
          resizable: false,
          draggable: false,
          clickToClose: true,
          width: '522px',
          minWidth: 522
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    getDiscountClient() {
      if (!this.user.clients) {
        return null
      }
      return this.user.clients.length === 1
        ? this.user.clients[ 0 ]
        : this.user.clients.find(cl => cl.id * 1 === this.orderFields.clientId * 1)
    },

    onForSequenceChange() {
      this.deliveryType = null;
      this.orderFields.forSequence = true;
      this.orderFields.address = ''
      this.orderFields.addressSrc = ''
      this.orderFields.addressId = -1
      this.orderFields.withDelivery = false
    },

    onDeliveryTypeChange(withDelivery) {
      this.orderFields.forSequence = false;
      let activeAddresses = this.user.addresses.filter(item => (!item.forDelete) && (item.client_fk === this.orderFields.clientId))
      if (activeAddresses.length === 1 && withDelivery) {
        this.orderFields.address = activeAddresses[ 0 ].toString()
        this.orderFields.addressSrc = JSON.stringify(activeAddresses[ 0 ])
        this.orderFields.addressId = activeAddresses[ 0 ].id
        this.orderFields.withDelivery = withDelivery
      } else {
        this.orderFields.address = ''
        this.orderFields.addressSrc = ''
        this.orderFields.addressId = withDelivery ? null : -1
        this.orderFields.withDelivery = withDelivery
      }
    },

    async onClickClient(clientId) {
      if (clientId * 1 !== this.orderFields.clientId * 1) {
        this.orderFields.clientId = clientId;
        this.orderFields.address = '';
        this.orderFields.addressId = null;
        this.orderFields.withDelivery = null;
        this.orderFields.addressSrc = '';
        this.orderFields.isPaySber = false;
        this.orderFields.forSequence = false;
        this.deliveryType = null;
        this.orderFields.pickUpAddressId = null;

      }
      this.$nextTick(async() => {
        this.$store.commit('basket/setPdDiscount', this.pdDiscount)
        this.$store.commit('basket/setSeqDiscount', this.sequenceDiscount)
        this.$store.commit('basket/setPrimerDiscount', this.primerDiscount)
        await this.$store.dispatch('basket/loadPrimersPrice', { primers: this.showPrimers, discountPercent: this.primerDiscount })
        this.$store.dispatch('basket/savePrimers', { primers: this.showPrimers, discountPercent: this.primerDiscount })
      })
    },

    formatPrice(src) {
      return this.$myHelper.priceFormat(src)
    },

    calcDiscount(sum) {
      return this.$myHelper.calcDiscountPrice(sum, this.pdDiscount)
    },

    calcPdRowSum(product) {
      if (this.productCountIsError(product)) {
        return ''
      }
      return this.calcDiscount(product.discount_price) * product.discount_count
        + this.calcDiscount(product.price) * (product.count - product.discount_count)
    },

    updateProducts(products) {
      this.basket.products = products
    },

    updatePrimers(primers) {
      this.basket.primers = primers
    },

    orderEndMessage() {
      this.showDialog('Вы оформили заказ в другом окне или вкладке браузера. Содержимое корзины обновлено')
    },

    onProductCountInput: _.debounce(async function(product) {
      if (this.checkProduct(product)) {
        try {
          await this.$store.dispatch('basket/saveProducts')
        } catch (ex) {
          this.showDialog('Ошибка сохранения корзины', ex)
        }
      }
    }, 300),

    async changeProductCount(product, count) {
      product.count = product.count * 1 + count
      this.basket.recalcProductsDiscount()
      if (this.checkProduct(product)) {
        try {
          await this.$store.dispatch('basket/saveProducts')
        } catch (ex) {
          this.showDialog('Ошибка сохранения корзины', ex)
        }
      }
    },

    showDeleteSequencesModal(primers) {
      this.$modal.show(
        DeleteSequenceModal,
        {
          title: 'Подтвердите удаление',
          primers: primers
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true,
          width: '522px',
          minWidth: 522
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },



    showDeletePrimersModal(primers) {
      this.$modal.show(
        DeletePrimersModal,
        {
          title: 'Подтвердите удаление',
          primers: primers
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true,
          width: '522px',
          minWidth: 522
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    async doDeletePrimers(primers) {
      this.primers.deletePrimers(primers)

      try {
        await this.$store.dispatch('basket/savePrimers', { primers })
      } catch (ex) {
        this.showDialog('Ошибка сохранения корзины', ex)
      }
    },

    async orderDone() {
      // Для случая когда заказ на физ лицо, а клиент не был создан так адрес не используется
      if (this.orderFields.clientId * 1 === -1) {
        this.orderFields.clientId = await this.$store.dispatch('user/addPersonClient')
      }

      if (this.orderFields.withDelivery) {
        let addr = _.find(this.user.addresses, a => a.id * 1 === this.orderFields.addressId * 1)
        if (addr) {
          this.orderFields.address = addr.toString()
        } else {
          this.orderFields.addressId = null
          this.orderFields.address = ''
          this.showDialog('Ошибка при обработке адреса')
          return
        }
      } else {
        this.orderFields.address = ''
      }

      this.orderFields.address = this.orderFields.withDelivery ? this.orderFields.address : ''

      try {
        const result = await this.$store.dispatch('basket/createOrder', this.orderFields)
        if (typeof result === "string") {
          this.newOrderNumber = result;
        } else {
          this.newOrderNumber = result.number;
          this.printOrderId = result.printOrderId;
        }
      } catch (ex) {
        this.showDialog('Ошибка создания заказа', ex)
        return
      }

      localStorage.setItem('orderFields', JSON.stringify(this.orderFields))
      this.basket.setCookieId('')
      await this.$store.dispatch('basket/cleanBasket')
      this.basket.initBasket()
    },

    checkProduct(product) {
      return /^\+?(0|[1-9]\d*)$/.test(product.count) && Number(product.count) > 0
    },

    productCountErrorString(product) {
      if (product.minimum_order_count * 1 > 1) {
        const cls = this.$myHelper.isPositiveInt(product.count) && product.count * 1 >= product.minimum_order_count * 1 ? 'info-label' : 'warning-label'
        return "<div class='" + cls + "'>Минимальное количество для заказа " + product.minimum_order_count + ' ' + product.measure_unit + '</div>'
      }
      return this.$myHelper.isPositiveInt(product.count)
        ? ''
        : "<div class='warning-label'>Значение должно быть целым, больше 0</div>"
    },

    productCountIsError(product) {
      return (!this.$myHelper.isPositiveInt(product.count)) ||
        product.count * 1 < product.minimum_order_count * 1
    },

    showPolicyModal() {
      this.$modal.show(
        ViewPolicyModal,
        {
          title: 'Пользовательское соглашение'
        },
        {
          width: 1100,
          resizable: false,
          draggable: false,
          clickToClose: true
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    showProductListModal() {
      this.$modal.show(
        ProductListModal,
        {
          title: 'Список продуктов'
        },
        {
          width: 1100,
          resizable: false,
          draggable: false,
          clickToClose: true
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    showDeleteProductModal(products) {
      this.$modal.show(
        DeleteProductFromBasketModal,
        {
          title: 'Подтвердите удаление',
          products: products
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true,
          width: '522px',
          minWidth: 522
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    async doDeleteProducts(products) {
      products.forEach(item => item.forDelete = true);
      this.basket.recalcProductsDiscount();
      try {
        await this.$store.dispatch('basket/saveProducts')
      } catch (ex) {
        this.showDialog('Ошибка сохранения корзины', ex)
      }
    },

    onChangeCheckGroupProduct() {
      this.showProducts.forEach(item => item.selected = this.allProductsSelected)
    },

    onChangeCheckGroupPrimer() {
      this.showPrimers.forEach(item => item.selected = this.allPrimersSelected)
    },

    checkNumber(event) {
      const acceptedKeys = _.range(10)
      acceptedKeys.push('Backspace', 'Delete', 'ArrowRight', 'ArrowLeft')

      if (!acceptedKeys.some(item => event.key === item.toString())) {
        event.preventDefault()
      }
    },

    showSelectBufferModal(product) {
      this.$modal.show(
        SelectBufferModal,
        {
          title: 'Выберите буфер',
          product: product,
          buffers: this.bulks.filter(item => item.product_id * 1 === product.product_fk * 1)
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    addFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files
      if (!fileList.length) {
        return
      }
      const filesForSend = []

      for ( let i = 0; i < fileList.length; i++ ) {
        const fileIsExist = this.fileDescriptions.some(item => item.toLowerCase() === fileList[ i ].name.toLowerCase())
        if (!fileIsExist) {
          filesForSend.push(fileList[ i ])
        }
      }

      this.$refs.fileInput.value = ''
      if (filesForSend.length > 0) {
        this.sendFilesToServer(filesForSend, this.basket.id)
      }
    },

    async sendFilesToServer(files, basketId) {
      const formData = new FormData()
      formData.append('basketId', basketId)

      files.forEach(function(file) {
        formData.append('files', file)
      })

      this.fileIsLoading = true
      // todo: разобраться почему при отправке formdata я получаю в ответ хер знает что, а не обычную структуру response-а
      await this.$axios.post('/site/basket/addFiles', formData, { headers: { 'Content-Type': 'multipart/form-data' } })

      this.fileDescriptions = await this.$myHelper.axiosWithMyEx('/basket/getFiles', JSON.stringify(this.basket.id))
      this.fileIsLoading = false
    },

    async doFileDelete(file) {
      const formData = new FormData()
      formData.append('basketId', this.basket.id)
      formData.append('fileId', file.id)
      this.fileIsLoading = true
      await this.$myHelper.axiosWithMyEx('/basket/deleteFile', {
        basketId: this.basket.id,
        fileId: file.id,
        file
      })
      this.fileDescriptions = await this.$myHelper.axiosWithMyEx('/basket/getFiles', JSON.stringify(this.basket.id))
      this.fileIsLoading = false
    },

    onChangeAddress(address) {
      this.orderFields.clientId = address.client_fk
      this.$nextTick(() => {
        this.orderFields.withDelivery = address.toString().trim().length > 0
        this.orderFields.address = address.toString()
        this.orderFields.addressSrc = JSON.stringify(this.orderFields.withDelivery ? address : '')
        this.orderFields.addressId = address.id
      })
    },
    onChangePickupAddress(address) {
      this.orderFields.pickUpAddressId = address.id * 1;
    },

    async onAddClient(client) {
      this.orderFields.clientId = client.id;
      this.onHideAddClient();
    },

    onHideAddClient() {
      this.showNewOrganization = false
      this.orderFields.clientName = ''
      this.orderFields.affiliateName = ''
    },

    async savePrimersToBase(primers) {
      if (!Array.isArray(primers)) {
        primers = [primers]
      }

      try {
        await this.$store.dispatch('basket/loadPrimersPrice', { primers, discountPercent: this.primerDiscount });
        let maxNumber = Math.max(...this.$store.state.basket.basket.primers.items.map(p => p.numberInOrder));
        await this.$store.dispatch('basket/savePrimers', { primers, maxNumber });
      } catch (ex) {
        this.showDialog('Ошибка сохранения корзины', ex)
        return false
      }

      return true
    },

    showAddClientDialog() {
      this.newClient = Object.assign({}, this.emptyClient)
      this.showNewOrganization = true
    },

    hideAddClientDialog() {
      this.newClient = Object.assign({}, this.emptyClient)
      this.showNewOrganization = false
    },

    showClearBasketModal() {
      this.$modal.show(
        ClearBasketModal,
        {
          title: 'Подтвердите удаление'
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true,
          width: '522px',
          minWidth: 522
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    async doClearBasket() {
      const delPrimers = this.primers.showPrimers()
      this.primers.deletePrimers(delPrimers)
      this.basket.products.forEach(item => item.forDelete = true)

      try {
        await this.$store.dispatch('basket/saveCleanBasket')
      } catch (ex) {
        this.showDialog('Ошибка сохранения корзины', ex)
      }
    }

  }
}
