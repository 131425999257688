
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'qPcrMixHsUdg',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '5X qPCRmix-HS (UDG)',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ПЦР-смесь с UDG для защиты от контаминации'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'PK245S, PK245L, PK245B, смесь пцр удг, микс пцр удг, mix удг, смесь пцр udg, микс пцр udg, mix udg'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
