
import Primer from '@/assets/primer.js'
import BtnWithLoading from './BtnWithLoading'
import $ from 'jquery'

export default {
  name: 'PrimerView',
  props: ['primer', 'index'],
  components: {
    BtnWithLoading
  },
  computed: {
    maxPrimerLenght() {
      return Primer.getMaxLength(this.primer.scale);
    },

    minPrimerLength() {
      return Primer.minLength
    },

    canAllScaleClean() {
      return Primer.canClean(1) && Primer.canClean(0.2) && Primer.canClean(0.04) && Primer.canClean(0.02);
    },

    canAllScaleKeen() {
      return Primer.canKeen(1) && Primer.canKeen(0.2) && Primer.canKeen(0.04) && Primer.canKeen(0.02);
    },
  },
  data () {
    return{
      clean: false,
      keen: false
    }
  },
  methods: {
    canClean(scale) {
      return Primer.canClean(scale);
    },

    canKeen(primer) {
      return Primer.canKeen(primer.scale, primer);
    },



    emitSetActive() {
      this.$emit('set-active-primer', this.primer)
    },
    // допроверить и разобраться с ивентом
    onChangeOptions (event) {
      var el = $(event.srcElement ? event.srcElement : event.target)
      this.primer.setOptions(el.val(), el.prop('checked'))

      this.$emit('change-primer', this.primer)
    },
    formatPrice(src) {
      return this.$myHelper.priceFormat(src)
    }
  },


}
