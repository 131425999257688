
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'MintRacePrimers',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Mint RACE primer set',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор праймеров для быстрой амплификации концевых фрагментов'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
