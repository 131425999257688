
export default {
  name: 'MOS',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Mirror Orientation Selection (MOS)',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Описание метода "Зеркально-ориентированная селекция" (англ.)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Mirror Orientation Selection, MOS, Зеркально-ориентированная селекция'
        }
      ]
    }
  }
}
