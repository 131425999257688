import { render, staticRenderFns } from "./PrimersTable.vue?vue&type=template&id=b882fa7e"
import script from "./PrimersTable.vue?vue&type=script&lang=js"
export * from "./PrimersTable.vue?vue&type=script&lang=js"
import style0 from "./PrimersTable.vue?vue&type=style&index=0&id=b882fa7e&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModifiersButtons: require('/var/www/localhost/evrogen.ru/components/ModifiersButtons.vue').default,PrimerEdit: require('/var/www/localhost/evrogen.ru/components/PrimerEdit.vue').default,PrimerView: require('/var/www/localhost/evrogen.ru/components/PrimerView.vue').default,BtnWithLoading: require('/var/www/localhost/evrogen.ru/components/BtnWithLoading.vue').default})
