
import NewYearAlert from '@/components/NewYearAlert'
import ModifiersPrice from '@/components/page_blocks/ModifiersPrice'
import ServicesNote from '@/components/page_blocks/ServicesNote'
import ConfidentialityV1 from '@/components/page_blocks/ConfidentialityV1'
export default {
  name: '',
  components: {
    ConfidentialityV1,
    ServicesNote,
    ModifiersPrice,
    NewYearAlert
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Синтез олигонуклеотидов (праймеров)',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Синтез производится на синтезаторах Applied Biosystems ABI 3900 и dr.Oligo 192. Качество синтеза контролируется с помощью аналитического электрофореза в ПААГ и масс-спектрального анализа. Олигонуклеотиды обессолены и лиофилизированы.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'синтез олигонуклеотидов, синтез олигов, синтез праймеров, праймеры, олигосинтез, олиги, олигонуклеотиды, праймеры евроген, олиги евроген, олигонуклеотиды евроген, олигосинтез евроген, зонды евроген, синтез евроген, олигонуклеотидный синтез, заказать олиги, заказать олигонуклеотилы, заказать синтез праймерв, заказать синтез олинуклиотидов, олиги купить, праймеры купить, олигонуклеоитиды купить'
        }
      ]
    }
  }
}
