import { render, staticRenderFns } from "./DnaLadders.vue?vue&type=template&id=36c4b1ac&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./DnaLadders.vue?vue&type=script&lang=js"
export * from "./DnaLadders.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c4b1ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductsPriceNotice: require('/var/www/localhost/evrogen.ru/components/ProductsPriceNotice.vue').default,BuyProductArea: require('/var/www/localhost/evrogen.ru/components/BuyProductArea.vue').default})
