import { render, staticRenderFns } from "./SampleRequirements.vue?vue&type=template&id=ce523ae8&scoped=true"
import script from "./SampleRequirements.vue?vue&type=script&lang=js"
export * from "./SampleRequirements.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce523ae8",
  null
  
)

export default component.exports