
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from "../../../components/ProductsPriceNotice";

export default {
  name: 'ExtractDnaBloodAndCells',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ExtractDNA Blood & Cells',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор для выделения суммарной ДНК из цельной крови и клеток животного происхождения'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'набор ДНК кровь, набор ДНК клеток животного происхождения, набор днк культуры клеток, kit ДНК кровь, kit ДНК клеток животного происхождения, kit днк культуры клеток'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/BM011ExtractDNA.jpg'}
      ],
      imageIndex: null
    }
  }
}
