
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'KtnHsDetect',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'KTN-HS',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Полимераза для ПЦР и ПЦР-РВ по технологиям, не требующим экзонуклеазной активности'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'KTN, KTN-HS, PK025, PK025S, Klentaq, klen taq, полимераза кленова'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
