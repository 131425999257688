
export default {
  name: 'Nomotech',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Наборы для молекулярно-генетических исследований',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы для молекулярно-генетических исследований'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'НОМОТЕК, Тест система генотипирование, набор генотипирование, Тест система онкология, набор онкология, Тест система мутация, набор мутация, Тест система аллельный вариант, набор аллельный вариант, Тест система полиморфизм, набор полиморфизм'
        }
      ]
    }
  }
}
