
import $ from 'jquery'
import _ from 'lodash'
import Primer from '@/assets/primer.js'

export default {
  name: 'PrimerEditor',
  props: ['primer'],
  mounted () {
    this.$nuxt.$on('insert-modifier', this.onInsertModifier)
  },
  activated () {
    $(this.$el).html(this.primer.buildHtml('image', false))
    $(this.$el).focus()
    this.restoreCursor(this.primer.cursorPosition)
  },
  destroyed () {
    this.$nuxt.$off('insert-modifier', this.onInsertModifier)
  },
  computed: {
    modifiers () {
      return this.$store.state.dictionaries.modifiers
    }
  },

  watch: {
    'primer.cursorPosition': function (newValue) {
      this.restoreCursor(newValue)
    },
    'primer.primerIsActive': function () {
      this.repaint()
    },
    'primer.sequence': function () {
      this.primerChange()
    },
    'primer.mods.length': function () {
      this.primerChange()
    }
  },
  methods: {
    loadPrices_debounced: _.debounce(async (primers, store) => {
      await store.dispatch('basket/loadPrimersPrice', {primers})
    }, 500),
    primerChange () {
      if (Primer.canClean(this.primer.scale) && this.primer.isForceClean() && this.primer.options.indexOf('clean') < 0) {
        this.primer.options.push('clean')
      }
      this.loadPrices_debounced(this.primer, this.$store)
      this.repaint()
// Прокрутка длинного праймера в поле ввода что б курсор не уезжал
// todo: найти нормальное решение
      var cursorPositionInPixels = this.getCursorPosition().width
      if (cursorPositionInPixels >= $(this.$el).width()) {
        this.$el.scrollLeft = cursorPositionInPixels
      }
    },

    repaint () {
      $(this.$el).html(this.primer.buildHtml('image', false))
      this.restoreCursor(this.primer.cursorPosition)
      this.$el.focus()
    },

    moveCursorKeys (args) {
      switch (args.keyCode) {
        case 37: { // left
          if (!args.shiftKey) {
            this.primer.setCursor(this.getCursorPosition())
          }
          break
        }
        case 39: {   // right
          if (!args.shiftKey) {
            this.primer.setCursor(this.getCursorPosition())
          }
          break
        }
        case 35: {   // end
          if (!args.shiftKey) {
            this.primer.cursorPosition = this.primer.lengthWithMods()
          }
          break
        }
        case 36: {   // home
          if (!args.shiftKey) {
            this.primer.cursorPosition = 0
          }
          break
        }
      }
    },

    onKeypress (args) {
      this.moveCursorKeys(args)
    },

    onKeyup (args) {
      this.moveCursorKeys(args)
    },

    onKeydown (args) {
      if (([35, 36, 37, 38, 39, 40].indexOf(args.keyCode) >= 0) ||           // left, top, right, down, home, end
        (args.ctrlKey && args.keyCode === 86) ||                             // Ctrl-V
        (args.ctrlKey && args.keyCode === 67)) {                              // Ctrl-C
        return
      }
      if ([8, 46].indexOf(args.keyCode) >= 0) {  // BackSpace, Delete
        this.primer.deleteChar(this.getCursorPosition(), args.keyCode === 8)
        this.primer.replaceDegenerateCharsInPrimer()
      } else {
        let char = String.fromCharCode(args.keyCode).toUpperCase()
        char = (char === '0' && args.shiftKey) ? ')' : char
        char = (char === '9' && args.shiftKey) ? '(' : char
        char = (char === '8' && args.shiftKey) ? '*' : char
        if (/^[A-Z)(*]$/i.test(char)) {
          const position = this.getCursorPosition()
          if (position.select > 0) {
            this.primer.deleteChar(position, true)
          }
          this.primer.insertCharOrPart(char)
          this.primer.replaceDegenerateCharsInPrimer()
        }
      }
      args.preventDefault()
    },

    calcRangeLength (elFragment) {
      return elFragment.textContent.length + $(elFragment).find('img').length
    },

    calcRangeWidth (elFragment) {
      var el = $('<div></div>').append(elFragment)
      var result = el.hide().appendTo($('body')).width()
      el.remove()
      return result
    },

    getPositionFromRange (range) {
      var selectLength = 0
      if (!range.collapsed) {
        var saveEl = range.startContainer
        var savePos = range.startOffset
        selectLength = this.calcRangeLength(range.cloneContents())
      }
      range.setStart(this.$el, 0)

      var fragment = range.cloneContents()
      var position = this.calcRangeLength(fragment)
      var width = this.calcRangeWidth(fragment)

      if (saveEl) {
        range.setStart(saveEl, savePos)
      } else {
        range.collapse(false)
      }
      return {position: position, select: selectLength, width: width}
    },

    getCursorPosition () {
      var selection = window.getSelection()
      if (selection.rangeCount !== 0) {
        var range = selection.getRangeAt(0)
        return this.getPositionFromRange(range)
      } else {
        return {position: 0, select: 0, width: 0}
      }
    },

    restoreCursor (position) {
      var context = this
      var getElementAtOffset = function () {
        var nodeIterator = document.createNodeIterator(context.$el, NodeFilter.SHOW_ALL, function () {
          return NodeFilter.FILTER_ACCEPT
        }, false)
        var currentOffset = 0
        nodeIterator.nextNode()
        var node

        while (node = nodeIterator.nextNode()) {
          if (node.nodeName === 'SPAN') {
            continue
          }
          var nodeLength = node.nodeName === 'IMG' ? 1 : node.length
          if (currentOffset + nodeLength >= position) {
            return {
              node: node,
              offset: position - currentOffset
            }
          }
          currentOffset += nodeLength
        }
      }

      var selection = window.getSelection()
      selection.removeAllRanges()
      var range = document.createRange()
      if (position === 0) {
        range.setStart(this.$el, 0)
      } else {
        var fnd = getElementAtOffset()
        if (!fnd || !fnd.node) {
          return
        }
        if (fnd.node.nodeName === 'IMG') {
          range.setStartAfter(fnd.node)
        } else {
          range.setStart(fnd.node, fnd.offset)
        }
      }
      selection.addRange(range)
    },

    onMouseUp () {
      this.primer.setCursor(this.getCursorPosition())
    },

    onPaste (args) {
      args.stopPropagation()
      args.preventDefault()
      var clipboardData = args.clipboardData || window.clipboardData
      var pastedData = clipboardData.getData('Text')
      if (pastedData.length === 0) {
        return
      }
      this.primer.insertCharOrPart(clipboardData.getData('Text'))
    },

    onDrop (args) {
      const data = args.dataTransfer.getData('Text')
      const modifier = _.find(this.modifiers, mod => Number(mod.id) === Number(data))

      const range = document.caretRangeFromPoint(args.x, args.y)
      if (range && modifier) {
        const position = this.getPositionFromRange(range)
        this.primer.addModifier(modifier, position.position)
        this.primer.cursorPosition = position.position + 1
      }
      args.stopPropagation()
      args.preventDefault()

      return false
    },

    onDragOver (args) {
      var range = document.caretRangeFromPoint(args.x, args.y)
      if (range) {
        var position = this.getPositionFromRange(range)
        this.restoreCursor(position.position)
        this.primer.cursorPosition = position.position
      }
      args.preventDefault()
    },

    onDragEnter (args) {
      args.preventDefault()
      return true
    },

    onInsertModifier (args) {
      if (args.target !== this.primer.id) {
        return
      }
      if (args.position === 'in') {
        var posAndSel = this.getCursorPosition()
        if (posAndSel.select > 0) {
          this.primer.deleteChar(posAndSel, true)
        }
        this.primer.addModifier(args.modifier)
      } else {
        const position = args.position === 'left'
          ? 0
          : this.primer.lengthWithMods()
        this.primer.addModifier(args.modifier, position)
      }
    }
  }
}
