
export default {
  name: 'Pcr',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Реактивы для ПЦР и ПЦР-РВ',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ДНК-полимеразы и готовые смеси для ПЦР и ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Полимеразная цепная реакция, реактивы пцр, реагенты пцр, ферменты пцр, пцр евроген, реактивы пцр-рв, реагенты пцр-рв, ферменты пцр-рв, пцр-рв евроген, pcr, real time pcr'
        }
      ]
    }
  }
}
