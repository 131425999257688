
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'

export default {
  name: 'MagStand',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Магнитные штативы МагСтенд',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Магнитные штативы МагСтенд предназначены для осаждения магнитных микрочастиц (например, CleanMag DNA).'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'BC036, BC037, bc038, магнитный штатив, магстенд, штатв магнитные частицы\n'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/BC037SML_MagStand.jpg'},
        {url: '/img/BC038_MagStand.jpg'},
        {url: '/img/BC036M_MagStand.jpg'},
      ],
      imageIndex: null
    }
  }
}
