import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=2ae976c0&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ae976c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductsPriceNotice: require('/var/www/localhost/evrogen.ru/components/ProductsPriceNotice.vue').default,BuyProductArea: require('/var/www/localhost/evrogen.ru/components/BuyProductArea.vue').default})
