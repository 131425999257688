

import ContactsChemistryRow from '@/components/page_blocks/rows/ContactsChemistryRow'
export default {
  name: 'OrderSanger',
  components: {
    ContactsChemistryRow
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Заказать химический синтез',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Заказать химический синтез или разработку полупромышленных технологий синтеза'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
