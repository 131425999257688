
import BuyProductArea from '@/components/BuyProductArea'
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  name: 'ExtractDnaFfpeNomotech',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ЭкстрактДНК FFPE',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для выделения ДНК человека из срезов с FFPE-блоков'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ЭкстрактДНК FFPE, R1001'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/M1001_FFPE.jpg'}
      ],
      imageIndex: null
    }
  }
}
