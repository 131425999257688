
export default {
  name: 'StepOutRace',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Step-Out RACE',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Описание технологии Step-Out RACE (англ.)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Step-Out, RACE'
        }
      ]
    }
  }
}
