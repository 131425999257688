
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'FP',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Флуоресцентные белки и продукты на их основе',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'флуоресцентные белки, биосенсор флуоресцентный, фотосенсибилизатор, рекомбинантный флуоресцентный белок, вектор флуоресцентный белок, вектор fp, плазмида fp биотехнология, синтез олигонуклеотидов, генная инженерия, наука, евроген, олигонуклеотиды, прайм'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
