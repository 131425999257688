
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPatentsNotice from "@/components/ProductsPatentsNotice";
import ProductsSpecialPriceNotice from "@/components/ProductsSpecialPriceNotice";

export default {
  name: 'mKate2',
  components: {
    ProductsSpecialPriceNotice,
    ProductsPatentsNotice,
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'mKate2',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Дальне-красный флуоресцентный белок mKate2'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false,
      imageIndex: null,
      images: [
        {url: '/img/BC032_ExtractRNA.jpg', title: 'Спектры эмиссии дальне-красных мономерных флуоресцентных белков. Спектры приведены в соответствии с их относительной яркостью. Диапазон длин волн, оптимальный для прохождения света через ткани, выделен серым. Спектры эмиссии дальне-красных мономерных белков.'}
      ]
    }
  },
  methods: {
    showModal () {
      this.$modal.show('mkate2image')
    },
    showDialog() {
      this.$modal.show('dialog', {
        title: '',
        text: 'Ваш запрос успешно выполнен',
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    }
  }
}
