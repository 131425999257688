
export default {
  name: 'InvitrogenGatewayLicense',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Limited Use Label License No. 19:Gateway Cloning Products',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Лицензия компании Инвитроген No 19'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'лицензия Gateway, патент Gateway'
        }
      ]
    }
  }
}
