
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'OneTube',
  components: {
    BuyProductArea,
    ProductsPriceNotice,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'OneTube: синтез и амплификация кДНК "в одной пробирке"',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы и смесь OneTube для синтеза и амплификации кДНК "в одной пробирке"'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'синтез амплификация одношаговым, синтез амлификация одна пробирка, one tube, экспрессия генов, синтез амплификация одна стадия, синтез амплификация один шаг, onestep, onetube'
        }
      ]
    }
  },
  data() {
    return {
      details1Expanded: false,
      images: [
        {url: '/img/SK031S.jpg'},
        {url: '/img/SK031M.jpg'},
        {url: '/img/SK032M.jpg'},
        {url: '/img/SK101M.jpg'},
        {url: '/img/OneTubeTM.png'},
        {url: '/img/OneTubeAB.png'}
      ],
      imageIndex: null
    }
  }
}
