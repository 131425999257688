
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'SnpDetect',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'SNPdetect',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Полимераза для детекции однонуклеотидных полиморфизмов (SNP) и высокоточной амплификации фрагментов ДНК длиной до 1 т.п.о.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'SNPdetect, SNP detect, PK022S'
        }
      ]
    }
  },
data () {
  return {
    detailsExpanded: false,
    images: [
      {url: '/img/PK022L.jpg'}
    ],
    swipeItems:             [
      {src: '/img/PK022L.jpg', thumbnail:'/icons/PK022L.jpg' , w: 1200,h: 800, title: 'PK022L.jpg'}
    ],
    imageIndex: null
  }
},
mounted() {
  if (!this.lightbox) {
    this.lightbox = new this.$PhotoSwipeLightbox({
      gallery: '#myGallery',
      children: 'a.grl',
      pswpModule: this.$PhotoSwipe,
    });
    this.lightbox.init();
  }
},
}
