
export default {
  name: 'Publications',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Публикации',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Публикации сотрудников Компании Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'евроген публикации, евроген сотрудники'
        }
      ]
    }
  }
}
