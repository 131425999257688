
import ServicesNote from '@/components/page_blocks/ServicesNote'
import ConfidentialityV1 from '@/components/page_blocks/ConfidentialityV1'
export default {
  name: 'AmpliconCloning',
  components: {
    ConfidentialityV1,
    ServicesNote
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Клонирование ПЦР-продуктов (ампликонов)',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Услуга включает: (1) клонирование фрагмента (ампликона), предоставленного заказчиком, в стандартный вектор pAL2-TA; (2) отбор рекомбинантных клонов путем ПЦР; секвенирование вставки (опционально) и (3) выделение плазмидной ДНК (5-10 мкг).'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
