
import BtnWithLoading from '@/components/BtnWithLoading'
import AddClient from '@/components/AddClient'
import EditAddressForm from '@/components/EditAddressForm'
import DeleteAddressModal from '@/components/modals/DeleteAddressModal'
import * as Address from '@/assets/address.js'
import _ from 'lodash'

export default {
  name: 'UserAddresses',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    BtnWithLoading,
    AddClient,
    EditAddressForm,
    DeleteAddressModal
  },
  head() {
    return {
      title: 'Адреса доставки',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    addresses () {
      if (!this.user || !this.user.addresses) {
        return []
      }

      return this.user.addresses
    },
    clients() {
      if (!this.user || !this.user.clients) {
        return []
      }

      return this.user.clients.map(c => {
        c.addresses = this.addresses.filter(a => a.client_fk * 1 === c.id * 1)
        return c
      }).sort((a, b) => {
        return a.is_person > b.is_person
      })
    },
  },
  validations() {
    return {

    }
  },
  created () {
    this.$nuxt.$on('delete-address', (a) => {
      this.doDeleteAddress(a)
    })
  },
  async mounted() {
    await this.$store.dispatch('user/loadUser')

    if (!this.user || !this.user.id) {
      this.$router.push('/auth/login?ret=/profile/addresses')
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('delete-address')
  },
  data() {
    return {
      currentEditAddress: {},
      newClient: null,
      emptyClient: {
        affiliate: {id: '', name: ''},
        id: 0,
        name: '',
        is_person: 0,
        pdDiscount: 0,
        simplePrimerDiscount: 0,
        source: 'site'
      },
      clientAddInProgress: false
    }
  },
  methods: {
    onBtnEditAddress(address) {
      this.currentEditAddress = Object.assign(new Address.Address(), address)
    },

    async doDeleteAddress(address) {
      if (address.id >= 0) {
        try {
          address.forDelete = 1
          await this.$store.dispatch('user/saveAddress', address)
        } catch (ex) {
          address.forDelete = 0
          this.showDialog('Ошибка удаления адреса', ex)
          return
        }
      }

      this.addresses.splice(this.addresses.indexOf(address), 1)
    },

    onAddressSaved(address) {
      address = Object.assign(address, this.currentEditAddress)
      this.currentEditAddress.id = null
    },

    onAddressCreated(address) {
      this.addresses.push(address)
      this.currentEditAddress = Object.assign({}, new Address.Address())
      this.currentEditAddress.id = null
    },

    onCancelEditAddress(address) {
      this.currentEditAddress.id = null

      if (address.id <= 0) {
        this.addresses.splice(this.addresses.indexOf(address), 1)
      }
    },

    showDeleteAddressModal(address) {
      this.$modal.show(
        DeleteAddressModal,
        {
          title: 'Подтвердите удаление',
          address: address
        },
        {
          resizable: false,
          draggable: false,
          clickToClose: true
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },

    onBtnNewAddress(client_id) {
      const newAddr = new Address.Address()
      newAddr.client_fk = client_id
      this.currentEditAddress = newAddr
    },

    showAddClientDialog() {
      this.newClient = Object.assign({}, this.emptyClient)
      this.clientAddInProgress = true
    },

    hideAddClientDialog () {
      this.newClient = Object.assign({}, this.emptyClient)
      this.clientAddInProgress = false
    },

  }
}
