
import Header from '@/pages/sequence/Header';
import Primer from '@/assets/primer.js';
import Footer from '@/layouts/main/Footer';
import SequenceFormConfirm from "@/components/modals/SequenceFormConfirm.vue";
import crossWindowCaller from '@/plugins/myCrossWindowCaller.js';
import Vue from "vue";

export default {
  name: 'Sequence',
  components: { Header, Footer },
  head() {
    return {
      title: 'Секвенирование - редактор',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data() {
    return {
      basketIsLoad: false,
      activeTab: 'import',
      cancelReturnPath: '/',
      menuItems: [
        {
          name: 'import',
          title: 'Ввод данных',
        },
        {
          name: 'primers',
          title: 'Праймеры',
        },
        {
          name: 'final',
          title: 'Детали заявки',
        }
      ],
    }
  },
  async fetch() {
    await this.$store.dispatch('dictionaries/loadSettings')
    await this.$store.dispatch('dictionaries/loadProducts', [])
    await this.$store.dispatch('dictionaries/loadModifiersAndBulks')
    try {
      await this.$store.dispatch('user/loadUser')
    } catch (e) {
      console.log(e, '!!!');
    }
  },

  async mounted() {
    await Promise.all([this.$store.dispatch('dictionaries/loadSettings'),
                  this.$store.dispatch('dictionaries/loadModifiersAndBulks'),
                  this.$store.dispatch('user/loadUser')])
    Primer.initStaticFields(this.$store.state.dictionaries)
    await this.$store.dispatch('basket/loadBasket');
    this.basketIsLoad = true;
    if (this.$route?.hash) {
      this.cancelReturnPath = '/' + this.$route?.hash?.replace('#', '');
    }
  },

  unmounted() {
    window.removeEventListener('message', this.receiveMessage)
  },

  methods: {
    onFrameLoad() {
      if (process.browser) {
        const targetWindow = this.$refs.frame.contentWindow;
        Vue.use(crossWindowCaller, { name: 'evrogen.ru', targetWindow });
        this.$cwc.setTargetWindow(targetWindow);
      }
      this.$cwc.setFunction('resize', height => {
        if (this.$refs?.frame?.style) {  //Иначе в процессе закрытия компонента может наесться
          this.$refs.frame.style.height = height > 400 ? `${height}px` : '400px';
        }
      });
      this.$cwc.setFunction('setTab', tabName => this.setActiveTab(tabName));
      this.$cwc.setFunction('confirm', ({ title, text }) =>
        new Promise(resolve =>
                      this.$modal.show(SequenceFormConfirm, {onClose: (result) => {
                         resolve(result)
                        }})
        )
      );
      this.$cwc.setFunction('done', (data) => {
        this.$store.dispatch('basket/setBasketSequence', data);
        this.$router.push('/basket');
      });
      this.$cwc.setFunction('cancel', () => {
        this.$router.push(this.cancelReturnPath);
      });
      this.$cwc.setFunction('getUserEmails', () => {
        if (!this.$store.state.user.user) {
          return '';
        }

        if (this.$store.state.user.user.loginState === 'new') {
          return this.$store.state.user.user.login
        }
        if (this.$store.state.user.user.contacts && this.$store.state.user.user.contacts.some(c => c.type === 'mail')) {
          return this.$store.state.user.user.contacts.filter(c => c.type === 'mail').map(c => c.contact).join(', ')
        }
        return ''
      });
      if (this.cancelReturnPath === '/basket') {
        this.activeTab = 'final';
      }
    },

    async setActiveTab(activeTab) {
      if (activeTab === 'start') {
        this.$router.push(this.cancelReturnPath)
      } else {
        let canNavigate = await this.$cwc.call('setTab', activeTab);
        if (canNavigate) {
          this.activeTab = activeTab;
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    clients() {
      if (!this.user || !this.user.clients) {
        return []
      }
      return this.user.clients
    },
    discountPercent() {
      let percent = this.user.discountInfo?.sequenceDiscount;
      if (percent) {
        return percent;
      }

      if (this.clients === []) {
        return 0
      }

      return this.clients.reduce((res, curr) => {
        if (curr.sequenceDiscount * 1 > res) {
          res = curr.sequenceDiscount * 1
        }

        return res
      }, 0)
    },
    editorUrl() {
      let url = `${process.env.seqEditorUrl}/editor?uid=${this.$store.state.basket.basket.id}`;
      if (this.$route?.hash && this.$route?.hash.includes('basket') ) {
        url += "#final";
      }
      return url;
    }
  }

}
