
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'SoutRu',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main_page/Index',
  head() {
    return {
      title: '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  },
  methods: {
    openFiles(){
      window.open("/sout/ru/prmpuut.pdf","link1")
      window.open("/sout/ru/svrpsout.pdf", "link2")
    }
  }
}
