
import NewYearAlert from '@/components/NewYearAlert'
export default {
  name: 'ServicesPricelist',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    NewYearAlert
  },
  head() {
    return {
      title: 'Прайс-лист на сервисные услуги',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'синтез олигонуклеотидов цена, синтез олигов цена, синтез праймеров цена, праймеры цена, зонды евроген цена, синтез евроген цена, синтез олигонуклеотидов стоимость, синтез олигов стоимость, синтез праймеров стоимость, праймеры стоимость, зонды евроген стоимость, синтез евроген стоимость'
        }
      ]
    }
  },
  methods: {
    getPrimerPrice(scale) {
      return this.$store?.state?.dictionaries?.primerPrices?.[scale].char;
    }
  }
}
