
import BuyProductArea from "../../../components/BuyProductArea";
export default {
  name: 'MGMT',
  components: {BuyProductArea},
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ЭпиГенТест MGMT',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для качественного определения статуса метилирования промоторной области гена методом метилспецифической ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ЭпиГенТест MGMT, ГенТест MGMT, тест система MGMT, kit MGMT, набор MGMT, мутация MGMT, горячая точка MGMT, аллельный вариант MGMT, скрининг MGMT, полиморфизм MGMT, статус метилирования, MGMT, ACTB, аберрантное метилирование, NM_002412.4, бисульфитная обработка'
        }
      ]
    }
  }
}
