
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from "../../../components/ProductsPriceNotice";

export default {
  name: 'ExtractDnaFfpe',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ExtractDNA FFPE',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор для выделения ДНК из срезов с FFPE-блоков и цитологических стеклопрепаратов'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'FFPE выделение, FFPE набор, FFPE kit, ffpe колонки'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/BC103_FFPE.jpg'}
      ],
      imageIndex: null
    }
  }
}
