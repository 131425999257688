
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'CDNA',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Реактивы для работы с кДНК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Ревертазы и наборы для синтеза кДНК, реактивы для нормализации кДНК и амплификации концевых фрагментов (RACE)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
