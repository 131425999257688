

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import { VueSelect } from "vue-select";

export default {
  name: 'selectDatesModal',
  components: { VueSelect, DatePicker },
  data() {
    return {
      selectedType: '',
      types: [
        {value:'', label: 'Все'},
        {value: 'sst', label:'Олигонуклеотиды без модификаций (SST)'},
        {value:'mst', label: 'Олигонуклеотиды c модификациями (MST)'}
      ],
      range:(() => {
        let startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        return [startDate, new Date()];
      })(),
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    async onExport (){
       this.exportSelect = null;
      let options = {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer'
      };
      let payload = {
        from: this.range[0],
        to: this.range[1],
        type: this.selectedType?.value
      }
      let data = await this.$myHelper.axiosWithMyEx('/profile/GetOrderExport', payload, options)
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]));
      link.setAttribute('download', 'orders.xlsx');
      document.body.appendChild(link);
      link.click();
      this.$emit('close')
    }
  }

}
