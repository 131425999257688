

import ContactsSangerRow from '@/components/page_blocks/rows/ContactsSangerRow'
export default {
  name: 'OrderSanger',
  components: {
    ContactsSangerRow
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Заказать секвенирование ДНК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
