
import BuyProductArea from "../../../components/BuyProductArea";
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  name: 'SLCO1B1',
  components: {BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ГенТест SLCO1B1',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для выявления полиморфизма в гене человека SLCO1B1 методом ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/R2003_SLCO1B1.jpg'}
      ],
      imageIndex: null
    }
  }
}
