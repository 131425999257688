
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'Encyclo',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Encyclo и Encyclo Plus PCR kit',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Полимераза для амплификации сложных смесей ДНК и кДНК, низкокопийных ДНК-матриц и фрагментов ДНК длиной до 20 т.п.о.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Encyclo, пцр кДНК, полимераза низкокопийная матрица, полимераза длинный фрагмент'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
