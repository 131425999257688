
import domtoimage from 'dom-to-image'
import $ from 'jquery'
import _ from 'lodash'

export default {
  name: 'ModifiersButtons',
  props: ['in-header'],
  computed: {
    modifiers() {
      return this.$store.state.dictionaries.modifiers
    }
  },
  mounted() {
    $(document).mouseup($.proxy(function (e) {
      if ($(e.target).hasClass('prm-mod-button')) {
        return
      }
      this.hideAll()
    }, this))
  },
  data() {
    return {
      activeDiv: ''
    }
  },
  methods: {
    setActiveDiv (position) {
      this.activeDiv = this.activeDiv === position ? '' : position
      this.hideAll(true)
    },

    hide () {
      this.activeDiv = ''
    },

    hideAll (withoutThis) {
      withoutThis = typeof withoutThis === 'undefined' ? false : withoutThis
      var allCmp = this.findAllModCmp(this.$root)
      allCmp.forEach(cmp => {
        if ((!withoutThis) || cmp._uid !== this._uid) {
          cmp.hide()
        }
      })
    },

    getSortedMods (position) {
      return this.modifiers
        .filter(m => !m.isDeleted && !m.disabled && m.acceptedPositionArray.indexOf(position) >= 0  )
        .toSorted((a, b) => a.name.localeCompare(b.name))
   },

    findAllModCmp (component) {
      let result = []
      if (component.$options.name === this.$options.name) {
        return [component]
      }
      if (component.$children) {
        Array.prototype.push.apply(result,
          component.$children.reduce((acc, item) => {
            Array.prototype.push.apply(acc, this.findAllModCmp(item))
            return acc
          }, []))
      }
      return result
    },

    onClick (e, item, position) {
      $("[id^='mods_']").hide()
      // noinspection JSUnresolvedVariable
      if (this.inHeader) {
        this.$nuxt.$emit('click-group-modifier', {modifier: item, position: position})
      } else {
        this.$nuxt.$emit('click-modifier', {modifier: item, position: position})
      }
    },

    onDragStart (args, modId) {
      // noinspection JSUnresolvedVariable
      if (this.inHeader) {
        return false
      }
      // noinspection JSUnresolvedVariable
      args.dataTransfer.effectAllowed = 'copy'
      // noinspection JSUnresolvedVariable
      args.dataTransfer.setData('Text', modId.toString())
      return true
    }
  },

  async generateButtons(modifierStore) {
    const cnt = $('#genCnt')
    if (cnt.length > 0) {
      cnt.html('')
    } else {
      $('body').prepend("<div id='genCnt' style='width: 100%;padding: 0;display: flex;flex-wrap: wrap;' >")
    }
    await this.$myHelper.axiosWithMyEx('/controllers/basket/deleteImages')

      ['left', 'in', 'right', 'menu'].forEach(async (position) => {
      await this.checkModImages(position, modifierStore)
    })
  },

  async checkModImages(position, modifiers) {
    for (let item of modifiers) {
      let itemName = position !== 'left' && position !== 'menu' ? '-' : ''
      itemName += '/' + item.name + '/'
      itemName += position !== 'right' && position !== 'menu' ? '-' : ''

      const url = `/modsImages/${item.name}_${position}.png?hash=${Math.random() * 9999999}`
      try {
        await this.$myHelper.axiosWithMyEx(url)
      } catch (ex) {
        $('#genCnt').append(`<div class = 'mod-generate modificator_${position}' id='divMod_${item.id}_${position}' >${itemName}</div><div style='float:left;'>&nbsp;</div>`)
        const modEl = $(`#divMod_${item.id}_${position}`)
        this.buildAndSaveImage(item.name + '_' + position, modEl)
      }
    }
  },

  buildAndSaveImage(name, el) {
    var modSize = this.calcModSize(el)
    // noinspection JSUnresolvedFunction
    domtoimage.toPng(el[0], {width: modSize.width, height: modSize.height})
      .then(dataUrl => {
        this.sendToServer(name, dataUrl)
        el.addClass('modificator_error')

        // noinspection JSUnresolvedFunction
        domtoimage.toPng(el[0], {width: modSize.width, height: modSize.height})
          .then(dataUrl => {
            this.sendToServer(name + '_err', dataUrl)
          })
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
  },

  sendToServer(name, dataUrl) {
    const formData = new FormData()
    formData.append(name, dataUrl)
    this.$axios.post('/controllers/basket/buildModImage', formData, {headers: {'Content-Type': 'multipart/form-data'}})
  },

  calcModSize(jqEl) {
    let totalWidth = jqEl.width()
    totalWidth += parseInt(jqEl.css('padding-left'), 10) + parseInt(jqEl.css('padding-right'), 10)
    totalWidth += parseInt(jqEl.css('margin-left'), 10) + parseInt(jqEl.css('margin-right'), 10)
    totalWidth += parseInt(jqEl.css('borderLeftWidth'), 10) + parseInt(jqEl.css('borderRightWidth'), 10)

    let totalHeight = jqEl.height()
    totalHeight += parseInt(jqEl.css('padding-top'), 10) + parseInt(jqEl.css('padding-bottom'), 10)
    totalHeight += parseInt(jqEl.css('margin-top'), 10) + parseInt(jqEl.css('margin-bottom'), 10)
    totalHeight += parseInt(jqEl.css('borderTopWidth'), 10) + parseInt(jqEl.css('borderBottomWidth'), 10)

    return {width: totalWidth, height: totalHeight}
  }
}

// Функция генерит кртинки для всех модификаторов если их нет

// <div ><span style='padding-left:3px' id='\"divMod_\" + item.id' ><span class = 'mod' >{{item.name}}</span></span> </div>
