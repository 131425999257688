
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'MMLV',
  components: {
    BuyProductArea,
    ProductsPriceNotice,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'MMLV и MMLV RT kit',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Обратная транскриптаза MMLV для синтеза первой цепи кДНК с одноцепочечной РНК-матрицы'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data() {
    return {
      details1Expanded: false,
      details2Expanded: false,
      images: [
        {url: '/img/SK021_MMLV_RT_kit.jpg'},
        {url: '/img/SK022S_MMLV.jpg'}
      ],
      imageIndex: null
    }
  }
}
