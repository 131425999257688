
export default {
  name: 'LongProductAmplification',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'PCR amplification of long DNA fraction',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Описание метода селективной амплификации длинной фракции ДНК (англ.)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'PCR long DNA fraction, amplification long DNA fraction, селективная амплификация, ПЦР длинной фракции'
        }
      ]
    }
  }
}
