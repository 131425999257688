import { render, staticRenderFns } from "./Contacts.vue?vue&type=template&id=60d1128c&scoped=true"
import script from "./Contacts.vue?vue&type=script&lang=js"
export * from "./Contacts.vue?vue&type=script&lang=js"
import style0 from "./Contacts.vue?vue&type=style&index=0&id=60d1128c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d1128c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnWithLoading: require('/var/www/localhost/evrogen.ru/components/BtnWithLoading.vue').default})
