
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  name: 'Normalization',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    CoolLightBox
  },
  head() {
    return {
      title: 'DSN-normalization of full-length-enriched cDNA',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Описание технологии нормализации кДНК (англ.)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'DSN-normalization, нормализация кДНК'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img_large/normalization-large.png'},
      ],
      imageIndex: null
    }
  }
}
