
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'

export default {
  name: 'CleanRna',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'CleanRNA Standard',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор для очистки препаратов суммарной РНК'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'очистка тризол, очистка tri reagent, очистка ExtractRNA, очистка trizol, очистка РНК по Хомчинскому, очистка суммарной рнк'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/BC033_CleanRNA.jpg'}
      ],
      imageIndex: null
    }
  }
}
