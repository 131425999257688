


import ServicesChemistry from "@/components/page_blocks/ServicesChemistry";

export default {
  name: 'Chemistry',
  components: {
    ServicesChemistry
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Химический синтез',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Заказной химический синтез'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
