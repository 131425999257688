
import BtnWithLoading from '@/components/BtnWithLoading'

export default {
  name: 'RegistrationComplete',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    BtnWithLoading
  },
  head() {
    return {
      title: 'Завершение регистрации',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  data() {
    return {
      error: null
    }
  },
  async created () {
    await this.checkRegComplete()
  },
  methods: {
    async checkRegComplete () {
      let errMessage = 'Не удалось завершить регистрацию. Перейдите по ссылке из письма еще раз или начните регистрацию заново'

      let id = this.$route.query.id

      if (!id) {
        this.error = errMessage
        return
      }

      try {
        await this.$store.dispatch('user/registrationComplete', JSON.stringify(id))
        await this.$store.dispatch('user/loadUser')
      } catch (ex) {
        this.error = errMessage
        return
      }

      this.error = ''
    }
  }
}
