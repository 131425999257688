
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'KtnMixHs',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '5X KTNmix-HS',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ПЦР-смесь для ПЦР-РВ с использованием технологий, основанных на изменении конформации флуоресцентной пробы без ее разрушения (Scorpion, Amplifluor, LUX и т.п.)'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: '5X KTNmix-HS, PK157S, PK157L'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
