

import ContactsSynthesisRow from './rows/ContactsSynthesisRow'
import ContactsSangerRow from './rows/ContactsSangerRow'
import ContactsGesRow from './rows/ContactsGesRow'
import ContactsProductsRow from './rows/ContactsProductsRow';
import ContactsChemistryRow from './rows/ContactsChemistryRow';

export default {
  name: 'ContactsBlock',
  components: {
    ContactsProductsRow,
    ContactsGesRow,
    ContactsSangerRow,
    ContactsSynthesisRow,
    ContactsChemistryRow
  }
}
