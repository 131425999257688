
import PasswordRecoveryForm from '@/components/PasswordRecoveryForm'
import LoggedInNotice from '@/components/LoggedInNotice'

export default {
  name: 'RestorePassword',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    LoggedInNotice,
    PasswordRecoveryForm,
  },
  head() {
    return {
      title: 'Восстановление пароля',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  }
}
