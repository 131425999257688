import { render, staticRenderFns } from "./PrimerEdit.vue?vue&type=template&id=78f63ad8&scoped=true"
import script from "./PrimerEdit.vue?vue&type=script&lang=js"
export * from "./PrimerEdit.vue?vue&type=script&lang=js"
import style0 from "./PrimerEdit.vue?vue&type=style&index=0&id=78f63ad8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f63ad8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModifiersButtons: require('/var/www/localhost/evrogen.ru/components/ModifiersButtons.vue').default,PrimerEditor: require('/var/www/localhost/evrogen.ru/components/PrimerEditor.vue').default,BtnWithLoading: require('/var/www/localhost/evrogen.ru/components/BtnWithLoading.vue').default})
