
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'CDNASynthesis',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Ревертазы и наборы для синтеза кДНК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Обратная транскрипция и амплификация кДНК в одну или две стадии'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'синтез кднк, от-пцр, ревератаза, mmlv, mulv, обратная транскриптаза, обратная транскрипция'
        }
      ]
    }
  },
  data() {
    return {
      details1Expanded: false,
      details2Expanded: false
    }
  }
}
