

export default {
  name: 'LeftTechnicalInfo',
  components: {},
  computed: {
    sectionName () {
      if (this.$route.path === '/') {
        return '/'
      }

      return this.$route.path.substring(1).split('/').reduce((res, curr) => {
        if (Object.keys(this.menuOptionsNormilized).includes(curr.toLowerCase())) {
          res = curr.toLowerCase()
        }

        return res
      }, '')
    },
    currentSection () {
      if (!this.menuOptionsNormilized[this.sectionName]) {
        return null
      }

      return this.menuOptionsNormilized[this.sectionName]
    },
    menuOptionsNormilized () {
      let res = {}

      Object.keys(this.menuOptions).forEach(k => {
        if (this.$route.path.toLowerCase().includes(this.menuOptions[k].href.toLowerCase())) {
          res[k.toLowerCase()] = this.menuOptions[k]
        }
      })

      return res
    }
  },
  data () {
    return {
      path: '/',
      menuOptions: {
        'mixes': {
          href: '/products/pcr/mixes',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Evrogen-PCR-recommendation.pdf', text: 'Полимеразы', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/Evrogen-PCR-products.pdf', text: 'Готовые смеси', absolute: true}
          ]
        },

      }
    }
  },
  methods: {

  }
}
