import { render, staticRenderFns } from "./Orders.vue?vue&type=template&id=feee5180"
import script from "./Orders.vue?vue&type=script&lang=js"
export * from "./Orders.vue?vue&type=script&lang=js"
import style0 from "./Orders.vue?vue&type=style&index=0&id=feee5180&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnWithLoading: require('/var/www/localhost/evrogen.ru/components/BtnWithLoading.vue').default})
