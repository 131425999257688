
import * as Address from '@/assets/address.js'
import VueSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import BtnWithLoading from './BtnWithLoading'

export default {
  name: 'EditAddressForm',
  props: {
    address: {default: () => new Address.Address()},
    clientId: {default: null}
  },
  components: {
    VueSuggest,
    BtnWithLoading
  },
  computed: {
    user() {
      return this.$store.state.user.user
    }
  },
  data() {
    return {
      autocompleteModel: '',
      findAddressNow: false,
      addressFields: Address.doDataToAddressFields
    };
  },
  methods: {
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    async getAutocompleteItems(text) {
      if (text.length < 3) {
        return []
      }

      this.findAddressNow = true

      try {
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"
        let token = "8ad7dc63420e2f4ced30334c78022c40a7f1e5e4"
        let args = {
          query: text,
          count: 20
        }

        let options = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
          },
          body: JSON.stringify(args)
        }

        let res = await fetch(url, options)
        let dadataResponse = await res.json()

        return dadataResponse.suggestions.map(item => {
          item.data.value = item.unrestricted_value
          return item.data
        })

      } catch (ex) {
        return []
      } finally {
        this.findAddressNow = false
      }
    },

    onAutocompleteSelect(selectedItem) {
      if (!selectedItem) {
        this.address.clear()
        return
      }
      Address.doDataToAddressFields.forEach(fldDscr => {
        this.address[fldDscr.name] = typeof fldDscr.src === 'function' ? fldDscr.src(selectedItem) : selectedItem[fldDscr.src]
      })
    },

    async onBtnSaveAddress() {
      try {
        if (this.clientId * 1 === -1) {
          this.address.client_fk = await this.$store.dispatch('user/addPersonClient')
        } else {
          this.address.client_fk = this.clientId
        }
      } catch (ex) {
        this.showDialog('Ошибка изменения адреса', ex)
        return
      }

      const findInDaData = await this.getAutocompleteItems(this.address.toString())
      if (findInDaData.length === 1) {
        this.address.fias_id = findInDaData[0].fias_id
        this.address.fias_code = findInDaData[0].fias_code
        this.address.kladr_id = findInDaData[0].kladr_id
      } else {
        this.address.fias_id = this.address.fias_code = this.address.kladr_id = ''
      }

      try {
        this.address.user_fk = this.user.id
        this.address.id = await this.$store.dispatch('user/saveAddress', this.address)
      } catch (ex) {
        this.showDialog('Ошибка изменения адреса', ex)
        return
      }

      this.$emit('save-edit-address', this.address)
    },

    onBtnCancelAddress() {
      this.$emit('cancel-edit-address')
    }
  }
}
