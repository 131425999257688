
import {required, minLength} from 'vuelidate/lib/validators'
import BtnWithLoading from '@/components/BtnWithLoading'
import LoggedInNotice from '@/components/LoggedInNotice'

export default {
  name: 'SetNewPassword',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    LoggedInNotice,
    BtnWithLoading
  },
  head() {
    return {
      title: '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    passwordCopy: {
      required,
      check (val) {
        return val === this.password
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    },
  },
  mounted() {
    this.hash = this.$route.query.id
  },
  data () {
    return {
      password: '',
      passwordCopy: '',
      err_common: null,
      success: false,
      hash: ''
    }
  },
  methods: {
    async setNewPassword() {
      this.success = false
      this.err_common = ''

      try {
        let args = {
          hash: this.hash,
          password: this.password
        }

        await this.$store.dispatch('user/setNewPassword', args)
      } catch (ex) {
        this.err_common = ex.message
        return
      }

      await this.$store.dispatch('user/loadUser')
      this.success = true
    }
  }
}
