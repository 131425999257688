
import BtnWithLoading from './BtnWithLoading'

export default {
  components: {
    BtnWithLoading,
  },
  name: 'ToBasketButtons',
  methods: {

  }
}
