
import CoolLightBox from "vue-cool-lightbox"
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea';

export default {
  name: 'MycoRealTime',
  components: {
    BuyProductArea,
    ProductsPriceNotice,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Myco Real-Time',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реактивов для выявления контаминации микоплазмой культур клеток методом ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'контаминация клеток, набор микоплазма культуры, набор микоплазма клетки, Mollicutes детекция набор, Mollicutes выявление набор, MycoSensor, venor gem, MycoTOOL, Mycoplasma Kit, набор микоплазма ПЦР, обнаружение микоплазма пцр, определить микоплазма пцр,'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/MR004_Myco_Real-Time.jpg'}
      ],
      imageIndex: null
    }
  }
}
