
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'
export default {
  name: 'PcrMixtures',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Готовые смеси для ПЦР',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'При постановке реакции в смесь требуется добавить только праймеры, ДНК-матрицу и воду.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Готовые смеси пцр, пцр смесь, пцр микс, qpcr, pcr mix, pcrmix'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
