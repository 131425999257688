
export default {
  name: 'Distributors',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Дистрибьюторы',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Официальные дистрибьюторы Евроген в России и других странах'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'хеликон, альгимед, окабиолаб, акрус, рбк лаб, лабоген, медиген, дистрибуция евроген, дистрибьютор евроген'
        }
      ]
    }
  }
}
