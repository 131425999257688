
import BuyProductArea from '@/components/BuyProductArea'
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  name: 'CES1',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ГенТест CES1',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для выявления полиморфизма в гене человека CES1 методом ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ГенТест CES1, R2004, тест система CES1, kit CES1, набор CES1, мутация CES1, горячая точка CES1, аллельный вариант CES1, скрининг CES1, полиморфизм CES1, CES1 c(-33)a, NG_012057.1:g.27467C> A, NM_001266.4:c.1165-33C> A, rs2244613'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/R2004_CES1.jpg'}
      ],
      imageIndex: null
    }
  }
}
