
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'ScreenMixHsUdg',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '5X ScreenMix-HS (UDG)',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ПЦР-смесь с UDG для защиты от контаминации'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'PK243S, PK243L, PK243B, смесь гель пцр удг, микс гель пцр удг, mix гель удг, смесь гель пцр udg, микс гель  пцр udg, mix гель udg, смесь форез пцр удг, микс форез  пцр удг, mix форез удг, смесь форез пцр udg, микс форез пцр udg, mix форез udg'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
