
import BuyProductArea from "../../../components/BuyProductArea";
import CoolLightBox from "vue-cool-lightbox";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  name: 'NAT2',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ГенТест-М NAT2',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для выявления полиморфизмов в гене человека NAT2 методом ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ГенТест-М NAT2, ГенТест NAT2, тест система NAT2, kit NAT2, набор NAT2, мутация NAT2, горячая точка NAT2, аллельный вариант NAT2, скрининг NAT2, полиморфизм NAT2, C282T, NG_012246.1:g.14041C> T, rs1041983, NM_000015.2:c.282C> T NP_000006.2:p.Tyr94, T341'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/R2006_NAT2.jpg'}
      ],
      imageIndex: null
    }
  }
}
