
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'BRCA',
  components: {
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'ГенТест-М BRCA1, BRCA2',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор реагентов для выявления наследственных мутаций в генах человека BRCA1 и BRCA2 методом ПЦР-РВ'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'ГенТест-М BRCA1 BRCA2, ГенТест BRCA1 BRCA2, R2007, тест система BRCA1, kit BRCA1, набор BRCA1, мутация BRCA1, горячая точка BRCA1, аллельный вариант BRCA1, скрининг BRCA1, тест система BRCA2, kit BRCA2, набор BRCA2, мутация BRCA2, горячая точка BRCA2'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/M2007_BRCA.jpg'}
      ],
      imageIndex: null
    }
  }
}
