
export default {
  name: 'EvrogenDsnLicense',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Ограниченная Лицензия #002: Продукты на основе Дуплекс-Специфической Нуклеазы компании Евроген',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Ограниченная Лицензия #002: Продукты на основе Дуплекс-Специфической Нуклеазы компании Евроген'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'лицензия дсн, лицензия DSN, лицензия нуклеаза, патент дсн, патент DSN, патент нуклеаза'
        }
      ]
    }
  }
}
