import { render, staticRenderFns } from "./Properties.vue?vue&type=template&id=6c71c897&scoped=true"
import script from "./Properties.vue?vue&type=script&lang=js"
export * from "./Properties.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c71c897",
  null
  
)

export default component.exports