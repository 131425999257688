
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'Lenti',
  components: {
    BuyProductArea,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Лентивирусные частицы, несущие гены флуоресцентных белков',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Лентивирусные частицы, несущие гены флуоресцентных белков'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Лентивирусные частицы, лентивирусы, LVT'
        }
      ]
    }
  }
}
