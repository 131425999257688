
import BtnWithLoading from '../BtnWithLoading'
import VueScrollbar from 'vue2-scrollbar'
import 'vue2-scrollbar/dist/style/vue2-scrollbar.css'
import PolicyTextNew from '../page_blocks/PolicyTextNew'

export default {
  components: {
    PolicyTextNew,
    BtnWithLoading,
    VueScrollbar
  },
  name: 'ViewPolicyModal',
  props: {
    title: null
  },
  computed: {

  },
  data() {
    return {
      findProductText: '',
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
  }

}
