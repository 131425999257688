import { render, staticRenderFns } from "./SelectBufferModal.vue?vue&type=template&id=32aaab1d&scoped=true"
import script from "./SelectBufferModal.vue?vue&type=script&lang=js"
export * from "./SelectBufferModal.vue?vue&type=script&lang=js"
import style0 from "./SelectBufferModal.vue?vue&type=style&index=0&id=32aaab1d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32aaab1d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BufferSelectorCmp: require('/var/www/localhost/evrogen.ru/components/BufferSelectorCmp.vue').default})
