
import ProductsPriceNotice from '@/components/ProductsPriceNotice'
import PolymeraseStorageNotice from '@/components/PolymeraseStorageNotice'
import BuyProductArea from '@/components/BuyProductArea'

export default {
  name: 'qPcrMixHsSybrRox',
  components: {
    BuyProductArea,
    PolymeraseStorageNotice,
    ProductsPriceNotice
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '5X qPCRmix-HS c красителями SYBR и ROX',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'ПЦР-смеси для высокоспецифичной ПЦР в реальном времени с интеркалирующим красителем SYBR Green I и референсным красителем ROX'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: '5X qPCRmix-HS, SYBR+ROX, qPCRmix-HS SYBR HighROX, qPCRmix-HS SYBR LowROX, PK154S, PK154L, PK155S, PK155L, 5X'
        }
      ]
    }
  },
  data () {
    return {
      detailsExpanded: false
    }
  }
}
