
import {required, minValue, decimal} from 'vuelidate/lib/validators'

export default {
  name: 'RCF-RPMCalc',
  components: {},
  computed: {
    currentRCF: {
      get: function () {
        if (this.rcf === 0) {
          return (this.rpm ** 2 * 1.118 * this.radius / 10 ** 5).toFixed()
        } else {
          return this.rcf
        }
      },
      set: function (value) {
        this.rcf = value.replace(/,/, '.').trim()
      }
    },
    currentRPM: {
      get: function () {
        if (this.rpm === 0 && !this.$v.radius.$invalid) {
          return (Math.sqrt(this.rcf * 10 ** 5 / 1.118 / this.radius)).toFixed()
        } else {
          return this.rpm
        }
      },
      set: function (value) {
        this.rpm = value.replace(/,/, '.').trim()
      }
    }
  },
  validations: {
    radius: {
      required,
      decimal,
      check(val) {
        return val * 1 > 0
      }
    },
    rcf: {
      required,
      decimal,
      minValue: minValue(0)
    },
    rpm: {
      required,
      decimal,
      minValue: minValue(0)
    }
  },
  data() {
    return {
      radius: 6,
      rcf: 10000,
      rpm: 0
    }
  },
  mounted() {
    this.$emit('loaded');
  },
  methods: {}
}
