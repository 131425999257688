
import Primer from '@/assets/primer.js'
export default {
  layout: 'main/Index',
  head() {
    return {
      title: 'Секвенирование',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  async mounted () {
    await Promise.all([
                        this.$store.dispatch('dictionaries/loadSettings'),
                        this.$store.dispatch('dictionaries/loadProducts', []),
                        this.$store.dispatch('dictionaries/loadModifiersAndBulks'),
                        this.$store.dispatch('user/loadUser')
                      ]);

    Primer.initStaticFields(this.$store.state.dictionaries)
    await this.$store.dispatch('basket/loadBasket');
    this.pageIsReady = true
  },
}
