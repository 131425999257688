
import BtnWithLoading from '@/components/BtnWithLoading'
import {required, email, minLength} from 'vuelidate/lib/validators'

export default {
  name: 'Profile',
  scrollToTop: true,
  layout: 'main/Index',
  components: {
    BtnWithLoading
  },
  head() {
    return {
      title: 'Личный кабинет',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
  validations() {
    return {
      userInEdit: {
        lName: {
          check(val) {
            return !this.fieldsIsEditNow ? true : val.trim().length > 0
          }
        },
        fName: {
          check(val) {
            return !this.fieldsIsEditNow ? true : val.trim().length > 0
          }
        }
      },
      newPassword: {
        required,
        minLength: minLength(6)
      },
      newPasswordConfirmation: {
        check(val) {
          return val === this.newPassword
        }
      },
      newLogin: {
        required,
        email
      },
      confirmCode: {
        required
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('user/loadUser')

    if (this.user.loginState === 'isChanging') {
      this.newLogin = this.user.login
    }

    if (!this.user || !this.user.id) {
      this.$router.push('/auth/login?ret=/profile')
    }
  },
  data() {
    return {
      newPassword: '',
      newPasswordConfirmation: '',
      newLogin: '',
      confirmCode: '',
      fieldsIsEditNow: false,
      passwordFormIsShow: false,
      userInEdit: null
    }
  },
  methods: {
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    async confirmNewLogin() {
      try {
        await this.$store.dispatch('user/checkCodeAndSetNewLogin', this.confirmCode)
      } catch (ex) {
        this.showDialog('Произошла ошибка, проверьте код', ex)
      }
    },

    startEdit() {
      this.userInEdit = Object.assign({}, this.user)
      this.fieldsIsEditNow = true
    },

    cancelEdit() {
      this.userInEdit = null
      this.fieldsIsEditNow = false
    },

    async saveFields() {
      try {
        await this.$store.dispatch('user/saveUserFields', this.userInEdit)
      } catch (ex) {
        this.showDialog('Ошибка сохранения пользователя', ex)
        return
      }

      this.userInEdit = null
      this.fieldsIsEditNow = false
    },

    async sendLoginConfirmationCode() {
      this.confirmCode = ''

      try {
        await this.$store.dispatch('user/setNewLoginAndSendCode', this.newLogin)
      } catch (ex) {
        this.showDialog('Во время отправки кода произошла ошибка', ex)
        return
      } finally {
        this.loading = false
      }

      this.showDialog('', 'Код подтверждения отправлен на адрес ' + this.newLogin)
      this.user.loginState = 'isChanging'
    },

    startEditPassword() {
      this.passwordFormIsShow = true
    },

    cancelEditPassword() {
      this.passwordFormIsShow = false
      this.newPassword = ''
      this.newPasswordConfirmation = ''
    },

    async changePassword() {
      try {
        await this.$store.dispatch('user/changePassword', JSON.stringify(this.newPassword))
      } catch (ex) {
        this.showDialog('Ошибка смены пароля', ex)
        return
      }

      this.passwordFormIsShow = false
      this.newPassword = ''
      this.newPasswordConfirmation = ''
      this.showDialog('', 'Пароль успешно изменен')
    }
  }
}
