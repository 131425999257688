
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import BuyProductArea from '@/components/BuyProductArea';
import ProductsPriceNotice from "@/components/ProductsPriceNotice";

export default {
  name: 'Cleanup',
  components: {
    ProductsPriceNotice,
    BuyProductArea,
    CoolLightBox
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Набор Cleanup St PCR',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Набор для очистки фрагментов двухцепочечной ДНК из ферментативных реакционных смесей.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'быстрая очистка днк, днк реакционные смеси, днк , выделение днк смеси, очистка днк смеси, агароза колонки, смеси колонки, набор Cleanup'
        }
      ]
    }
  },
  data () {
    return {
      images: [
        {url: '/img/BC025S.jpg'}
    ],
      swipeItems:             [
      {src: '/img/BC025S.jpg', thumbnail:'/icons/BC025S.jpg' , w: 1200,h: 800, title: 'BC025S.jpg'}
    ],
      imageIndex: null
  }
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new this.$PhotoSwipeLightbox({
        gallery: '#myGallery',
        children: 'a.grl',
        pswpModule: this.$PhotoSwipe,
      });
      this.lightbox.init();
    }
  },
}
