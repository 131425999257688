import { render, staticRenderFns } from "./Fragmentation.vue?vue&type=template&id=2ffb1520&scoped=true"
import script from "./Fragmentation.vue?vue&type=script&lang=js"
export * from "./Fragmentation.vue?vue&type=script&lang=js"
import style0 from "./Fragmentation.vue?vue&type=style&index=0&id=2ffb1520&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ffb1520",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Degracalq: require('/var/www/localhost/evrogen.ru/components/Degracalq.vue').default})
