
import BtnWithLoading from '../BtnWithLoading'

export default {
  components: {
    BtnWithLoading,
  },
  props: {
    orders: [],
    basketIsEmpty: true,
    close: null
  },
  data() {
    return { selectedOrders: [] }
  },
  methods: {
    onClickDone(isReplace) {
      const result = {
        isReplace,
        orders: this.orders.length > 1
                   ? this.selectedOrders
                   : this.orders
      }
      this.close(result);
      this.$emit('close');
    }
  },
  computed: {
    disableButtons() {
      return this.orders.length > 1 && this.selectedOrders.length === 0;
    },
  }
}
