
import EditAddressForm from './EditAddressForm'
import * as Address from '@/assets/address.js'
import BtnWithLoading from './BtnWithLoading'
import _ from 'lodash'

export default {
  name: 'AddressForm',
  props: ['addresses', 'withSelector', 'clientId', 'showAddButton', 'addressId', 'cbName'],
  components: {
    BtnWithLoading,
    EditAddressForm
  },
  data() {
    return {
      currentEditAddress: {},
      selectedAddressId: null,
      addNewAddressFlag: false
    };
  },

  watch: {
    clientId() {
      // todo:crutch
      // После добавления адреса для физлица меняется clientId( если его не было )
      // и в этой ситуации сбрасывать выбранный адрес не надо
      // флаг выставляется после записи адреса, а сбрасывается здесь
      if (!this.addNewAddressFlag) {
        this.selectedAddressId = null
      }
      this.addNewAddressFlag = false
    }
  },

  mounted() {
    this.selectedAddressId = this.addressId
  },

  beforeDestroy() {
    if (this.currentEditAddress.id * 1 === -1) {
      const id = _.findIndex(this.addresses, address => address.id * 1 === -1)
      id >= 0 && this.addresses.splice(id, 1)
    }
  },

  computed: {
    activeAddresses() {
      return (!this.addresses || !this.clientId)
        ? []
        : this.addresses.filter(item => (!item.forDelete) && ((!this.clientId) || item.client_fk === this.clientId))
    }
  },
  methods: {
    showDialog(title, text) {
      this.$modal.show('dialog', {
        title: title,
        text: text,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    onChangeSelectedAddress(address) {
      address = address || new Address.Address()
      address.client_fk = this.clientId
      this.selectedAddressId = address.id
      this.$emit('change-address', address)
    },

    onSaveEditAddress(address) {
      address = Object.assign(address, this.currentEditAddress)

      if (this.withSelector) {
        address = address || new Address.Address()
        this.selectedAddressId = address.id
        // Флаг который нужен что б после добавления клиента-физлица одновременно с адресом не слетал выбранный адрес
        // проверяется в watch для clientId
        this.addNewAddressFlag = true
        this.$emit('change-address', address)
      }

      this.currentEditAddress.id = null
    },

    onBtnEditAddress(address) {
      this.currentEditAddress = Object.assign(new Address.Address(), address)
    },

    onBtnNewAddress() {
      const newAddr = new Address.Address()
      newAddr.client_fk = this.clientId
      this.addresses.push(newAddr)
      this.currentEditAddress = new Address.Address()
    },

    onCancelEditAddress(address) {
      this.currentEditAddress.id = null

      if (address.id <= 0) {
        this.addresses.splice(this.addresses.indexOf(address), 1)
      }
    },

    async onBtnDeleteAddress(address) {
      /*if (!await this.$dialog.confirm('Удалить адрес?', 'Удалить', 'Отмена')) {
        return;
      }*/

      if (address.id >= 0) {
        try {
          address.forDelete = 1
          await this.$store.dispatch('saveAddress', address)
        } catch (ex) {
          address.forDelete = 0
          this.showDialog('Ошибка удаления адреса', ex)
          return
        }
      }

      this.addresses.splice(this.addresses.indexOf(address), 1)
    }
  }
}
