
import LoggedInNotice from '@/components/LoggedInNotice'
import LoginForm from '@/components/LoginForm'

export default {
  name: 'Login',
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  components: {
    LoginForm,
    LoggedInNotice,
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  }
}
